<template>
    <div>
        <b-field
            label="Search:">
            <b-autocomplete
                :data="data"
                placeholder="e.g. EX class Jsv"
                :loading="isFetching"
                :autofocus="true"
                icon="magnify"
                @typing="getAsyncData"
                @select="itemSelected">
                <template #default="props">
                    <div
                        v-if="props.option.type === 15"
                        class="media">
                        <div class="media-left">
                            <svg
                                style="width:24px;height:24px"
                                viewBox="0 0 24 24">
                                <path
                                    fill="currentColor"
                                    d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z" />
                            </svg>
                        </div>
                        <div class="media-content">
                            <b>{{ props.option.summary }}</b><br>
                            Library group
                        </div>
                    </div>
                    <div
                        v-else-if="props.option.type === 20"
                        class="media">
                        <div class="media-left">
                            <svg
                                style="width:24px;height:24px"
                                viewBox="0 0 24 24">
                                <path
                                    fill="currentColor"
                                    d="M21,5C19.89,4.65 18.67,4.5 17.5,4.5C15.55,4.5 13.45,4.9 12,6C10.55,4.9 8.45,4.5 6.5,4.5C4.55,4.5 2.45,4.9 1,6V20.65C1,20.9 1.25,21.15 1.5,21.15C1.6,21.15 1.65,21.1 1.75,21.1C3.1,20.45 5.05,20 6.5,20C8.45,20 10.55,20.4 12,21.5C13.35,20.65 15.8,20 17.5,20C19.15,20 20.85,20.3 22.25,21.05C22.35,21.1 22.4,21.1 22.5,21.1C22.75,21.1 23,20.85 23,20.6V6C22.4,5.55 21.75,5.25 21,5M21,18.5C19.9,18.15 18.7,18 17.5,18C15.8,18 13.35,18.65 12,19.5V8C13.35,7.15 15.8,6.5 17.5,6.5C18.7,6.5 19.9,6.65 21,7V18.5Z" />
                            </svg>
                        </div>
                        <div
                            class="media-content">
                            <b>{{ props.option.summaryParts[0] }}</b><br>
                            {{ props.option.summaryParts[1] }}
                        </div>
                    </div>
                    <div
                        v-else-if="props.option.type === 10"
                        class="media">
                        <div class="media-left">
                            <svg
                                style="width:24px;height:24px"
                                viewBox="0 0 24 24">
                                <path
                                    fill="currentColor"
                                    d="M19,4C20.11,4 21,4.9 21,6V18A2,2 0 0,1 19,20H5C3.89,20 3,19.1 3,18V6A2,2 0 0,1 5,4H19M19,18V8H5V18H19Z" />
                            </svg>
                        </div>
                        <div class="media-content">
                            <b>{{ props.option.summary }}</b><br>
                            Librarian feature
                        </div>
                    </div>
                    <div
                        v-else-if="props.option.type === 25"
                        class="media">
                        <div class="media-left">
                            <svg
                                style="width:24px;height:24px"
                                viewBox="0 0 24 24">
                                <path
                                    fill="currentColor"
                                    d="M7,5H21V7H7V5M7,13V11H21V13H7M4,4.5A1.5,1.5 0 0,1 5.5,6A1.5,1.5 0 0,1 4,7.5A1.5,1.5 0 0,1 2.5,6A1.5,1.5 0 0,1 4,4.5M4,10.5A1.5,1.5 0 0,1 5.5,12A1.5,1.5 0 0,1 4,13.5A1.5,1.5 0 0,1 2.5,12A1.5,1.5 0 0,1 4,10.5M7,19V17H21V19H7M4,16.5A1.5,1.5 0 0,1 5.5,18A1.5,1.5 0 0,1 4,19.5A1.5,1.5 0 0,1 2.5,18A1.5,1.5 0 0,1 4,16.5Z" />
                            </svg>
                        </div>
                        <div class="media-content">
                            <b>{{ props.option.summary }}</b><br>
                            Library items
                        </div>
                    </div>
                </template>
            </b-autocomplete>
        </b-field>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import { globalSearch } from '../helpers/api';
    import { getLibraryLink, getLibraryListLink, getCodeSetLink } from '../helpers/routing';

    export default {
        emits: [
            'select'
        ],
        data() {
            return {
                data: [],
                selected: null,
                isFetching: false
            };
        },
        methods: {
            getAsyncData: debounce(async function(name) {
                if (!name.length) {
                    this.data = [];
                    return;
                }
                const result = await globalSearch(this, name);
                this.data = this.postProcess(result);
                this.isFetching = false;
            }, 500),
            itemSelected: function(option) {
                this.$emit('select', option);
                if (option.type === 20) {
                    this.$router.push(getLibraryLink(option.key));
                } else if (option.type === 15) {
                    this.$router.push(getLibraryListLink([option.key]));
                } else if (option.type === 10) {
                    this.$router.push(option.key);
                } else if (option.type === 25) {
                    this.$router.push(getCodeSetLink(option.key));
                }
            },
            postProcess: function(result) {
                return result.map(x => {
                    if (x.type === 20) {
                        return { ...x, summaryParts: x.summary.split('\n') };
                    }
                    return x;
                });
            }
        }
    };
</script>

<style scoped>
</style>
