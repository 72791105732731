<template>
    <div v-if="hasErrors || hasWarnings || hasLogErrors">
        <b-message
            v-if="hasErrors"
            title="Errors in import"
            type="is-danger"
            :closable="false">
            <ul style="list-style-type: square; list-style-position: inside; margin-bottom: 1em;">
                <li
                    v-for="(error, idx) in cappedErrors"
                    :key="idx">
                    {{ error }}
                </li>
            </ul>
            <p><strong>Errors must be corrected before import can be executed.</strong></p>
            <div v-show="hasMoreErrors">
                <strong>Only first 10 errors are shown here! Total error count is {{ release.specification.errors.length }}</strong>
                <br>
                <button
                    class="button is-info is-small"
                    @click="paginate.maxErrors = undefined">
                    Show all errors
                </button>
            </div>
        </b-message>
        <b-message
            v-if="hasWarnings"
            title="Warnings in import"
            type="is-warning"
            :closable="false">
            <ul style="list-style-type: square; list-style-position: inside; margin-bottom: 1em;">
                <li
                    v-for="(warning, idx) in cappedWarnings"
                    :key="idx">
                    {{ warning }}
                </li>
            </ul>
            <div v-show="hasMoreWarnings">
                <strong>Only first 10 warnings are shown here!</strong>
                <br>
                <button
                    class="button is-info is-small"
                    @click="paginate.maxWarnings = undefined">
                    Show all warnings
                </button>
            </div>
        </b-message>
        <b-message
            v-if="hasLogErrors"
            title="Errors in log"
            type="is-danger"
            :closable="false">
            <ul style="list-style-type: square; list-style-position: inside; margin-bottom: 1em;">
                <li
                    v-for="(error, idx) in logErrors"
                    :key="idx">
                    {{ $filters.dateFormatLong(error.timestamp) }}: {{ error.message }}
                    <pre v-if="errorHasCodeResults(error)">{{ error.data.codeResults }}</pre>
                </li>
            </ul>
        </b-message>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        props: {
            release: {
                type: Object,
                required: true
            },
            logErrors: {
                type: Array,
                default: () => []
            }
        },
        data: function() {
            return {
                paginate: {
                    maxErrors: 10,
                    maxWarnings: 10
                }
            };
        },
        computed: {
            cappedErrors: function() {
                return this.release.specification.errors.slice(0, this.paginate.maxErrors);
            },
            hasErrors: function() {
                return _.get(this.release, 'specification.errors.length', false);
            },
            hasMoreErrors: function() {
                return _.get(this.release, 'specification.errors.length', 0) > this.paginate.maxErrors;
            },
            cappedWarnings: function() {
                return this.release.specification.warnings.slice(0, this.paginate.maxWarnings);
            },
            hasWarnings: function() {
                return _.get(this.release, 'specification.warnings.length', false);
            },
            hasMoreWarnings: function() {
                return _.get(this.release, 'specification.warnings.length', 0) > this.paginate.maxWarnings;
            },
            hasLogErrors: function() {
                return this.logErrors.length > 0;
            }
        },
        methods: {
            errorHasCodeResults: function(error) {
                return error.data
                    && error.data.codeResults
                    && error.data.codeResults.length > 0;
            }
        }
    };
</script>
