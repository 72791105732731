<template>
    <div>
        <div class="columns">
            <div
                class="column is-3">
                <h1 class="title">
                    <b-icon
                        icon="table-search"
                        size="" />
                    SQL Editor
                </h1>
                <p class="block">
                    This is a preview version of the SQL Editor. It will get more features in the future.
                </p>
                <sql-library-browser
                    :library-list="libraries"
                    @selected="x => { selectedLibrary = x; }"
                    @set-sql="onSetSql" />
            </div>
            <div class="column is-9">
                <sql-buttons
                    :executing="executing"
                    @click-run="runQuery"
                    @click-excel-export="runExport" />
                <b-field>
                    <b-input
                        v-model="queryText"
                        type="textarea"
                        rows="10"
                        custom-class="sql-editor" />
                </b-field>
                <b-notification
                    v-show="errorMessage"
                    :closable="false"
                    type="is-danger">
                    {{ errorMessage }}
                </b-notification>
                <hr>
                <div
                    v-if="result"
                    class="query-results">
                    <b-table
                        striped
                        narrowed
                        paginated
                        :data="resultData">
                        <template
                            v-for="column in resultColumns"
                            :key="column.field">
                            <b-table-column
                                v-slot="props"
                                :field="column.field"
                                :sortable="column.sortable"
                                :label="column.label">
                                <template v-if="column.field === 'Id' || column.field.endsWith('_ID')">
                                    <router-link :to="codeLinkFromDbId(props.row[column.field])">
                                        <b-icon
                                            icon="link-variant"
                                            size="is-small" /> {{ props.row[column.field] }}
                                    </router-link>
                                </template>
                                <template v-else>
                                    <span
                                        class="keep-spaces"
                                        v-text="props.row[column.field]" />
                                </template>
                            </b-table-column>
                        </template>
                        <template #empty>
                            <div class="has-text-left">
                                No records
                            </div>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import saveAs from 'file-saver';
    import SqlButtons from './SqlButtons.vue';
    import SqlLibraryBrowser from './SqlLibraryBrowser.vue';
    import { showMixin } from '@/shared/mixins/showMixin';
    import { getLibraryNames, sqlQuery, sqlQueryToExcel } from '@/shared/helpers/api';
    import { getCodeLinkByB64Id } from '@/shared/helpers/routing';
    import { encodeIdBase64 } from '@/shared/helpers/utils';
    import _ from 'lodash';

    export default {
        name: 'SqlEditor',
        components: {
            SqlButtons,
            SqlLibraryBrowser
        },
        mixins: [
            showMixin
        ],
        data() {
            return {
                libraries: [],
                queryText: '',
                result: null,
                errorMessage: '',
                executing: false,
                selectedLibrary: null
            };
        },
        computed: {
            resultData() {
                if (this.result) {
                    return this.result.data;
                }
                return [];
            },
            resultColumns() {
                if (this.result && this.result.data.length > 0) {
                    const fieldNames = _.keys(this.result.data[0]);
                    return _.map(fieldNames, fn => ({
                        field: fn,
                        label: fn,
                        sortable: true
                    }));
                }
                return [];
            }
        },
        async mounted() {
            this.libraries = (await getLibraryNames(this)).sort();
        },
        methods: {
            async runQuery() {
                this.errorMessage = null;
                this.executing = true;
                try {
                    this.result = await sqlQuery(this, {
                        query: this.queryText
                    });
                } catch (ex) {
                    this.showError(ex, m => { this.errorMessage = m; });
                }
                this.executing = false;
            },
            async runExport() {
                this.errorMessage = null;
                try {
                    const response = await sqlQueryToExcel(this, {
                        query: this.queryText
                    });

                    if (response.data) {
                        const filename = this.selectedLibrary + '_query.xlsx';
                        const blob = new Blob([response.data], { type: response.headers['Content-Type'] });
                        saveAs(blob, filename);
                    }
                } catch (ex) {
                    this.showError(ex, m => { this.errorMessage = m; });
                }
            },
            onSetSql(sql) {
                this.queryText = sql;
            },
            codeLinkFromDbId(id) {
                return getCodeLinkByB64Id(encodeIdBase64('Code', id));
            }
        }
    };
</script>

<style>
.sql-editor {
    height: 200px;
    font-family: monospace;
    font-size: 12px;
    font-weight: bold;
    color:green;
}
.query-results {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 72vw;
  padding: 4px;
}
.keep-spaces {
    white-space: pre;
}
</style>
