<template>
    <div class="max-width">
        <h1 class="title">
            Schema specifications
        </h1>
        <div class="columns">
            <div class="column">
                <b-table
                    :data="schemata"
                    default-sort="Name"
                    :striped="true"
                    :narrowed="true"
                    :hoverable="true"
                    class="table-height site-sticky-header">
                    <b-table-column
                        v-slot="props"
                        field="Name"
                        label="Name"
                        sortable>
                        <span class="name_column">
                            <b-icon
                                icon="link-variant"
                                size="is-small" />
                            <a :href="'/Schema#/specification/' + props.row.Identity">
                                {{ props.row.Name }}</a>
                        </span>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="Description"
                        label="Description">
                        {{ props.row.Description }}
                    </b-table-column>
                </b-table>
            </div>
            <div class="column is-3">
                <tag-introduction :tag="'Schema (Ontology)'" />
            </div>
        </div>
    </div>
</template>

<script>
    import TagIntroduction from '@/shared/components/TagIntroduction.vue';
    import { genericViewQuery } from '@/shared/helpers/api.ts';

    export default {
        components: {
            TagIntroduction
        },
        data: function() {
            return {
                schemata: []
            };
        },
        mounted: async function() {
            this.schemata = (await genericViewQuery(this, {
                version: 1,
                name: 'Schema',
                include: [
                    {
                        field: 'Name'
                    },
                    {
                        field: 'Description'
                    },
                    {
                        field: 'Identity'
                    }
                ],
                where: {
                    field: 'isValid',
                    value: true
                }
            })).data;
        }
    };
</script>
<style scoped>
.name_column{
    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;
}
.max-width{
    width: 100%;
}
</style>
