<template>
    <div>
        <b-field>
            <b-checkbox
                v-model="showMapped">
                Show only mapped classes
            </b-checkbox>
        </b-field>
        <spinner
            :loading="loading"
            :fullscreen="false">
            <div
                v-if="!loading"
                class="tables-wrapper">
                <div class="left-div">
                    <h5 class="subtitle is-5">
                        Classes {{ displayText(schemaList[0]) }}
                    </h5>
                    <div class="left-table-wrap">
                        <b-table
                            v-model:selected="selectedClass"
                            class="left-table"
                            :data="classes"
                            :hoverable="true">
                            <b-table-column
                                v-slot="props"
                                field="displayname"
                                label="Source"
                                :td-attrs="columnTdAttrs">
                                <b-icon
                                    icon="link-variant"
                                    size="is-small" />
                                {{ props.row.displayname }}
                            </b-table-column>
                            <b-table-column
                                v-slot="props"
                                field="mapping.displayclassName"
                                label="Destination">
                                {{ props.row.mapping ? props.row.mapping.displayclassName : '' }}
                            </b-table-column>

                            <b-table-column
                                v-slot="props"
                                field="mapping.isBidirectional"
                                label="Bidirectional">
                                {{ props.row.mapping ? props.row.mapping.isBidirectional : '' }}
                            </b-table-column>

                            <b-table-column
                                v-slot="props"
                                field="mapping.passOnUnmappedAttributes"
                                label="Passthrough">
                                {{ props.row.mapping ? props.row.mapping.passOnUnmappedAttributes : '' }}
                            </b-table-column>
                        </b-table>
                    </div>
                </div>
                <div class="right-div">
                    <div class="margin-bot">
                        <h5 class="subtitle is-5">
                            Attributes {{ displayText(selectedClass) }}
                        </h5>
                        <div class="top-table-wrap">
                            <b-table
                                v-if="selectedAttributes.length > 0"
                                v-model:selected="selectedAttribute"
                                class="top-table"
                                :hoverable="true"
                                :data="selectedAttributes">
                                <b-table-column
                                    v-slot="props"
                                    field="name"
                                    label="Source Schema"
                                    :td-attrs="columnTdAttrs">
                                    <b-icon
                                        icon="link-variant"
                                        size="is-small" />
                                    {{ props.row.name }}
                                </b-table-column>
                                <b-table-column
                                    v-slot="props"
                                    field="mappingFlat.attributeName"
                                    label="Target Schema">
                                    {{ props.row.mappingFlat ? props.row.mappingFlat.attributeName : '' }}
                                </b-table-column>

                                <b-table-column
                                    v-slot="props"
                                    field="mappingFlat.isBidirectional"
                                    label="Bidirectional">
                                    {{ props.row.mappingFlat ? props.row.mappingFlat.isBidirectional : '' }}
                                </b-table-column>
                            </b-table>
                            <span v-else>
                                Select a class to view attributes
                            </span>
                        </div>
                    </div>
                    <div class="margin-bot">
                        <h5 class="subtitle is-5">
                            Attributes added {{ displayText(selectedClass) }}
                        </h5>
                        <div class="low-table-wrap">
                            <b-table
                                v-if="selectedAddedAttributes.length > 0"
                                class="low-table"
                                :data="selectedAddedAttributes">
                                <b-table-column
                                    v-slot="props"
                                    field="valueSource"
                                    label="Source">
                                    {{ props.row.valueSource }}
                                </b-table-column>
                                <b-table-column
                                    v-slot="props"
                                    field="name"
                                    label="Target Name">
                                    {{ props.row.name }}
                                </b-table-column>
                                <b-table-column
                                    v-slot="props"
                                    field="valueConstant"
                                    label="Value Constant">
                                    {{ props.row.valueConstant }}
                                </b-table-column>
                            </b-table>
                            <span v-else-if="!selectedClass">
                                Select a class to view added attributes
                            </span>
                            <span v-else-if="schemaTo.length == 0">
                                Select a schema to map to
                            </span>
                            <span v-else>
                                No added attributes
                            </span>
                        </div>
                    </div>
                    <div>
                        <h5 class="subtitle is-5">
                            Value map {{ displayText(selectedAttribute) }}
                        </h5>
                        <div class="low-table-wrap">
                            <b-table
                                v-if="selectedValueMapData.length > 0"
                                class="low-table"
                                :data="selectedValueMapData"
                                :columns="columnsValuemapDefinitions" />
                            <span v-else-if="schemaTo.length > 0">
                                Select an attribute to view value mapping. (Found no value mapping for selected attribute.)
                            </span>
                            <span v-else-if="schemaTo.length == 0">
                                Select a schema to map to.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </spinner>
    </div>
</template>

<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import _ from 'lodash';

    export default {
        components: { Spinner },

        props: {
            loading: {
                type: Boolean
            },
            schemaList: {
                default: null,
                type: Array
            },
            schemaTo: {
                type: Array,
                default: () => ['mapped to']
            },
            showMap: {
                type: Boolean,
                default: false
            }
        },
        data: function() {
            return {
                selectedClass: null,
                selectedAttribute: null,
                columnsValuemapDefinitions: [],
                showMapped: false
            };
        },
        computed: {
            classes: function() {
                const modifiedClassList = [];
                this.schemaList.forEach(schema => {
                    const currentClasses = schema.classes.map(currentClass => {
                        currentClass.displayname = `${schema.name}.${currentClass.name}`;
                        if (currentClass.mapping) {
                            currentClass.mapping.displayclassName = `${currentClass.mapping.schema}.${currentClass.mapping.className}`;
                        }
                        return currentClass;
                    });
                    modifiedClassList.push.apply(modifiedClassList,
                                                 currentClasses.filter(currentClass => !this.showMapped || currentClass.mapping)
                                                     .sort((a, b) => a.displayname > b.displayname ? 1 : -1));
                });
                return modifiedClassList.filter(currentClass => !this.showMapped || currentClass.mapping)
                    .sort((a, b) => a.displayname > b.displayname ? 1 : -1);
            },
            selectedAttributes: function() {
                const attributeList = [];
                if (!this.selectedClass)
                    return attributeList;

                for (const attr of this.selectedClass.attributes) {
                    if (attr.mapping && attr.mapping.length > 0) {
                        for (const mapping of attr.mapping) {
                            const attrWithMapping = { ...attr };
                            attrWithMapping.mappingFlat = mapping;
                            attributeList.push(attrWithMapping);
                        }
                    } else {
                        attributeList.push(attr);
                    }
                }
                attributeList.sort((a, b) => a.description.localeCompare(b.description));
                return attributeList;
            },
            selectedValueMapData: function() {
                if (this.selectedAttribute) {
                    if (this.selectedAttribute.mapping && this.selectedAttribute.mappingFlat) {
                        let tempValueMap = this.selectedAttribute.mappingFlat.valueMap;
                        if (tempValueMap) {
                            tempValueMap = Object.entries(tempValueMap);
                            tempValueMap = tempValueMap.map(entry => {
                                if (entry && entry[0]) {
                                    return { propName: entry[0], propValue: entry[1] };
                                } else return {};
                            });
                            return tempValueMap;
                        }
                    }
                    return [{ propName: 'Selected attribute has no value mapping' }];
                } else {
                    return [];
                }
            },
            selectedAddedAttributes: function() {
                if (this.selectedClass?.mapping) {
                    return this.selectedClass.mapping.addAttributes;
                }
                return [];
            }
        },
        watch: {
            loading: function() {
                this.selectedClass = null;
                this.selectedAttribute = null;
            },
            schemaList: function(s) {
                if (s[0]) {
                    this.createColumns();
                }
            },
            showMap: function(value) {
                if (this.schemaList[0]) {
                    this.createColumns();
                }
                this.showMapped = value;
            },
            selectedClass: function() {
                this.selectedAttribute = null;
            }
        },
        mounted: function() {
            this.createColumns();
        },
        methods: {
            columnTdAttrs(row, column) {
                if (column.label === 'Source' || column.label === 'Source Schema') {
                    return {
                        class: 'has-text-weight-bold'
                    };
                }
                return null;
            },
            createColumns: function() {
                const ColumnLabels = ['Source Schema', 'Target Schema'];

                this.columnsValuemapDefinitions = [
                    { field: 'propName', label: ColumnLabels[0] },
                    { field: 'propValue', label: ColumnLabels[1] }
                ];
            },
            displayText: function(obj) {
                return _.get(obj, 'displayname', '') ? 'in ' + obj.displayname : null;
            }
        }
    };
</script>
<style scoped>
.tables-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    height: 600px;
}
.left-div {
    width: 65%;
    --max-width: 840px;
    padding: 10px;
    overflow: auto;
    height: inherit;
}
.right-div{
    width: 35%;
    overflow: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
}
.margin-bot{
    margin-bottom: 20px;
}
.left-table-wrap{
    overflow-y: auto;
    overflow-x: hidden;
}
.top-table-wrap{
    overflow: auto;
}
.low-table-wrap{
    overflow: auto;
}
.left-table{
    overflow: hidden;
    cursor: pointer;
    width: initial;
}
.top-table{
    overflow: hidden;
    cursor: pointer;
}
.low-table{
    overflow: hidden;
}
</style>
