<template>
    <b-dropdown
        :append-to-body="true"
        :trigger-tabindex="-1"
        :position="scopeFilterPosition"
        aria-role="list">
        <b-field
            class="library-group-container"
            position="is-centered">
            <b-button
                @click="store.clearScopeFilter">
                Clear selected
            </b-button>
        </b-field>
        <template #trigger>
            <b-button
                :loading="store.scopeFilterLoading"
                :icon-left="store.filterIcon"
                class="button is-info is-dropdown-trigger">
                {{ store.scopeFilterLabel }}
            </b-button>
        </template>
        <hr>
        <scope-filter-columns
            class="scope-filter-columns" />
    </b-dropdown>
</template>

<script setup>
    import { useScopeFilterStore } from '@/stores/scopeFilterStore.js';
    import { onMounted } from 'vue';

    import ScopeFilterColumns from '@/shared/components/ScopeFilterColumns.vue';

    const props = defineProps({
        library: {
            type: Object,
            required: true
        },
        scopeFilterPosition: {
            type: String,
            default: 'is-bottom-right'
        }
    });

    const store = useScopeFilterStore();

    onMounted(async () => {
        if (props.library.scopeType) {
            await store.setScopeType(props.library.scopeType);
        }
        await store.init();
    });
</script>
<style scoped>
.library-group-container {
    padding-left: 10px;
    padding-top: 10px;
    overflow-x: auto;
    width:400px;
}
.scope-filter-columns {
    height: 520px;
    width: 650px;
    margin: 5px;
}
</style>
