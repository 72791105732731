<template>
    <span
        v-if="hasValue && attributeDefinition?.attributeType.toUpperCase() === 'CODEREF'">
        <a
            :href="aHrefCodeLink">
            <b-icon
                icon="link-variant"
                size="is-small" />
            {{ attributeDefinition.referenceLibraryName }}: {{ referenceCodeLabel }}
        </a>

    </span>
    <span v-else-if="hasValue && attributeDefinition?.attributeType.toUpperCase() === 'LIBRARYREF'">
        <router-link
            :to="getLibraryLink(value)">
            <b-icon
                icon="link-variant"
                size="is-small" />
            {{ value }}
        </router-link>
    </span>
    <span v-else-if="hasValue && attributeDefinition?.attributeType.toUpperCase() === 'URI'">
        <uri-element
            :value="value"
            :truncate-at="truncLimit" />
    </span>
    <span
        v-else
        class="keep-spaces"
        v-text="fieldValue(value)" />
</template>

<script setup>
    import { encodeIdBase64 } from '@/shared/helpers/utils';
    import { getReferenceCodeLabel } from '@/shared/helpers/dataHelpers';
    import { getCodeLinkByB64Id, getLibraryLink } from '@/shared/helpers/routing';
    import UriElement from '@/shared/components/UriElement.vue';
    import { changeValueFilter } from '@/filters.js';
    import { computed, ref } from 'vue';

    const props = defineProps(
        {
            attributeDefinition: {
                type: Object,
                default: null
            },
            value: {
                type: null,
                default: null
            },
            referableCodes: {
                type: Object,
                default: null
            }
        });

    const truncLimit = ref(50);
    const codeType = 'Code';

    function getRefCode(value) {
        return props.referableCodes[props.attributeDefinition.name]
            .find(c => c.identity === value);
    }

    const aHrefCodeLink = computed(() => {
        const refCode = getRefCode(props.value);
        if (refCode?.Id) {
            const encodedId = encodeIdBase64(codeType, refCode.Id);
            return getCodeLinkByB64Id(encodedId);
        }
        return '';
    });

    const referenceCodeLabel = computed(() => {
        const refCode = getRefCode(props.value);
        if (refCode) {
            return getReferenceCodeLabel(props.attributeDefinition, refCode);
        } else {
            return '';
        }
    });

    const hasValue = computed(() => {
        return props.value && props.value !== '{null}';
    });

    function fieldValue(value) {
        return changeValueFilter(value);
    }
</script>

<style scoped>
.keep-spaces {
    white-space: pre-wrap;
}
</style>
