<template>
    <b-tooltip
        :label="tooltipLabel"
        :close-delay="5000"
        type="is-light"
        animated
        multilined
        @open="refreshTooltipLabel">
        <b-field>
            <p class="control">
                <b-button
                    @click="generateAndCopyLookupUrlUnencoded">
                    <b-icon
                        icon="content-copy"
                        size="is-small" />
                </b-button>
            </p>
            <p class="control">
                <b-button
                    class="is-info"
                    @click="generateAndCopyLookupUrlEncoded">
                    <span>Copy Lookup URL</span>
                </b-button>
            </p>
        </b-field>
    </b-tooltip>
</template>

<script>
    import { showMixin } from '@/shared/mixins/showMixin';

    export default {
        mixins: [
            showMixin
        ],
        props: {
            libraryName: {
                type: String,
                required: true
            },
            codeSetDescription: {
                type: String,
                required: false,
                default: null
            },
            isGlobal: {
                type: Boolean,
                required: false,
                default: false
            },
            scope: {
                type: String,
                required: false,
                default: null
            },
            queries: {
                type: Array,
                required: false,
                default: () => []
            },
            regEx: {
                type: Array,
                required: false,
                default: () => []
            }
        },
        data() {
            return {
                tooltipLabel: ''
            };
        },
        methods: {
            generateLookupURL: function() {
                const url = new URL(`${window.location.origin}/Lookup/`);
                const selectedColumns = this.getSelectedColumns();

                url.searchParams.set('library', this.libraryName);
                url.searchParams.set('scope', !this.isGlobal ? this.scope : '');

                if (this.queries.length > 0)
                    url.searchParams.set('queries', JSON.stringify(this.queries));
                if (this.regEx.length > 0)
                    url.searchParams.set('regex', `"${this.regEx.join('","')}"`);
                if (selectedColumns.length > 0) {
                    url.searchParams.set('show', `${JSON.stringify(selectedColumns)}`);
                }

                return url;
            },
            getSelectedColumns: function() {
                const selectedColumns = localStorage.getItem('Library.' + this.libraryName + '.SelectedTableColumns');
                return selectedColumns ? selectedColumns.split(',') : [];
            },
            generateLinkTitle: function() {
                return this.codeSetDescription ?? document.title;
            },
            generateAndCopyLookupUrlUnencoded: async function() {
                const url = this.generateLookupURL();
                await this.copyToClipboard(decodeURIComponent(url.toString()), this.generateLinkTitle());
            },

            generateAndCopyLookupUrlEncoded: async function() {
                const url = this.generateLookupURL();
                await this.copyToClipboard(url, this.generateLinkTitle());
            },
            refreshTooltipLabel: function() {
                this.tooltipLabel = decodeURIComponent(this.generateLookupURL().toString());
            },
            copyToClipboard: async function(url, title) {
                // Copy url both as plain text and html, to allow pasting  hyperlinks with title in Word etc.
                // and plain URL in text based editors

                try {
                    await navigator.clipboard.write([
                        new ClipboardItem({
                            'text/plain': new Blob([url], {
                                type: 'text/plain'
                            }),
                            'text/html': new Blob(
                                [
                                    `<a href="${encodeURI(url)}">${title}</a>`
                                ],
                                {
                                    type: 'text/html'
                                }
                            )
                        })
                    ]);
                    this.showInfo('Lookup URL copied to clipboard');
                } catch {
                    this.showError('Copy to clipboard failed!');
                }
            }
        }
    };
</script>

<style scoped>
.b-tooltip :deep(.tooltip-content) {
    font-size: 12px !important;
    width: auto !important;
}
</style>
