<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { EnsFormatElement } from '@/apps/ens/helpers.ts';

    type Props = {
        existingFormats: EnsFormatElement[];
    };

    const props = withDefaults(
        defineProps<Props>(),
        { existingFormats: () => [] }
    );

    const emit = defineEmits(['close', 'submit']);

    const description = ref('');

    const isDescriptionUnique = computed(() => {
        const normalizedDescription = description.value.toLowerCase().trim();
        return !props.existingFormats.some(x => x.description.toLowerCase().trim() === normalizedDescription);
    });

    const canSubmit = computed(() => {
        return !!description.value.trim() && isDescriptionUnique.value;
    });

    const onClose = () => {
        emit('close');
    };

    const onClickSubmit = () => {
        emit('submit', description.value);
        emit('close');
    };
</script>

<template>
    <b-modal
        has-modal-card
        model-value
        @close="onClose">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    <b>Add new format</b>
                </p>
            </header>
            <section class="modal-card-body">
                <b-field
                    label="Description"
                    :type="isDescriptionUnique ? '' : 'is-danger'"
                    :message="isDescriptionUnique ? '' : 'A format with the same description already exists'">
                    <b-input
                        v-model="description"
                        required />
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <b-field class="is-grouped-right">
                    <b-button
                        label="Cancel"
                        @click="onClose" />
                    <b-button
                        label="Add"
                        type="is-primary"
                        :disabled="!canSubmit"
                        @click="onClickSubmit" />
                </b-field>
            </footer>
        </div>
    </b-modal>
</template>

<style scoped>
    .modal-card {
        max-width: 600px;
    }
    .modal-card-foot {
        /* Right-justify buttons to match .dialog button placement */
        justify-content: flex-end;
    }
</style>
