<template>
    <div>
        <div class="columns">
            <div class="column is-clearfix">
                <div class="field is-grouped is-pulled-right">
                    <p class="control">
                        <b-button
                            v-require-can-edit-code="{ libraryName: 'Contract' }"
                            class="is-primary"
                            @click="newContract">
                            <b-icon
                                icon="pencil"
                                size="is-small" />
                            <span>Create new Contract</span>
                        </b-button>
                    </p>
                </div>
            </div>
        </div>

        <div
            v-if="showNewDialog"
            id="editor">
            <h2 class="title">
                Create a new Contract:
            </h2>
            <code-edit
                :key="contractCodeEditKey"
                :quick-commit="true"
                :show-cancel="true"
                :code-set-name="codeSetName"
                library="Contract"
                @refresh="refreshContracts"
                @cancel="showNewDialog = false" />
            <hr>
        </div>

        <b-table
            v-if="!loading"
            :data="contractList.data"
            default-sort="Name"
            :striped="true"
            :narrowed="true"
            :hoverable="true"
            class="site-sticky-header">
            <b-table-column
                v-slot="props"
                field="Name"
                label="Name"
                sortable>
                <router-link
                    :to="getContractLink(props.row)">
                    <b-icon
                        icon="link-variant"
                        size="is-small" />
                    {{ props.row.Name }}
                </router-link>
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="IsValid"
                label="Is valid"
                centered
                sortable>
                <bool-element :value="props.row.IsValid" />
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="Description"
                label="Description"
                sortable>
                {{ props.row.Description }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="ProjectName"
                label="Project"
                sortable>
                <router-link
                    v-if="props.row.ProjectName"
                    :to="getProjectLink(props.row)">
                    <b-icon
                        icon="link-variant"
                        size="is-small" />
                    {{ props.row.ProjectName }}
                </router-link>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import BoolElement from '@/shared/components/BoolElement.vue';
    import { genericViewQueryAsText, getCodeSets } from '@/shared/helpers/api';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { encodeIdBase64 } from '@/shared/helpers/utils';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            CodeEdit,
            BoolElement
        },
        data: function() {
            return {
                contractList: {},
                contractCodeEditKey: 1,
                showNewDialog: false,
                loading: true,
                codeSetName: null,
                contractListGQ: `
                    FROM Contract 
                    SELECT Id, Name, Identity, Description, IsValid
                    WHERE IsValid = true 
                    JOIN ProjectMaster SELECT Id AS ProjectId, Name AS ProjectName`
            };
        },
        async mounted() {
            this.contractList = await genericViewQueryAsText(this, this.contractListGQ);
            this.codeSetName = (await getCodeSets(this, 'Contract'))[0].name;
            this.loading = false;
        },
        methods: {
            getProjectLink: function(f) {
                return { name: 'FacilityAndProjectById', params: { id: encodeIdBase64('Code', f.ProjectId) } };
            },
            getContractLink: function(f) {
                return { name: 'ContractById', params: { id: encodeIdBase64('Code', f.Id) } };
            },
            newContract: function() {
                this.showNewDialog = true;
                this.contractCodeEditKey += 1;
            },
            refreshContracts: async function() {
                this.contractList = await genericViewQueryAsText(this, this.contractListGQ);

                this.showNewDialog = false;
            }
        }
    };
</script>
