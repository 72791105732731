<template>
    <div>
        <div class="columns">
            <div
                class="column is-2">
                <h1 class="title">
                    <b-icon
                        icon="table-search"
                        size="" />
                    Query Builder
                </h1>
                <query-browser
                    v-model:active-tab="activeBrowserTab"
                    :libraries="libraries"
                    :queries="savedQueries"
                    @tab-changed="n => { activeBrowserTab = n; }"
                    @lib-selected="libSelected"
                    @query-selected="querySelected"
                    @new-query="clickNewQuery" />
            </div>
            <div :class="{'column': true, 'is-10': !showHelp, 'is-8': showHelp }">
                <query-buttons
                    :executing="executing"
                    :saved-query-loaded="query.id != null"
                    :uml-visible="showUml"
                    :help-visible="showHelp"
                    :is-public="query.isPublic"
                    :is-report="query.isReport"
                    :query-user-id="query.userId"
                    @is-public-changed="b => query.isPublic = b"
                    @click-run="clickRun"
                    @click-save="clickSave"
                    @click-delete="clickDelete"
                    @click-view-query="clickViewQuery"
                    @click-view-uml="clickViewUml"
                    @click-excel-export="clickExcelExport"
                    @click-toggle-report="clickToggleReport"
                    @click-help="clickHelp" />
                <div>
                    <b-field v-show="query.id">
                        <b-input
                            v-model="query.name"
                            placeholder="No name" />
                    </b-field>
                    <div
                        v-show="query.isReport"
                        style="margin-bottom:8px;">
                        <b-icon
                            icon="rss"
                            size="is-small" /> This query is used as a report. <b><a
                                :href="'/Report/Query#/id/' + query.id"><b-icon
                                icon="link-variant"
                                size="is-small" />Open report</a></b>
                    </div>
                    <div
                        v-if="showUml">
                        <b-loading
                            v-if="umlprogress"
                            v-model="umlprogress"
                            :is-full-page="false" />
                        <mermaid-diagram
                            v-else
                            :uml-source="umlSource" />
                    </div>

                    <b-field
                        label="Query"
                        style="width: auto; height: 200px; "
                        label-position="on-border">
                        <textarea
                            v-model="queryspec"
                            style="width: 100%; height: 200px; font-size: 14px; padding: 5px; font-family: monospace"
                            rows="10" />
                    </b-field>
                    <b-field
                        v-for="p in parameters"
                        :key="p.name"
                        label-position="on-border"
                        :label="p.name"
                        :message="p.value ? '' : 'No parameter value specified'"
                        :type="p.value ? '' : 'is-info'">
                        <b-input v-model="p.value" />
                    </b-field>
                </div>
                <hr>
                <b-notification
                    v-model="errorActive"
                    type="is-danger"
                    aria-close-label="Close notification"
                    has-icon
                    role="alert">
                    {{ error }}
                </b-notification>
                <div
                    v-if="result"
                    class="query-results">
                    <b-table
                        striped
                        narrowed
                        paginated
                        :data="resultData">
                        <template
                            v-for="column in resultColumns"
                            :key="column.field">
                            <b-table-column
                                v-slot="props"
                                :field="column.field"
                                :sortable="column.sortable"
                                :label="column.label">
                                <template v-if="column.field === 'Id' || column.field.endsWith('_ID')">
                                    <a
                                        v-if="props.row[column.field]"
                                        :href="codeLinkFromDbId(props.row[column.field])">
                                        <b-icon
                                            icon="link-variant"
                                            size="is-small" /> {{ props.row[column.field] }}
                                    </a>
                                </template>
                                <template v-else>
                                    <span
                                        class="keep-spaces"
                                        v-text="props.row[column.field]" />
                                </template>
                            </b-table-column>
                        </template>
                        <template #empty>
                            <div class="has-text-left">
                                No records
                            </div>
                        </template>
                    </b-table>
                </div>
            </div>
            <div
                v-show="showHelp"
                class="column is-2 help-column">
                <query-builder-help />
            </div>
        </div>

        <query-parsed-modal
            v-model:show="showCode"
            :query="queryspec" />
    </div>
</template>

<script>
    import QueryBrowser from './QueryBrowser.vue';
    import QueryButtons from './QueryButtons.vue';
    import QueryParsedModal from './QueryParsedModal.vue';
    import QueryBuilderHelp from './QueryBuilderHelp.vue';
    import MermaidDiagram from '@/shared/MermaidDiagram.vue';
    import { makeDefaultQuery } from './queryHelpers';

    import saveAs from 'file-saver';
    import { showMixin } from '@/shared/mixins/showMixin';
    import {
        makeUmlSource,
        helpfulMermaidDiagramPlaceholder,
        mermaidQueryHelperPlaceholder
    } from '@/shared/helpers/umlHelper';
    import {
        getLibraries,
        getAttributeDefinitions,
        genericViewQueryAsTextToExcel,
        genericViewQueryAsText,
        createSavedQuery,
        updateSavedQuery,
        deleteSavedQuery,
        getSavedQueries,
        savedQuerySetReport
    } from '@/shared/helpers/api';
    import { getCodeLinkByB64Id } from '@/shared/helpers/routing';
    import { encodeIdBase64 } from '@/shared/helpers/utils';
    import _ from 'lodash';

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    const localStorageKeys = {
        ShowUml: 'QueryBuilder:ShowUml',
        ActiveBrowserTab: 'QueryBuilder:ActiveBrowserTab'
    };

    export default {
        components: {
            MermaidDiagram,
            QueryBrowser,
            QueryButtons,
            QueryParsedModal,
            QueryBuilderHelp
        },
        mixins: [
            showMixin
        ],
        data() {
            return {
                libraries: [],
                selectedLibraryFromTextual: null,
                savedQueries: [],
                attributeDefinitions: {},
                query: {
                    id: null,
                    name: '',
                    isPublic: false,
                    isReport: false
                },
                queryspec: '', // needs to be a root property in data because of reasons (editor failing etc.)
                parameters: [],
                result: null,
                activeBrowserTab: 0,
                executing: false,
                error: null,
                errorActive: false,
                showCode: false,
                showUml: false,
                showHelp: false,
                parameterRegex: RegExp('@\\w+', 'gm'),
                textualLibraryRegex: RegExp('FROM (\\w+)', 'i'), // MOVE TO HELPER
                umlSource: null,
                umlprogress: false
            };
        },
        computed: {
            selectedLibraryTextual() {
                if (this.queryspec?.length > 0) {
                    const m = this.textualLibraryRegex.exec(this.queryspec);
                    if (m && m.length === 2) {
                        return m[1];
                    }
                }
                return undefined;
            },
            resultData() {
                if (this.result) {
                    return this.result.data;
                }
                return [];
            },
            resultColumns() {
                if (this.result && this.result.data.length > 0) {
                    const fieldNames = _.keys(this.result.data[0]);
                    return _.map(fieldNames, fn => ({
                        field: fn,
                        label: fn,
                        sortable: true
                    }));
                }
                return [];
            }
        },
        watch: {
            '$route'() {
                this.loadFromRoute();
            },
            queryspec(value) {
                const fromSource = value.match(this.parameterRegex);
                this.parameters = fromSource?.filter(onlyUnique).map(name => ({
                    name,
                    value: this.parameters.find(x => x.name === name)?.value ?? ''
                })) ?? [];
            },
            async selectedLibraryTextual(value) {
                if (value && value.length > 0) {
                    const valueLower = value.toLowerCase();
                    const lib = _.find(this.libraries, l => l.name.toLowerCase() === valueLower);
                    if (lib) {
                        this.umlprogress = true;
                        await this.ensureDefinitions(lib.name);
                        this.selectedLibraryFromTextual = lib;
                        this.umlSource = makeUmlSource(lib, this.libraries, this.attributeDefinitions);
                        this.umlprogress = false;
                    } else {
                        this.selectedLibraryFromTextual = null;
                        this.umlSource = mermaidQueryHelperPlaceholder(value);
                    }
                } else {
                    this.selectedLibraryFromTextual = null;
                }
            },
            showUml(value) {
                localStorage.setItem(localStorageKeys.ShowUml, value);
            },
            activeBrowserTab(value) {
                localStorage.setItem(localStorageKeys.ActiveBrowserTab, value);
            }
        },
        async mounted() {
            this.readSettings();
            this.umlSource = helpfulMermaidDiagramPlaceholder;
            this.libraries = _.sortBy(await getLibraries(this), x => x.name).filter(x => !x.isForeignObject);
            await this.loadQueries();
            await this.loadFromRoute();

            const self = this;

            window.addEventListener('keydown', async function(e) {
                if (e.shiftKey && e.key === 'Enter') {
                    e.preventDefault();
                    await self.clickRun();
                } else if (e.altKey) {
                    switch (e.key) {
                        case '1':
                            e.preventDefault();
                            self.activeBrowserTab = 0;
                            break;
                        case '2':
                            e.preventDefault();
                            self.activeBrowserTab = 1;
                            break;
                    }
                }
            });
        },
        methods: {
            readSettings() {
                const showUmlSetting = localStorage.getItem(localStorageKeys.ShowUml);
                if (showUmlSetting)
                    this.showUml = showUmlSetting === 'true';

                const activeBrowserTabSetting = localStorage.getItem(localStorageKeys.ActiveBrowserTab);
                if (activeBrowserTabSetting)
                    this.activeBrowserTab = parseInt(activeBrowserTabSetting);
            },
            clearUI() {
                this.result = null;
                this.errorActive = false;
                this.query = {
                    id: null,
                    name: '',
                    isPublic: false,
                    isReport: false,
                    text: ''
                };
                this.queryspec = '';
                this.umlSource = helpfulMermaidDiagramPlaceholder;
            },
            async loadQueries() {
                this.savedQueries = _.sortBy(await getSavedQueries(this), x => x.name);
            },
            async selectLib(name) {
                this.clearUI();
                const library = _.find(this.libraries, { name: name });
                await this.ensureDefinitions(library.name);
                if (library) {
                    if (!(this.$route.name === 'QueryBuilderByLibrary' && this.$route.params.library === library.name)) {
                        await this.$router.push({ name: 'QueryBuilderByLibrary', params: { library: library.name } });
                    }
                    this.queryspec = makeDefaultQuery(library, this.attributeDefinitions[library.name]);
                }
            },
            querySelected(id) {
                let q = _.find(this.savedQueries, { id: id });
                if (q) {
                    q = JSON.parse(JSON.stringify(q));
                    this.clearUI();
                    this.query = q;
                    this.queryspec = q.spec;
                    if (!(this.$route.name === 'QueryBuilderById' && parseInt(this.$route.params.id) === q.id)) {
                        this.$router.push({ name: 'QueryBuilderById', params: { id: q.id } });
                    }
                } else {
                    this.showError(`Can't load query with id ${id}. It may not have been made public and belong to another user.`, m => {
                        this.error = m;
                        this.errorActive = true;
                    });
                }
            },
            async loadFromRoute() {
                switch (this.$route.name) {
                    case 'QueryBuilderByLibrary':
                        if (this.selectedLibraryTextual !== this.$route.params.library) {
                            await this.selectLib(this.$route.params.library);
                            this.activeBrowserTab = 0;
                        }
                        break;
                    case 'QueryBuilderById': {
                        const id = parseInt(this.$route.params.id);
                        if (this.query.id !== id) {
                            this.querySelected(id);
                            this.activeBrowserTab = 1;
                        }
                        break;
                    }
                    default: {
                        this.clearUI();
                    }
                }
            },
            async ensureDefinitions(libName) {
                if (!this.attributeDefinitions[libName]) {
                    const attributesForLib = await getAttributeDefinitions(this, libName);
                    this.attributeDefinitions[libName] = attributesForLib;

                    for (let i = 0; i < attributesForLib.length; i++) {
                        const x = attributesForLib[i];
                        if (x.attributeType === 'CodeRef')
                            await this.ensureDefinitions(x.referenceLibraryName);
                    }
                }
            },
            async clickViewQuery() {
                this.showCode = true;
            },
            async clickExcelExport() {
                this.errorActive = false;
                this.executing = true;

                try {
                    const response = await genericViewQueryAsTextToExcel(this, this.queryspec, this.parametersWithValue());

                    if (response.data) {
                        const filename = this.selectedLibraryTextual + '_query.xlsx';
                        const blob = new Blob([response.data], { type: response.headers['Content-Type'] });
                        saveAs(blob, filename);
                    }
                } catch (ex) {
                    this.showError(ex, m => {
                        this.error = m;
                        this.errorActive = true;
                    });
                }
                this.executing = false;
            },
            parametersWithValue() {
                return this.parameters.filter(p => p.value && p.value.length > 0);
            },
            async clickRun() {
                const missingParams = _.differenceBy(this.parameters, this.parametersWithValue(), x => x.name).map(x => x.name);
                if (missingParams.length > 0) {
                    this.$buefy.dialog.confirm({
                        message: `Missing parameters: ${missingParams.join(', ')}.<br>Run query anyway?`,
                        cancelText: 'No',
                        confirmText: 'Yes',
                        onConfirm: this.run
                    });
                } else {
                    await this.run();
                }
            },
            async run() {
                this.executing = true;
                this.errorActive = false;
                this.result = null;

                try {
                    this.result = await genericViewQueryAsText(this, this.queryspec, this.parametersWithValue());
                } catch (ex) {
                    this.showError(ex, m => {
                        this.error = m;
                        this.errorActive = true;
                    });
                }

                this.executing = false;
            },
            async libSelected(name) {
                await this.selectLib(name);
            },
            async clickSave() {
                if (this.query.id) {
                    await this.save(this.query.name);
                } else {
                    const self = this;
                    this.$buefy.dialog.prompt({
                        message: 'New query name:',
                        inputAttrs: {
                            minlength: 1,
                            maxlength: 52,
                            required: true
                        },
                        onConfirm: self.save
                    });
                }
            },
            async save(name) {
                try {
                    this.query.name = name;
                    const request = { ...this.query, spec: this.queryspec };
                    if (this.query.id) {
                        await updateSavedQuery(this, this.query.id, request);
                        await this.loadQueries();
                    } else {
                        const createResult = await createSavedQuery(this, request);
                        await this.loadQueries();
                        this.querySelected(createResult.data.id);
                    }
                    this.showInfo('Query saved');
                } catch (ex) {
                    this.showError(ex, m => {
                        this.error = m;
                        this.errorActive = true;
                    });
                }
            },
            async clickDelete() {
                const self = this;
                this.$buefy.dialog.confirm({
                    title: 'Please confirm delete',
                    message: 'Are you sure you want to delete the saved query?',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: async () => {
                        try {
                            await deleteSavedQuery(self, self.query.id);
                            self.query.id = null;
                            await self.loadQueries();
                        } catch (error) {
                            this.showError(_.get(error, 'response.data.Message', 'Unable to delete item'));
                        }
                    }
                });
            },
            async clickToggleReport() {
                try {
                    const newIsReport = !this.query.isReport;
                    await savedQuerySetReport(this, this.query.id, newIsReport);
                    this.query.isReport = newIsReport;
                    this.showInfo('Report setting changed');
                    await this.loadQueries();
                } catch (ex) {
                    this.showError(ex, m => {
                        this.error = m;
                        this.errorActive = true;
                    });
                }
            },
            clickNewQuery() {
                this.clearUI();
                this.queryspec = 'FROM ';
            },
            clickViewUml() {
                this.showUml = !this.showUml;
            },
            clickHelp() {
                this.showHelp = !this.showHelp;
            },
            codeLinkFromDbId(id) {
                return getCodeLinkByB64Id(encodeIdBase64('Code', id));
            }
        }
    };
</script>

<style scoped>
.query-results {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 80vw;
    padding: 4px;
}
.help-column {
    z-index: 100;
    background-color: white;
}
.keep-spaces {
    white-space: pre;
}
</style>
