<template>
    <div>
        <div v-show="!selectedReport">
            <h2 class="title is-3">
                Handy lists
            </h2>
            <b-table
                striped
                narrowed
                default-sort="name"
                :data="reports">
                <b-table-column
                    v-slot="props"
                    field="name"
                    label="Name"
                    sortable>
                    <router-link :to="{ name: 'QueryReportById', params: { id : props.row.id }}">
                        <b-icon
                            icon="table-search"
                            size="is-small" />
                        {{ props.row.name }}
                    </router-link>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="library"
                    label="Base library"
                    sortable>
                    {{ props.row.library }}
                </b-table-column>
                <template #empty>
                    <div class="has-text-left">
                        No lists
                    </div>
                </template>
            </b-table>
        </div>
        <div v-if="selectedReport">
            <p style="margin-bottom:10px;">
                <router-link :to="{ name: 'QueryReportMain' }">
                    &laquo; Back to handy lists
                </router-link>
            </p>
            <h2 class="title is-3">
                {{ selectedReport.name }}
            </h2>
            <b-field
                v-for="p in parameters"
                :key="p.name"
                label-position="on-border"
                :label="p.name.substring(1)"
                :message="p.value ? '' : 'No parameter value specified'"
                :type="p.value ? '' : 'is-info'">
                <b-input v-model="p.value" />
            </b-field>
            <button
                :class="runButtonClass"
                title="Run query"
                @click="run()">
                <b-icon
                    icon="play"
                    size="is-small" />
                <span>Run query</span>
            </button>
            <button
                class="button"
                @click="exportReport()">
                <b-icon
                    icon="download"
                    size="is-small" />
                <span>Excel export</span>
            </button>
            <router-link
                class="button"
                :to="{ name: 'QueryBuilderById', params: { id: id }}">
                <b-icon
                    icon="table-search"
                    size="is-small" />
                <span>Open in Query Builder</span>
            </router-link>
            <div v-if="result && !executing">
                <b-table
                    striped
                    narrowed
                    paginated
                    pagination-position="top"
                    per-page="30"
                    :data="resultData"
                    :columns="resultColumns">
                    <template #empty>
                        <div class="has-text-left">
                            No records
                        </div>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
    import { genericViewSavedQuery, genericViewSavedQueryToExcel, getQueryReports } from '@/shared/helpers/api';
    import { showMixin } from '@/shared/mixins/showMixin';
    import saveAs from 'file-saver';
    import _ from 'lodash';

    export default {
        mixins: [
            showMixin
        ],
        props: {
            id: {
                default: null,
                type: Number
            }
        },
        data: function() {
            return {
                reports: [],
                selectedReport: null,
                parameters: [],
                result: null,
                executing: false,
                skipNavigationOnce: false
            };
        },
        computed: {
            runButtonClass() {
                return {
                    button: true,
                    'is-primary': true,
                    'is-loading': this.executing
                };
            },
            resultData() {
                if (this.result) {
                    return this.result;
                }
                return [];
            },
            resultColumns() {
                if (this.result && this.result.length > 0) {
                    const fieldNames = _.keys(this.result[0]);
                    return _.map(fieldNames, fn => ({
                        field: fn,
                        label: fn,
                        sortable: true
                    }));
                }
                return [];
            }
        },
        watch: {
            '$route': async function() {
                await this.doRouting();
            }
        },
        async mounted() {
            this.reports = await getQueryReports(this);
            await this.doRouting();
        },
        methods: {
            async doRouting() {
                const r = this.$route;
                if (r.name === 'QueryReportMain') {
                    this.selectedReport = null;
                    this.result = [];
                    this.parameters = [];
                } else if (r.name === 'QueryReportById') {
                    await this.selectReportById(this.id);
                }
            },
            async selectReportById(id) {
                if (this.skipNavigationOnce) {
                    this.skipNavigationOnce = false;
                    return;
                }
                this.selectedReport = _.find(this.reports, { id: id });
                const r = this.selectedReport;
                this.result = [];
                if (r.parameters === null || r.parameters === '') {
                    await this.run();
                } else {
                    this.parameters = r.parameters.split(',').map(pName => ({
                        name: '@' + pName,
                        value: this.$route.query[pName] ?? ''
                    }));
                }
            },
            async run() {
                this.executing = true;
                this.result = [];
                try {
                    this.result = (await genericViewSavedQuery(this, this.selectedReport.id, this.parametersWithValue())).data;
                    const urlParams = this.makeUrlParams();
                    if (urlParams) {
                        this.skipNavigationOnce = true;
                        const url = `/Report/Query/id/${this.selectedReport.id}?${urlParams}`;
                        if (url !== this.$route.fullPath) {
                            await this.$router.push(url);
                        }
                    }
                } catch (ex) {
                    this.showError(ex, m => {
                        this.error = m;
                        this.errorActive = true;
                    });
                }
                this.executing = false;
            },
            async exportReport() {
                try {
                    const response = await genericViewSavedQueryToExcel(this, this.selectedReport.id, this.parametersWithValue());

                    if (response.data) {
                        const filename = 'QueryReport.xlsx';
                        const blob = new Blob([response.data], { type: response.headers['Content-Type'] });
                        saveAs(blob, filename);
                    }
                } catch (ex) {
                    this.showError(ex, m => {
                        this.error = m;
                        this.errorActive = true;
                    });
                }
            },
            parametersWithValue() {
                return this.parameters.filter(p => p.value && p.value.length > 0);
            },
            makeUrlParams() {
                const ps = this.parameters.filter(p => p.value !== '');
                if (ps && ps.length > 0) {
                    return ps.map(p => `${p.name.substring(1)}=${encodeURIComponent(p.value)}`).join('&');
                }
                return null;
            }
        }
    };
</script>
