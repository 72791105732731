<template>
    <div>
        <div>
            <!-- Filter/buttons for cablecodes -->
            <div class="pb-2">
                <b-field
                    position="is-right"
                    grouped>
                    <b-field>
                        <b-button
                            class="button is-secondary"
                            :disabled="isUnchanged"
                            @click="eraseChange">
                            <span class="icon is-small">
                                <i class="fa fa-eraser" />
                            </span>
                            <span>Remove change</span>
                        </b-button>
                    </b-field>
                    <b-field>
                        <b-button
                            v-require-can-edit-code="permissionsObject"
                            class="button is-info"
                            :disabled="!selectedCableId"
                            @click="activateModal(1)">
                            <span class="icon is-small">
                                <i class="fa fa-fw fa-pencil-alt" />
                            </span>
                            <span>Edit selected cable code</span>
                        </b-button>
                    </b-field>
                    <b-field>
                        <b-button
                            v-require-can-edit-code="permissionsObject"
                            class="button is-info"
                            @click="activateModal(0)">
                            <span class="icon is-small">
                                <i
                                    class="fa fa-plus-circle"
                                    aria-hidden="true" />
                            </span>
                            <span>New cable code</span>
                        </b-button>
                    </b-field>
                </b-field>
            </div>
            <b-table
                ref="cableTable"
                :striped="true"
                :hoverable="true"
                :bordered="true"
                :data="tableRows"
                :selected="selectedRow"
                custom-row-key="identity"
                default-sort="values.meta.isNew"
                @update:selected="onSelectRow">
                <b-table-column
                    v-slot="props"
                    width="40">
                    <b-icon
                        v-if="props.row.meta.edited && !props.row.meta.isNew"
                        type="is-danger"
                        class="fa fa-fw fa-pencil-alt" />
                    <b-icon
                        v-if="props.row.meta.isNew"
                        type="is-danger"
                        class="fa fa-fw fa-plus-square" />
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="name"
                    label="Cable code"
                    searchable
                    sortable>
                    {{ props.row.name }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="Screen"
                    label="Screen"
                    sortable>
                    {{ props.row.Screen ? props.row.Screen : 'None' }}
                </b-table-column>
                <b-table-column
                    field="CableCategory"
                    label="Category"
                    searchable
                    sortable>
                    <template #searchable>
                        <b-select
                            v-model="filters.category"
                            placeholder="Select a cable cat">
                            <option :value="''">
                                All categories
                            </option>
                            <option
                                v-for="category in originalCodes(cableCatLibName)"
                                :key="category.values.identity"
                                :value="category.values.identity">
                                {{ `${category.values.name} - ${category.values.description}` }}
                            </option>
                        </b-select>
                    </template>
                    <template #default="props">
                        {{ props.row.CableCategory }}
                    </template>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="description"
                    label="Description"
                    searchable
                    sortable>
                    {{ props.row.description }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="MudResistFlag"
                    label="Mud resistant"
                    width="100"
                    centered
                    sortable>
                    <bool-element
                        size="is-medium"
                        custom-size="mdi-18px"
                        :value="props.row.MudResistFlag" />
                </b-table-column>
                <b-table-column
                    field="isValid"
                    label="Valid"
                    width="4.5em"
                    searchable
                    centered
                    sortable>
                    <template
                        #searchable>
                        <div
                            class="table-filter-checkbox">
                            <b-checkbox
                                v-model="filters.valid"
                                style="margin-right: -0.5em" />
                        </div>
                    </template>
                    <template
                        #default="props">
                        <bool-element
                            size="is-medium"
                            custom-size="mdi-18px"
                            :value="props.row.isValid" />
                    </template>
                </b-table-column>
            </b-table>
            <div
                id="pagetop"
                class="container sticky-bottom">
                <transition name="fade">
                    <div
                        v-show="showPageTopButton"
                        class="to-top"
                        @click="scrollToTop">
                        Back to top
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#4a5568"
                            stroke-width="1"
                            stroke-linecap="square"
                            stroke-linejoin="arcs">
                            <path d="M18 15l-6-6-6 6" />
                        </svg>
                    </div>
                </transition>
            </div>
            <!-- Modal Edit/New cable -->
            <div v-if="tableRows.length">
                <b-modal
                    v-model="modalActive[0]"
                    :can-cancel="!cableCodeSaving"
                    has-modal-card>
                    <cable-code-form
                        :library="cableCodeLibName"
                        header="New cable code"
                        @saving="cableCodeSaving = $event"
                        @close="onClose('new', $event)" />
                </b-modal>

                <b-modal
                    v-if="selectedCableId"
                    v-model="modalActive[1]"
                    :can-cancel="!cableCodeSaving"
                    has-modal-card>
                    <cable-code-form
                        :library="cableCodeLibName"
                        header="Edit cable code"
                        :existing-id="selectedCableId"
                        @close="onClose('edited', $event)" />
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { createCableCodeTableRow, libNames } from '@/shared/helpers/cableEditHelpers.js';
    import { scrollToSelectedTableRow } from '@/shared/helpers/utils.js';
    import { showMixin } from '@/shared/mixins/showMixin.js';
    import { useCodeEditStore } from '@/stores/codeEditStore.js';
    import { mapActions, mapState, mapStores } from 'pinia';
    import BoolElement from '../../../shared/components/BoolElement.vue';
    import CableCodeForm from './CableCodeForm.vue';

    const localStorageKeys = {
        cableEditCode: 'cableEditCode'
    };

    export default {
        components: {
            BoolElement,
            CableCodeForm
        },
        directives: {
            requireCanEditCode
        },
        mixins: [
            showMixin
        ],
        props: {
            headerHeight: {
                type: Number,
                default: 0,
                required: false
            }
        },
        data: function() {
            return {
                cableCodeLibName: libNames.cableCode,
                cableCatLibName: libNames.cableCat,
                filters: {
                    search: '',
                    category: '',
                    valid: true
                },
                modalActive: [false, false],
                cableCodeSaving: false,
                showPageTopButton: false
            };
        },
        computed: {
            ...mapStores(useCodeEditStore),
            ...mapState(useCodeEditStore, ['scope']),
            tableRows: function() {
                let search = null;
                if (this.filters.search !== '') {
                    search = this.filters.search.toLowerCase();
                }

                const cableCats = this.codeEditStore.codes[libNames.cableCat];
                const cableScreens = this.codeEditStore.codes[libNames.cableScreen];
                return this.filteredCodes(libNames.cableCode)
                    .filter(c =>
                        (this.filters.category !== '' ? c.values.CableCategory === this.filters.category : true)
                        && (this.filters.valid ? c.values.isValid : true)
                        && (search ? c.values.name.toLowerCase().includes(search) || c.values.description.toLowerCase().includes(search) : true))
                    .map(c => createCableCodeTableRow(c, cableCats, cableScreens))
                    .sort((a, b) => {
                        if (a.meta.isNew && !b.meta.isNew) {
                            return -1;
                        } else if (!a.meta.isNew && b.meta.isNew) {
                            return 1;
                        } else {
                            return a.name.localeCompare(b.name);
                        }
                    });
            },
            selectedRow: function() {
                return this.tableRows.find(row => row.identity === this.selectedCableId);
            },
            selectedCableCode: function() {
                return this.selectedCode(libNames.cableCode);
            },
            selectedCableId: function() {
                return this.selectedCableCode?.values?.identity;
            },
            isUnchanged: function() {
                return !this.selectedCableCode
                    || !(this.selectedCableCode.meta.isNew || this.selectedCableCode.meta.edited);
            },
            permissionsObject() {
                return {
                    libraryName: 'CableCode',
                    scopes: this.scope ? [this.scope] : []
                };
            }
        },
        watch: {
            selectedCableId: function(newValue) {
                if (newValue) {
                    localStorage.setItem(localStorageKeys.cableEditCode, newValue);
                }
            }
        },
        mounted: async function() {
            this.loadCableSelection();

            this.filters.valid = true;
            window.addEventListener('scroll', this.handleScroll);
        },
        beforeUnmount() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        activated: async function() {
            this.filters.valid = true;

            // Because of weird behaviour due to use of tabs
            // this is necessary to scroll to selected cable|
            // when changing back to this tab.
            const tableList = this.tableRows.map(row => row.identity);
            this.$nextTick(function() {
                this.$nextTick(() => {
                    if (this.selectedCableCode) {
                        scrollToSelectedTableRow(tableList, this.selectedCableCode.values.identity, this.$refs.cableTable.$el.querySelector('tbody'));
                    }
                });
            });
        },
        methods: {
            ...mapActions(useCodeEditStore, ['filteredCodes', 'originalCodes', 'selectedCode']),
            loadCableSelection: function() {
                const cableId
                    = this.codeEditStore.selectedCodes[libNames.cableCode]
                        || localStorage.getItem(localStorageKeys.cableEditCode);
                if (!cableId)
                    return;

                const cable = this.filteredCodes(libNames.cableCode).find(c => c.values.identity === cableId);
                if (!cable)
                    return;

                this.setSelectedCableCode(cable);
            },
            setSelectedCableCode: function(cableCode) {
                if (!cableCode || !cableCode.values.isValid && this.filters.valid) {
                    cableCode = null;
                }
                this.codeEditStore.selectCode(libNames.cableCode, cableCode?.values.identity);
                const selectedCategory = this.selectedCableCode?.values.CableCategory;
                this.codeEditStore.selectCode(libNames.cableCat, selectedCategory);
            },
            activateModal: function(num) {
                this.modalActive[num] = true;
            },
            eraseChange: function() {
                this.codeEditStore.removeChange(libNames.cableCode, this.selectedCableId);
                this.setSelectedCableCode(null);
            },
            onClose: function(operation, code) {
                if (code.values.identity) {
                    this.setSelectedCableCode(code);
                    if (operation === 'new') {
                        this.scrollToTop();
                    }
                }
                if (operation === 'new') {
                    this.modalActive[0] = false;
                }
                if (operation === 'edited') {
                    this.modalActive[1] = false;
                }
            },
            onSelectRow: function(row) {
                const cableCode = this.filteredCodes(libNames.cableCode).find(c => c.values.identity === row.identity);
                this.setSelectedCableCode(cableCode);
            },
            scrollToTop: function() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            handleScroll: function() {
                this.showPageTopButton = window.scrollY > 100;
            }
        }
    };
</script>

<style scoped>
.to-top {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #4a5568;
    background-color: white;
}
.sticky-bottom {
    position: sticky;
    bottom: 0;
    width: 25%;
}
.field :deep(.label) {
    margin-right: 1rem;
}
#pagetop :hover{
    cursor: pointer;
}
/* Since the `searchable` slot is wrapped in a `span`, we need to use a sized `div` inside the slot to center the
 * checkbox */
.table-filter-checkbox {
    width: 4.5em; /* same as column width */
    height: 2.5em; /* same as the height of a `BInput` in neighboring column */
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
