<template>
    <b-table
        :data="details"
        :default-sort="['codeSetDescription', 'asc']"
        :striped="false"
        :narrowed="true"
        :hoverable="false"
        :loading="loadingDetails"
        :sticky-header="true"
        :height="tableHeight"
        marginless
        :row-class="rowCssClass">
        <b-table-column
            v-slot="props"
            field="codeSetDescription"
            label="Set"
            :searchable="isSearchable"
            sortable>
            <router-link :to="getCodeSetLink(props.row.codeSetName)">
                <b-icon
                    icon="link-variant"
                    size="is-small" />
                {{ props.row.codeSetDescription }}
            </router-link>
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="lockedForDelete"
            label=""
            sortable>
            <b-tooltip :label="props.row.lockedForDelete ? 'Locked for delete' : 'Open for delete'">
                <b-icon
                    size="is-small"
                    type="is-info"
                    :icon="props.row.lockedForDelete ? 'lock' : 'lock-off'" />
            </b-tooltip>
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="isReleaseReady"
            label=""
            sortable>
            <b-tooltip :label="props.row.isReleaseReady ? 'Released' : 'Not ready for release'">
                <b-icon
                    size="is-small"
                    type="is-info"
                    :icon="props.row.isReleaseReady ? 'cloud' : 'alert'" />
            </b-tooltip>
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="statisticsUpdatedDate"
            label="Updated"
            sortable>
            <b-tooltip
                multilined
                :label="props.row.statisticsUpdatedDate"
                position="is-right"
                style="white-space: nowrap">
                {{ $filters.dateFormatShort(props.row.statisticsUpdatedDate) }}
            </b-tooltip>
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="codeCount"
            label="#codes"
            numeric
            sortable>
            {{ props.row.codeCount }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="validCodeCount"
            label="#valid"
            numeric
            sortable>
            {{ props.row.validCodeCount }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            label="#invalid"
            numeric>
            {{ props.row.codeCount - props.row.validCodeCount }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="stidCodeCount"
            label="#codes STID"
            numeric
            sortable>
            {{ props.row.stidCodeCount }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="stidValidCodeCount"
            label="#valid STID"
            numeric
            sortable>
            {{ props.row.stidValidCodeCount }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="stidMissingInStidCodeCount"
            label="#missing STID"
            numeric
            sortable>
            {{ props.row.stidMissingInStidCodeCount }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="stidMissingInCommonLibCodeCount"
            label="#missing CL"
            numeric
            sortable>
            {{ props.row.stidMissingInCommonLibCodeCount }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="stidInBothButDifferentCodeCount"
            label="#diff w/STID"
            numeric
            sortable>
            {{ props.row.stidInBothButDifferentCodeCount }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="stidSyncDate"
            label="STID sync"
            sortable>
            <b-tooltip
                multilined
                :label="props.row.stidSyncDate"
                position="is-left"
                style="white-space: nowrap">
                {{ $filters.dateFormatShort(props.row.stidSyncDate) }}
            </b-tooltip>
        </b-table-column>
    </b-table>
</template>

<script>
    import { getCodeSetLink } from '@/shared/helpers/routing.js';

    export default {
        props: {
            details: {
                type: Array,
                required: true
            },
            loadingDetails: {
                type: Boolean,
                required: true
            },
            tableHeight: {
                type: String,
                required: true
            },
            isSearchable: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            rowCssClass(row) {
                return row.lockedForDelete ? 'is-danger' : '';
            },
            getCodeSetLink
        }
    };
</script>

<style scoped>

</style>
