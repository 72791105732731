import { encodeIdBase64 } from '@/shared/helpers/utils';
import { getCode } from '@/shared/helpers/api';

import Frontpage from '@/apps/frontpage/Frontpage.vue';
import ScheduleReport from '@/apps/report/ScheduleReport.vue';
import CodeSetStatisticsReport from '@/apps/report/CodeSetStatisticsReport.vue';
import UserProfile from '@/apps/userProfile/UserProfile.vue';
import LibraryList from '@/apps/codeLibrary/LibraryList.vue';
import LibraryNetwork from '@/apps/codeLibrary/LibraryNetwork.vue';
import CodeSet from '@/apps/codeLibrary/CodeSet.vue';
import Library from '@/apps/codeLibrary/Library.vue';
import ForeignObjectLibraryView from '@/apps/codeLibrary/ForeignObjectLibraryView.vue';
import Code from '@/apps/codeLibrary/Code.vue';
import QueryBuilder from '@/apps/queryBuilder/QueryBuilder.vue';
import Alias from '@/apps/config/Alias.vue';
import AdminCommands from '@/apps/config/AdminCommands.vue';
import ApplicationList from '@/apps/config/ApplicationList.vue';
import Application from '@/apps/config/Application.vue';
import Subscription from '@/apps/config/Subscription.vue';
import AccessGroupList from '@/apps/config/AccessGroupList.vue';
import AccessGroup from '@/apps/config/AccessGroup.vue';
import AccessPolicy from '@/apps/config/AccessPolicy.vue';
import AttachmentSearch from '@/apps/config/AttachmentSearch.vue';
import ChangeLogAdmin from '@/apps/config/ChangeLogAdmin.vue';
import Monitoring from '@/apps/config/Monitoring.vue';
import Publisher from '@/apps/config/Publisher.vue';
import ScheduleList from '@/apps/config/ScheduleList.vue';
import TagsConfig from '@/apps/config/TagsConfig.vue';
import Webhooks from '@/apps/config/Webhooks.vue';
import UIDesignSystem from '@/apps/config/UIDesignSystem.vue';
import LogList from '@/shared/components/LogList.vue';
import ReleaseList from '@/apps/release/ReleaseList.vue';
import MyReleases from '@/apps/release/MyReleases.vue';
import MyReleaseArchive from '@/apps/release/MyReleaseArchive.vue';
import Release from '@/apps/release/Release.vue';
import Log from '@/apps/release/Log.vue';
import CodeSetImport from '@/apps/codeSetImport/CodeSetImport.vue';
import CableEditContainer from '@/apps/cableEdit/components/CableEditContainer.vue';
import EleComponentTypeContainer from '@/apps/eleComponentType/EleComponentTypeContainer.vue';
import ChangeLog from '@/apps/changeLog/ChangeLog.vue';
import PipeClassEditorPage from '@/apps/STID_Pipe/PipeClassEditorPage.vue';
import FacilityAndProjectMainContainer from '@/apps/facilityAndProject/FacilityAndProjectMainContainer.vue';
import ProjectMasterContainer from '@/apps/facilityAndProject/ProjectMasterContainer.vue';
import FacilityContainer from '@/apps/facilityAndProject/FacilityContainer.vue';
import ProjectContainer from '@/apps/facilityAndProject/ProjectContainer.vue';
import ContractsContainer from '@/apps/facilityAndProject/ContractsContainer.vue';
import ProjectUI from '@/apps/project/ProjectUI.vue';
import SchemaContainer from '@/apps/schema/SchemaContainer.vue';
import SchemaDTO from '@/apps/schema/SchemaDTO.vue';
import SchemaList from '@/apps/schema/SchemaList.vue';
import SchemaModel from '@/apps/schema/SchemaModel.vue';
import SchemaClassEditor from '@/apps/schema/SchemaClassEditor.vue';
import SchemaInterfaceEditor from '@/apps/schema/SchemaInterfaceEditor.vue';
import SchemaAttributeEditor from '@/apps/schema/SchemaAttributeEditor.vue';
import SchemaClassMappingEditor from '@/apps/schema/SchemaClassMappingEditor.vue';
import SchemaRelationEditor from '@/apps/schema/SchemaRelationEditor.vue';
import SchemaAttributeMappingEditor from '@/apps/schema/SchemaAttributeMappingEditor.vue';
import SchemaRelationMappingEditor from '@/apps/schema/SchemaRelationMappingEditor.vue';
import UnitOfMeasure from '@/apps/unitofmeasure/UnitOfMeasure.vue';
import CodeTables from '@/apps/report/CodeTables.vue';
import QueryReportMain from '@/apps/report/QueryReportMain.vue';
import KpiMain from '@/apps/report/KpiMain.vue';
import LibraryCodeCount from '@/apps/report/LibraryCodeCount.vue';
import LsdMain from '@/apps/report/LsdMain.vue';
import MasterMapping from '@/apps/report/MasterMapping.vue';
import TreeMap from '@/apps/report/TreeMap.vue';
import Help from '@/apps/help/Help.vue';
import Lookup from '@/apps/lookup/Lookup.vue';
import Unauthorized from '@/shared/Unauthorized.vue';
import SqlEditor from '@/apps/sqlEditor/SqlEditor.vue';
import EnsNumberValidation from '@/apps/ens/EnsNumberValidation.vue';
import EnsFormatEditor from '@/apps/ens/components/EnsFormatEditor.vue';
import axios from 'axios';
import { createRouter, createWebHistory } from 'vue-router';

const roles = {
    IsAuthenticatedUser: 'IsAuthenticatedUser',
    IsAdministrator: 'IsAdministrator',
    CanEditRelease: 'CanEditRelease',
    CanEditLibrary: 'CanEditLibrary'
};

export const routes = [
    {
        path: '/',
        name: 'FrontPage',
        component: Frontpage,
        meta: {
            title: 'Frontpage',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Library',
        name: 'LibraryList',
        component: LibraryList,
        meta: {
            title: 'Library List',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Library/Library-network',
        name: 'LibraryNetwork',
        component: LibraryNetwork,
        meta: {
            title: 'Library Network',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Library/Library-network/lib/:library',
        name: 'LibraryNetworkForLibrary',
        component: LibraryNetwork,
        meta: {
            title: 'Library Network',
            permissions: [roles.IsAuthenticatedUser]
        }

    },
    {
        path: '/Library/:libraryName',
        name: 'Library',
        component: Library,
        meta: {
            title: (route) => route.params.libraryName,
            permissions: [roles.IsAuthenticatedUser],
            refreshKey: route => route.path
        }
    },
    {
        path: '/Library/codeset/:codeSetName',
        name: 'CodeSet',
        component: CodeSet,
        meta: {
            title: 'CodeSet',
            permissions: [roles.IsAuthenticatedUser],
            refreshKey: route => route.path
        },
        props: (route) => ({ codeSetName: route.params.codeSetName })
    },
    {
        path: '/Library/codeset/:codeSetName/code/:identity',
        name: 'codeOld',
        meta: {
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Library/code/:id',
        name: 'code',
        component: Code,
        meta: {
            title: 'Code',
            permissions: [roles.IsAuthenticatedUser],
            refreshKey: route => route.path
        },
        props: (route) => ({ id: route.params.id })
    },
    {
        path: '/Library/ForeignObjectLibrary/:libraryName',
        name: 'ForeignObjectLibrary',
        component: ForeignObjectLibraryView,
        meta: {
            title: 'Foreign Object Library View',
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ libraryParameter: route.params.libraryName })
    },
    {
        path: '/Query',
        name: 'QueryBuilder',
        component: QueryBuilder,
        meta: {
            title: 'Query Builder',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Query/Query/:id',
        name: 'QueryBuilderById',
        component: QueryBuilder,
        meta: {
            title: 'Query Builder',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Query/Library/:library',
        name: 'QueryBuilderByLibrary',
        component: QueryBuilder,
        meta: {
            title: 'Query Builder',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/UserProfile',
        name: 'Userprofile',
        component: UserProfile,
        meta: {
            title: 'User Profile',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Config/AccessGroups',
        name: 'AccessGroups',
        component: AccessGroupList,
        meta: {
            title: 'Access Group Configuration',
            permissions: [roles.IsAdministrator]
        }
    },
    {
        path: '/Config/AccessGroups/:groupId',
        name: 'AccessGroupDetails',
        component: AccessGroup,
        meta: {
            title: 'Access Group',
            permissions: [roles.IsAdministrator]
        },
        props: (route) => ({ groupId: parseInt(route.params.groupId) })
    },
    {
        path: '/Config/AccessPolicy',
        name: 'AccessPolicy',
        component: AccessPolicy,
        meta: {
            title: 'Access Policy Configuration',
            permissions: [roles.IsAdministrator]
        }
    },
    {
        path: '/Config/AdminCommands',
        name: 'AdminCommands',
        component: AdminCommands,
        meta: {
            title: 'Admin commands',
            permissions: [roles.IsAdministrator]
        }
    },
    {
        path: '/Config/Aliasing',
        name: 'Aliasing',
        component: Alias,
        meta: {
            title: 'Aliases configuration',
            permissions: [roles.IsAdministrator]
        }
    },
    {
        path: '/Config/Application',
        component: ApplicationList,
        children: [
            {
                path: '',
                name: 'ApplicationList',
                component: ApplicationList,
                meta: {
                    title: 'Application configuration',
                    permissions: [roles.IsAdministrator]
                }
            },
            {
                path: ':appName',
                name: 'ApplicationDetails',
                components: {
                    default: ApplicationList,
                    applicationDetails: Application
                },
                meta: {
                    title: 'Application Configuration Details',
                    permissions: [roles.IsAdministrator]
                },
                props: {
                    applicationDetails: (route) => ({ appName: route.params.appName })
                }
            },
            {
                path: ':appName/:subscriptionId',
                name: 'SubscriptionDetails',
                components: {
                    default: ApplicationList,
                    applicationDetails: Subscription
                },
                meta: {
                    title: 'Subscription',
                    permissions: [roles.IsAdministrator]
                },
                props: (route) => ({ appName: route.params.appName, subscriptionId: route.params.subscriptionId })
            }
        ]
    },
    {
        path: '/Config/AttachmentSearch',
        name: 'AttachmentSearch',
        component: AttachmentSearch,
        meta: {
            title: 'Search for attachments',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Config/ChangeLogAdmin',
        name: 'ChangeLogAdmin',
        component: ChangeLogAdmin,
        meta: {
            title: 'ChangeLog Admin',
            permissions: [roles.IsAdministrator]
        }
    },
    {
        path: '/Config/Monitoring',
        name: 'Monitor',
        component: Monitoring,
        meta: {
            title: 'Monitoring',
            permissions: [roles.IsAdministrator]
        }
    },
    {
        path: '/Config/Publisher',
        name: 'Publisher',
        component: Publisher,
        meta: {
            title: 'Publisher',
            permissions: [roles.IsAdministrator]
        }
    },
    {
        path: '/Config/Schedules',
        component: ScheduleList,
        meta: {
            title: 'Schedules',
            permissions: [roles.IsAdministrator]
        }
    },
    {
        path: '/Config/Tags',
        component: TagsConfig,
        meta: {
            title: 'Library Groups',
            permissions: [roles.IsAdministrator, roles.CanEditLibrary]
        }
    }, // did not bring the route :tag over because it did not look like it was in use.
    {
        path: '/Config/Webhooks',
        name: 'Webhooks',
        component: Webhooks,
        meta: {
            title: 'Webhooks configuration',
            permissions: [roles.IsAdministrator]
        },
        props: (route) => ({ hookId: route.query.hookId })
    },
    {
        path: '/Config/Webhooks/logs',
        name: 'WebhookLog',
        component: LogList,
        meta: {
            title: 'Webhook log',
            permissions: [roles.IsAdministrator]
        }
    },
    {
        path: '/Config/UI',
        name: 'UIDesignSystem',
        component: UIDesignSystem,
        meta: {
            title: 'UI Design System'
        }
    },
    {
        path: '/Release/',
        name: 'ReleaseList',
        component: ReleaseList,
        meta: {
            title: 'Common Library: All Releases',
            permissions: [roles.IsAdministrator, roles.CanEditRelease]
        }
    },
    {
        path: '/Release/id/:release',
        name: 'ReleaseById',
        component: Release,
        meta: {
            title: 'Common Library: Release',
            permissions: [roles.IsAdministrator, roles.CanEditRelease],
            refreshKey: route => route.path
        }
    },
    {
        path: '/Release/MyReleases',
        name: 'MyReleases',
        component: MyReleases,
        meta: {
            title: 'Common Library: My Releases',
            permissions: [roles.IsAdministrator, roles.CanEditRelease]
        }
    },
    {
        path: '/Release/MyReleases/Archive',
        name: 'MyReleasesArchive',
        component: MyReleaseArchive,
        meta: {
            title: 'Common Library: My Release Archive ',
            permissions: [roles.IsAdministrator, roles.CanEditRelease]
        }
    },
    {
        path: '/Log',
        name: 'Log',
        component: Log,
        meta: {
            title: 'Log',
            permissions: [roles.CanEditRelease, roles.IsAdministrator]
        },
        props: (route) => ({
            libraryName: route.query.libraryName,
            applicationName: route.query.applicationName,
            codeSetName: route.query.codeSetName,
            releaseId: route.query.releaseId
        })
    },
    {
        path: '/CodeSetImport',
        name: 'CodeSetImport',
        component: CodeSetImport,
        meta: {
            title: 'Excel Import',
            permissions: [roles.CanEditRelease, roles.IsAdministrator]
        }
    },
    {
        path: '/CableEdit',
        name: 'CableEdit',
        component: CableEditContainer,
        meta: {
            title: 'Cable  Edit',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/EleComponentType',
        name: 'ElectricalComponentType',
        component: EleComponentTypeContainer,
        meta: {
            title: 'Electrical Component Type',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/ChangeLog',
        name: 'ChangeLog',
        component: ChangeLog,
        meta: {
            title: 'ChangeLog - Common Library',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/TagFormat',
        name: 'TagFormat',
        component: EnsFormatEditor,
        meta: {
            title: 'Tag Format',
            permissions: [roles.IsAuthenticatedUser],
            refreshKey: route => route.path
        },
        props: () => ({
            ensType: 'TagFormat'
        })
    },
    {
        path: '/DocumentNumberingFormat',
        name: 'DocumentNumberingFormat',
        component: EnsFormatEditor,
        meta: {
            title: 'Document Numbering Format',
            permissions: [roles.IsAuthenticatedUser],
            refreshKey: route => route.path
        },
        props: () => ({
            ensType: 'DocumentNumberingFormat'
        })
    },
    {
        path: '/EnsNumber',
        name: 'EnsNumber',
        component: EnsNumberValidation,
        meta: {
            title: 'ENS number validation',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/PipeClassEditor',
        name: 'PipeClassEditor',
        component: PipeClassEditorPage,
        meta: {
            title: 'Pipe Class Editor',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/FacilityAndProject',
        name: 'ProjectMasterList',
        component: FacilityAndProjectMainContainer,
        meta: {
            title: 'Facility and Project',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/FacilityAndProject/project',
        name: 'ProjectList',
        component: FacilityAndProjectMainContainer,
        meta: {
            title: 'Facility and Project',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/FacilityAndProject/org-unit',
        name: 'OrgUnitList',
        component: FacilityAndProjectMainContainer,
        meta: {
            title: 'Facility and Project',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/FacilityAndProject/facility',
        name: 'FacilityList',
        component: FacilityAndProjectMainContainer,
        meta: {
            title: 'Facility and Project',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/FacilityAndProject/contract',
        name: 'ContractList',
        component: FacilityAndProjectMainContainer,
        meta: {
            title: 'Facility and Project',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/FacilityAndProject/by-id/:id',
        name: 'FacilityAndProjectById',
        component: ProjectMasterContainer,
        meta: {
            title: 'Facility and Project',
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ id: route.params.id })
    },
    {
        path: '/FacilityAndProject/facility/by-id/:id',
        name: 'FacilityById',
        component: FacilityContainer,
        meta: {
            title: 'Facility and Project',
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ id: route.params.id })
    },
    {
        path: '/FacilityAndProject/project/by-id/:id',
        name: 'ProjectById',
        component: ProjectContainer,
        meta: {
            title: 'Facility and Project',
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ id: route.params.id })
    },
    {
        path: '/FacilityAndProject/contract/by-id/:id',
        name: 'ContractById',
        component: ContractsContainer,
        meta: {
            title: 'Facility and Project',
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ id: route.params.id })
    },
    {
        path: '/LCI/',
        name: 'Project',
        redirect: () => { return { name: 'LCITab', params: { tab: 'Projects' } }; }
    },
    {
        path: '/LCI/:tab',
        name: 'LCITab',
        component: ProjectUI,
        meta: {
            title: r => r.params.tab,
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({
            tab: route.params.tab
        })
    },
    {
        path: '/Schema',
        name: 'SchemaList',
        component: SchemaList,
        meta: {
            title: 'Schema List',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Schema/specification/:identity',
        name: 'SchemaDetails',
        component: SchemaContainer,
        meta: {
            title: (route) => `${route.params.identity} Details`,
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ identity: route.params.identity })
    },
    {
        path: '/Schema/specification/model/:identity',
        name: 'SchemaModel',
        component: SchemaModel,
        meta: {
            title: (route) => `${route.params.identity} Model View`,
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ identity: route.params.identity })
    },
    {
        path: '/Schema/specification/dto/:identity',
        name: 'SchemaDTO',
        component: SchemaDTO,
        meta: {
            title: (route) => `${route.params.identity} Mapping`,
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ identity: route.params.identity })
    },
    {
        path: '/Schema/specification/ClassEditor/:identity',
        name: 'SchemaClassEditor',
        component: SchemaClassEditor,
        meta: {
            title: (route) => `${route.params.identity} Class Editor`,
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ identity: route.params.identity })
    },
    {
        path: '/Schema/specification/InterfaceEditor/:identity',
        name: 'SchemaInterfaceEditor',
        component: SchemaInterfaceEditor,
        meta: {
            title: (route) => `${route.params.identity} Interface Editor`,
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ identity: route.params.identity })
    },
    {
        path: '/Schema/specification/AttributeMappingEditor/:identity',
        name: 'SchemaAttributeMappingEditor',
        component: SchemaAttributeMappingEditor,
        meta: {
            title: (route) => `${route.params.identity} Attribute Mapping`,
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ identity: route.params.identity })
    },
    {
        path: '/Schema/specification/ClassMappingEditor/:identity',
        name: 'SchemaClassMappingEditor',
        component: SchemaClassMappingEditor,
        meta: {
            title: (route) => `${route.params.identity} Class Mapping`,
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ identity: route.params.identity })
    },
    {
        path: '/Schema/specification/RelationMappingEditor/:identity',
        name: 'SchemaRelationMappingEditor',
        component: SchemaRelationMappingEditor,
        meta: {
            title: (route) => `${route.params.identity} Relation Mapping`,
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ identity: route.params.identity })
    },
    {
        path: '/Schema/specification/AttributeEditor/:identity',
        name: 'SchemaAttributeEditor',
        component: SchemaAttributeEditor,
        meta: {
            title: (route) => `${route.params.identity} Attribute Editor`,
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ identity: route.params.identity })
    },
    {
        path: '/Schema/specification/RelationEditor/:identity',
        name: 'SchemaRelationEditor',
        component: SchemaRelationEditor,
        meta: {
            title: (route) => `${route.params.identity} Relation Editor`,
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ identity: route.params.identity })
    },
    {
        path: '/Sql',
        name: 'SqlEditor',
        component: SqlEditor,
        meta: {
            title: 'Sql Editor',
            permissions: [roles.IsAuthenticatedUser]
        }
    },

    {
        path: '/UoM',
        name: 'UnitOfMeasure',
        component: UnitOfMeasure,
        meta: {
            title: 'Unit of Measure',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/UoM/quantity/:quantityIdentity',
        name: 'Quantity',
        component: UnitOfMeasure,
        meta: {
            title: 'Quantity',
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ quantityIdentity: route.params.quantityIdentity })
    },
    {
        path: '/CodeTables',
        name: 'CodeTablesExport',
        component: CodeTables,
        meta: {
            title: 'Export Code Tables',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/TreeMap',
        name: 'TreeMap',
        component: TreeMap,
        meta: {
            title: 'Library Tree map view',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Report/Query',
        name: 'QueryReportMain',
        component: QueryReportMain,
        meta: {
            title: 'Handy Lists',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Report/Query/id/:id',
        name: 'QueryReportById',
        component: QueryReportMain,
        meta: {
            title: 'Handy Lists',
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ id: parseInt(route.params.id) })
    },
    {
        path: '/Report/KPI',
        name: 'KPIReport',
        component: KpiMain,
        meta: {
            title: 'Key Performance Indicators',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Report/LibraryCodeCount',
        name: 'LibraryCodeCount',
        component: LibraryCodeCount,
        meta: {
            title: 'Report: Library code count',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Report/LibraryScopeDistribution',
        name: 'LibraryScopeDistribution',
        component: LsdMain,
        meta: {
            title: 'Library Scope Distribution',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Report/LibraryStatistics',
        name: 'CodeSetStatisticsReport',
        component: CodeSetStatisticsReport,
        meta: {
            title: 'Library statistics',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Report/MasterMapping',
        name: 'MasterMapping',
        component: MasterMapping,
        meta: {
            title: 'Report: MasterMapping',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Report/Schedules',
        name: 'ScheduleReport',
        component: ScheduleReport,
        meta: {
            title: 'Report: Schedules',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Help',
        name: 'Help',
        component: Help,
        meta: {
            title: 'Help',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Help/:slug',
        name: 'HelpSlug',
        component: Help,
        meta: {
            title: 'Help',
            permissions: [roles.IsAuthenticatedUser]
        },
        props: (route) => ({ slug: route.params.slug })
    },
    {
        path: '/Lookup',
        name: 'Lookup',
        component: Lookup,
        meta: {
            title: 'Lookup',
            permissions: [roles.IsAuthenticatedUser]
        }
    },
    {
        path: '/Unauthorized',
        name: 'Unauthorized',
        component: Unauthorized,
        meta: {
            title: 'Access Denied',
            requireAuth: false
        },
        props: (route) => ({ page: route.params.page })
    },
    {
        /*
            Lowest Priority Route
            Matches everything
            New routes must be added above
         */
        path: '/:pathMatch(.*)*',
        redirect: to => {
            const newPath = rewriteLegacyPath(to.fullPath);
            if (newPath !== null) {
                return { fullPath: newPath };
            } else {
                return { name: 'FrontPage' };
            }
        }
    }
];

export const ensureLoggedIn = async (to, from, next) => {
    if (window.authService.isSignedIn()) {
        next();
    } else {
        await window.authService.signIn();
        next();
    }
};

export const requireAuthForRoutes = async (to, from, next) => {
    if (to.meta.requireAuth === false) {
        next();
    } else if (!to.meta.requireAuth) {
        const requiredPermissions = to.meta.permissions ?? [roles.IsAdministrator];
        const hasAccess = await window.authService.hasAnyPermission(requiredPermissions);
        await window.authService.getUserAttributes();

        if (hasAccess) {
            next();
        } else {
            next({ name: 'Unauthorized', params: { page: to.path } });
        }
    } else {
        next({ name: 'Unauthorized', params: { page: to.path } });
    }
};
export const rewriteTitle = (to, from, next) => {
    const defaultTitleExtension = ' - Common Library';
    if (to.meta?.title instanceof Function) {
        document.title = to.meta.title(to) + defaultTitleExtension;
    } else if (to.meta?.title) {
        document.title = to.meta.title + defaultTitleExtension;
    }
    next();
};

const oldHashRoutingPaths = [
    '',
    '/ChangeLog',
    '/Library',
    '/Query',
    '/Release',
    '/FacilityAndProject',
    '/CableEdit',
    '/Schema',
    '/UoM',
    '/EleComponentType',
    '/Config',
    '/Help',
    '/Report/Query'
];

const alternatives = oldHashRoutingPaths
    .map(s => s.replaceAll('/', '\\/'))
    .join('|');

const hashPattern = /\/?#/;
const pathPattern = new RegExp(`^(?:${alternatives})${hashPattern.source}`, 'i');

export function rewriteLegacyPath(path) {
    if (!pathPattern.test(path))
        return null;

    return path.replace(
        /(\/)?#(\/)?(\w*)/,
        (_fullMatch, leadingSlash, trailingSlash, path) => {
            if (path) {
                return '/' + path;
            } else if (leadingSlash || trailingSlash) {
                return '/';
            } else {
                return '';
            }
        }
    );
}

export const rewriteLegacyRouteUrls = (to, from, next) => {
    const destination = rewriteLegacyPath(to.fullPath);
    if (destination !== null) {
        next(destination);
    } else {
        next();
    }
};

/**
 * For all routes hitting the route names 'codeOld' we redirect to the correct code.
 * Redirecting in the 'routes' const is not supported with async calls, using a beforeEnter or beforeEach,
 * is the correct way to redirect if needing to do async operations.
 *
 * https://v3.router.vuejs.org/guide/essentials/redirect-and-alias.html#redirect
 * https://github.com/vuejs/vue-router/issues/2729
 *
 * @param to
 * @param from
 * @param next
 */
export const redirectOldCode = async (to, from, next) => {
    if (to.name !== 'codeOld') {
        next();
    } else {
        const codeIdentity = to.params.identity;
        const codeSetName = to.params.codeSetName;

        try {
            const code = await getCode({ $http: axios }, codeSetName, codeIdentity);
            if (code.id) {
                const encodedId = encodeIdBase64('Code', code.id);
                const destination = { name: 'code', params: { id: encodedId } };
                next(destination);
            } else {
                next('/');
            }
        } catch {
            next('/');
        }
    }
};

export const router = createRouter({
    history: createWebHistory(),
    routes
});
