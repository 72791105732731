<template>
    <div class="box">
        <table
            v-if="library"
            class="table is-striped is-fullwidth">
            <tbody>
                <tr>
                    <th>Description</th>
                    <td colspan="2">
                        {{ library.description }}
                    </td>
                </tr>
                <tr>
                    <th>Definition</th>
                    <td colspan="2">
                        {{ library.definition }}
                    </td>
                </tr>
                <tr>
                    <th>Alias</th>
                    <td>{{ library.alias }}</td>
                    <td class="helpCol">
                        <help-icon text="The library may also be known by this name" />
                    </td>
                </tr>
                <tr>
                    <th>Scopetype</th>
                    <td>
                        <type-icon
                            v-show="library.isGlobal"
                            type="global" />
                        <type-icon
                            v-show="library.scopeType == 'Facility'"
                            type="facility" />
                        {{ scopeDescription }}
                    </td>
                    <td class="helpCol">
                        <help-icon>
                            <template #helpContent>
                                A library may be global, or it may be scoped by some other library.<br>
                                "Country" is an example of a global library, as its values will be consistent for all facilities.<br>
                                "Location" is an example of a scoped library, as its values will be different for different facilities.
                            </template>
                        </help-icon>
                    </td>
                </tr>
                <tr v-show="!library.isGlobal">
                    <th>Is scope specific</th>
                    <td>
                        <bool-element
                            :value="library.isScopeSpecific"
                            :has-yes-no="true" />
                    </td>
                    <td class="helpCol">
                        <help-icon>
                            <template #helpContent>
                                The values in the library sets for this library are purely scope specific,<br>
                                meaning its content should not be copied when introducing new scopes.
                            </template>
                        </help-icon>
                    </td>
                </tr>
                <tr>
                    <th>Is foreign object</th>
                    <td>
                        <bool-element
                            :value="library.isForeignObject"
                            :has-yes-no="true" />
                        <span
                            v-if="library.isForeignObject">Source app: {{ library.foreignAppName }}</span>
                    </td>
                    <td class="helpCol">
                        <help-icon>
                            <template #helpContent>
                                Common Library will act as a proxy and get data from source when requested.<br>
                                The library will have limited usability, can't have attribute defintions,<br>
                                can't be referred to by other libraries, etc.
                            </template>
                        </help-icon>
                    </td>
                </tr>
                <tr v-show="!library.isForeignObject">
                    <th>Is case sensitive</th>
                    <td>
                        <bool-element
                            :value="library.isCaseSensitive"
                            :has-yes-no="true" />
                    </td>
                    <td class="helpCol">
                        <help-icon>
                            <template #helpContent>
                                The Name of items in this library will be case sensitive.<br>
                                <i>ABC</i>, <i>Abc</i>, and <i>abc</i> can coexist as distinct values.
                            </template>
                        </help-icon>
                    </td>
                </tr>
                <tr v-show="!library.isForeignObject">
                    <th>Name in identity</th>
                    <td>
                        <bool-element
                            :value="library.nameInIdentity"
                            :has-yes-no="true" />
                    </td>
                    <td class="helpCol">
                        <help-icon>
                            <template #helpContent>
                                The Name is part of the unique identifier of an item in this library.<br>
                                Additional attributes may also be included in the identity.<br>
                                If Name is <i>not</i> part of the identifier, at least one attribute must be.
                            </template>
                        </help-icon>
                    </td>
                </tr>
                <tr v-show="!library.isForeignObject">
                    <th>Name may be changed</th>
                    <td>
                        <bool-element
                            :value="library.nameMayChange"
                            :has-yes-no="true" />
                    </td>
                    <td class="helpCol">
                        <help-icon text="The Name of items can be changed. Can only be set if Name is not part of identity." />
                    </td>
                </tr>
                <tr v-show="!library.isForeignObject">
                    <th>UpperCase Names</th>
                    <td>
                        <bool-element
                            :value="library.areNamesUpperCase"
                            :has-yes-no="true" />
                    </td>
                    <td class="helpCol">
                        <help-icon text="All names will always be uppercase." />
                    </td>
                </tr>
                <tr v-show="!library.isForeignObject">
                    <th>Description required</th>
                    <td>
                        <bool-element
                            :value="library.descriptionIsRequired"
                            :has-yes-no="true" />
                    </td>
                    <td class="helpCol">
                        <help-icon text="Item description is required." />
                    </td>
                </tr>
                <tr v-show="!library.isForeignObject">
                    <th>Regex for Name</th>
                    <td>
                        {{ library.codeNameRegex }}
                    </td>
                    <td class="helpCol">
                        <help-icon text="Library item Names must be valid according to this regular expression." />
                    </td>
                </tr>
                <tr v-show="!library.isForeignObject">
                    <th>Regex for Description</th>
                    <td>
                        {{ library.descriptionRegex }}
                    </td>
                    <td class="helpCol">
                        <help-icon text="Library item Descriptions must be valid according to this regular expression." />
                    </td>
                </tr>
                <tr>
                    <th>Date created</th>
                    <td colspan="2">
                        {{ $filters.dateFormatShort(library.dateCreated) }}
                    </td>
                </tr>
                <tr>
                    <th>Date updated</th>
                    <td colspan="2">
                        {{ $filters.dateFormatShort(library.dateUpdated) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import BoolElement from '@/shared/components/BoolElement.vue';
    import HelpIcon from '@/shared/components/HelpIcon.vue';
    import TypeIcon from '@/shared/components/TypeIcon.vue';

    export default {
        components: {
            BoolElement,
            HelpIcon,
            TypeIcon
        },
        props: {
            library: {
                type: Object,
                required: true
            }
        },
        data: function() {
            return {
            };
        },
        computed: {
            scopeDescription: function() {
                if (this.library.isGlobal)
                    return 'Global library';
                if (this.library.scopeType === null || this.library.scopeType === '')
                    return 'Scoping has not been configured for this library';
                return `Scoped by ${this.library.scopeType}`;
            }
        }
    };
</script>

<style scoped>
    .helpCol {
        padding-right: 1px;
        padding-left: 2px;
    }
</style>
