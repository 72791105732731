<template>
    <div>
        <div class="columns">
            <div class="column is-one-third">
                <b-field
                    grouped
                    group-multiline>
                    <b-field
                        label="State"
                        label-position="inside">
                        <b-select
                            v-model="filters.state">
                            <option value="">
                                &lt;ALL&gt;
                            </option>
                            <option
                                v-for="x in projectStateList"
                                :key="x.identity"
                                :value="x.identity">
                                {{ x.name }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field
                        label="Category"
                        label-position="inside">
                        <b-select
                            v-model="filters.category">
                            <option value="">
                                &lt;ALL&gt;
                            </option>
                            <option
                                v-for="x in projectCategoryList"
                                :key="x.identity"
                                :value="x.identity">
                                {{ x.name }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field
                        label="Portfolio Org. unit"
                        label-position="inside">
                        <b-select
                            v-model="filters.orgUnit">
                            <option value="">
                                &lt;ALL&gt;
                            </option>
                            <option
                                v-for="x in orgUnitList"
                                :key="x.identity"
                                :value="x.identity">
                                {{ x.name }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field
                        label="Country"
                        label-position="inside">
                        <b-select
                            v-model="filters.country">
                            <option value="">
                                &lt;ALL&gt;
                            </option>
                            <option
                                v-for="x in countryList"
                                :key="x.identity"
                                :value="x.identity">
                                {{ x.name }}
                            </option>
                        </b-select>
                    </b-field>
                </b-field>

                <div
                    class="control has-icons-left">
                    <input
                        v-model="filters.text"
                        type="text"
                        class="input"
                        placeholder="Filter on name">
                    <span class="icon is-small is-left">
                        <i class="fa fa-search  " />
                    </span>
                </div>
            </div>

            <div class="column is-clearfix">
                <div class="field is-grouped is-pulled-right">
                    <p class="control">
                        <b-button
                            v-require-can-edit-code="{ libraryName: 'ProjectMaster' }"
                            class="is-primary"
                            @click="newProjectMaster">
                            <b-icon
                                icon="pencil"
                                size="is-small" />
                            <span>Create new</span>
                        </b-button>
                    </p>
                </div>
            </div>
        </div>

        <span
            id="newPMEditor">
            <div
                v-if="showNewPMEditor">
                <code-edit
                    :key="newPMEditorKey"
                    :quick-commit="true"
                    :show-cancel="true"
                    :code-set-name="projectMasterCodeSetName"
                    library="ProjectMaster"
                    :code-template-values="newProjectMasterTemplate"
                    @refresh="onSaveNew"
                    @cancel="cancelSaveNew" />
                <hr>
            </div>
        </span>
        <b-table
            :data="filteredProjectMasterList"
            default-sort="Name"
            detailed
            :striped="true"
            :narrowed="true"
            :hoverable="true"
            class="site-sticky-header">
            <b-table-column
                v-slot="props"
                field="Name"
                label="Name"
                :custom-sort="localeSort('Name')"
                sortable>
                <router-link :to="getProjectMasterLink(props.row)">
                    <b-icon
                        icon="link-variant"
                        size="is-small" /> {{ props.row.Name }}
                </router-link>
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="ProjectState"
                label="State"
                sortable>
                {{ props.row.ProjectState }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="ProjectCategory"
                label="Category"
                sortable>
                {{ props.row.ProjectCategory }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="PortfolioOrganizationalUnit"
                label="Portfolio Org. unit"
                sortable>
                {{ props.row.PortfolioOrganizationalUnit }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="Country"
                label="Country"
                sortable>
                {{ props.row.Country }}
            </b-table-column>
            <template
                #detail="props">
                <div class="field is-grouped is-grouped-multiline">
                    <template
                        v-for="[key, value] in Object.entries(props.row)"
                        :key="key">
                        <div
                            v-if="value != null && includeInExpandView(key)"
                            class="control">
                            <div
                                class="tags has-addons">
                                <span class="tag is-light">{{ key }}</span>
                                <span class="tag is-white">{{ value }}</span>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </b-table>
        <b-loading
            v-model="loading"
            :is-full-page="false"
            :can-cancel="false" />
    </div>
</template>

<script>
    import _ from 'lodash';
    import CodeEdit from '@/shared/components/CodeEdit.vue';
    import LocaleSort from '@/shared/mixins/localeSort';
    import { genericViewQueryAsText, getCodeSets } from '@/shared/helpers/api';
    import { requireCanEditCode } from '@/shared/directives/requirePermission';
    import { encodeIdBase64 } from '@/shared/helpers/utils';

    export default {
        directives: {
            'require-can-edit-code': requireCanEditCode
        },
        components: {
            CodeEdit
        },
        mixins: [LocaleSort],
        data: function() {
            return {
                loading: true,
                projectMasterList: [],
                projectCategoryList: [],
                orgUnitList: [],
                projectStateList: [],
                countryList: [],
                projectMasterCodeSetName: null,
                showNewPMEditor: false,
                newPMEditorKey: 1,
                filters: {
                    category: '',
                    orgUnit: '',
                    state: 'ACTIVE',
                    country: '',
                    text: ''
                },
                newProjectMasterTemplate: {
                    GUID: function() {
                        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
                        ).toUpperCase();
                    }
                }
            };
        },
        computed: {
            filteredProjectMasterList: function() {
                const filters = this.filters;
                let list = this.projectMasterList;
                if (filters.state.length > 0) {
                    list = list.filter(p => p.ProjectState === filters.state);
                }
                if (filters.orgUnit.length > 0) {
                    list = list.filter(p => p.PortfolioOrgUnit === filters.orgUnit);
                }
                if (filters.category.length > 0) {
                    list = list.filter(p => p.ProjectCategory === filters.category);
                }
                if (filters.country.length > 0) {
                    list = list.filter(p => p.Country === filters.country);
                }
                if (filters.text.length > 0) {
                    const parts = filters.text.toLowerCase().split(' ');
                    list = list.filter(function(x) {
                        const tmp = (x.Name + x.Description).toLowerCase();
                        return _.every(parts, function(p) {
                            return tmp.indexOf(p) > -1;
                        });
                    });
                }
                list = list.filter(x => x.isValid !== false);
                return list;
            }
        },
        watch: {
            projectMasterCodeSetName: function() {
                this.newPMEditorKey += 1;
            }
        },
        mounted: async function() {
            await this.loadData();
        },
        methods: {
            loadData: async function() {
                this.loading = true;

                const [csReq, orgUnitReq, projCatReq, projStateReq, countryReq, pmReq] = await Promise.all([
                    getCodeSets(this, 'ProjectMaster'),
                    genericViewQueryAsText(this, 'FROM OrganizationalUnit SELECT Name as name, Identity as identity'),
                    genericViewQueryAsText(this, 'FROM ProjectCategory SELECT Name as name, Identity as identity'),
                    genericViewQueryAsText(this, 'FROM ProjectState SELECT Name as name, Identity as identity'),
                    genericViewQueryAsText(this, 'FROM Country SELECT Name as name, Identity as identity'),
                    genericViewQueryAsText(this, 'FROM ProjectMaster WHERE IsValid=true')
                ]);

                this.projectMasterCodeSetName = csReq[0].name;
                this.projectMasterList = pmReq.data;

                const self = this;
                const sortByName = xs => _.sortBy(xs, x => x.name);
                const prepFilterList = function(req, attributeName) {
                    const filterCodes = req.data;
                    return sortByName(filterCodes.filter(x => _.some(self.projectMasterList, p => p[attributeName] === x.identity)));
                };

                this.orgUnitList = prepFilterList(orgUnitReq, 'PortfolioOrganizationalUnit');
                this.countryList = prepFilterList(countryReq, 'Country');
                this.projectCategoryList = prepFilterList(projCatReq, 'ProjectCategory');
                this.projectStateList = prepFilterList(projStateReq, 'ProjectState');

                this.loading = false;
            },
            includeInExpandView: function(key) {
                switch (key) {
                    case 'Id':
                    case 'Name':
                    case 'IsValid':
                    case 'Description':
                    case 'Identity':
                    case 'ProjectState':
                    case 'PortfolioOrganizationalUnit':
                    case 'ProjectCategory':
                    case 'Country':
                        return false;
                    default:
                        return !key.endsWith('_ID');
                }
            },
            getProjectMasterLink: function(c) {
                return { name: 'FacilityAndProjectById', params: { id: encodeIdBase64('Code', c.Id) } };
            },
            newProjectMaster: function() {
                this.showNewPMEditor = true;
            },
            onSaveNew: function() {
                // This is a navigation hack. A smoother way to handle tabs and hash navigation
                // must be found, but this seems to work for now.
                if (window.location.hash !== '#/') {
                    window.location.hash = '#/';
                }
                window.location.reload();
            },
            cancelSaveNew: function() {
                this.showNewPMEditor = false;
            }
        }
    };
</script>
