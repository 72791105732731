<template>
    <spinner :loading="loading">
        <div class="field is-grouped">
            <column-selector
                :library-name="library.name"
                :columns="columns"
                @hidden="displayColumnFilter($event, false)"
                @visible="displayColumnFilter($event, true)" />

            <ref-code-display-selector
                :library-name="library.name"
                :columns="columns"
                @change="onChangeCodeRefDisplayMode" />
        </div>
    </spinner>
</template>

<script>
    import RefCodeDisplaySelector from './RefCodeDisplaySelector.vue';
    import ColumnSelector from './ColumnSelector.vue';
    import { createCodeDefArray } from '@/shared/helpers/dataHelpers.js';
    import Spinner from '@/shared/components/Spinner.vue';
    export default {
        components: {
            RefCodeDisplaySelector,
            ColumnSelector,
            Spinner
        },
        props: {
            library: {
                type: Object,
                required: true
            }
        },
        emits: [
            'column-visibility',
            'coderef-display-mode'
        ],
        data: function() {
            return {
                columns: null,
                loading: true
            };
        },
        mounted: async function() {
            this.columns = createCodeDefArray(this.library.attributeDefinitions);
            this.loading = false;
        },
        onDestroy: function() {
            this.query.unsubscribe();
        },
        methods: {
            displayColumnFilter(col, displayBool) {
                this.$emit('column-visibility', (columns) => {
                    columns.find(c => c.field === col).visible = displayBool;
                });
            },
            onChangeCodeRefDisplayMode(col) {
                this.$emit('coderef-display-mode', (columns) => {
                    columns.find(c => c.field === col.field).referenceDisplayMode = col.referenceDisplayMode;
                });
            }
        }
    };
</script>

<style>

</style>
