<template>
    <b-table
        :data="codeHistoryItems"
        :loading="loadingCodeHistory"
        striped
        narrowed
        default-sort="dateUpdated"
        default-sort-direction="desc">
        <b-table-column
            label="Field"
            field="field"
            sortable
            searchable>
            <template
                #searchable="props">
                <b-autocomplete
                    v-model="props.filters.field"
                    placeholder="Filter by field"
                    open-on-focus
                    :data="sortedAttributeDefinitions"
                    field="name"
                    clearable>
                    <template #default="{ option }">
                        {{ option.name }}
                    </template>
                </b-autocomplete>
            </template>
            <template #default="props">
                {{ props.row.field }}
            </template>
        </b-table-column>
        <b-table-column
            v-slot="props"
            label="Old value"
            field="oldValue"
            sortable>
            <code-attribute-value
                :attribute-definition="props.row.attributeDefinition"
                :value="props.row.oldValue"
                :referable-codes="referableCodes" />
        </b-table-column>
        <b-table-column
            v-slot="props"
            label="New value"
            field="newValue"
            sortable>
            <code-attribute-value
                :attribute-definition="props.row.attributeDefinition"
                :value="props.row.newValue"
                :referable-codes="referableCodes" />
        </b-table-column>
        <b-table-column
            v-slot="props"
            label="Updated date"
            field="dateUpdated"
            sortable>
            <b-tooltip
                :label="`${props.row.dateUpdated} Show the release ${props.row.releaseId} that caused this change`"
                position="is-left"
                size="is-small"
                multilined>
                <router-link :to="getReleaseLink(props.row.releaseId)">
                    <div style="white-space: nowrap;">
                        <b-icon
                            icon="link-variant"
                            size="is-small" />
                        {{ $filters.dateFormatShort(props.row.dateUpdated) }}
                    </div>
                </router-link>
            </b-tooltip>
        </b-table-column>
        <b-table-column
            v-if="showUserInfo"
            v-slot="props"
            label="Updated by"
            field="createdBy"
            sortable>
            <name-with-email :user="props.row.createdBy" />
        </b-table-column>
        <template #empty>
            <div class="has-text-centered">
                No code history
            </div>
        </template>
    </b-table>
</template>

<script>
    import { getCodeHistory } from '@/shared/helpers/api';
    import NameWithEmail from './NameWithEmail.vue';
    import CodeAttributeValue from './CodeAttributeValue.vue';
    import { getReleaseLink } from '@/shared/helpers/routing';

    export default {
        components: {
            CodeAttributeValue,
            NameWithEmail
        },
        props: {
            codeId: {
                type: Number,
                required: true
            },
            showUserInfo: {
                type: Boolean,
                default: false
            },
            attributeDefinitions: {
                type: Array,
                required: true
            },
            referableCodes: {
                type: Object,
                required: true
            }
        },
        data: function() {
            return {
                codeHistory: null,
                loadingCodeHistory: false
            };
        },
        computed: {
            codeHistoryItems: function() {
                if (!this.codeHistory)
                    return [];

                return this.codeHistory.releasesWithChanges.flatMap(r =>
                    r.releaseChanges.changes.map(c =>
                        ({
                            releaseId: r.releaseId,
                            dateUpdated: r.dateUpdated,
                            createdBy: r.createdBy,
                            field: c.field,
                            oldValue: c.oldValue,
                            newValue: c.newValue,
                            attributeDefinition: this.getAttributeDefinitionForField(c.field)
                        })
                    )
                );
            },
            sortedAttributeDefinitions: function() {
                const sortedAttributeDefinitions = [...this.attributeDefinitions];
                return sortedAttributeDefinitions
                    .concat([{ name: 'Description' }, { name: 'IsValid' }, { name: 'Name' }])
                    .sort((a, b) => a.name.localeCompare(b.name));
            }
        },
        mounted: async function() {
            await this.loadCodeHistory();
        },
        methods: {
            loadCodeHistory: async function() {
                this.loadingCodeHistory = true;
                this.codeHistory = await getCodeHistory(this, this.codeId);
                this.loadingCodeHistory = false;
            },
            getAttributeDefinitionForField: function(fieldName) {
                return this.attributeDefinitions.find(a => a.name === fieldName);
            },
            getReleaseLink
        }
    };
</script>
