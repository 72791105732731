<template>
    <div
        v-require-is-administrator>
        <div>
            <h2 class="title is-3">
                Access Groups
            </h2>
            <nav class="level">
                <div class="level-left">
                    <div class="has-margin-top control has-icons-left">
                        <input
                            v-model="searchFilter"
                            type="text"
                            class="input"
                            placeholder="Filter groups">
                        <span class="icon is-small is-left">
                            <i class="fa fa-search  " />
                        </span>
                    </div>
                </div>

                <div class="level-right">
                    <p class="level-item">
                        <button
                            class="button is-info has-margin-top"
                            @click="addAccessGroup">
                            <span class="icon is-small">
                                <i
                                    class="fa fa-plus-circle"
                                    aria-hidden="true" />
                            </span>
                            <span>New Access Group</span>
                        </button>
                    </p>
                </div>
            </nav>

            <div class="columns is-desktop has-margin-top2">
                <div class="column is-full-tablet is-full-mobile">
                    <div class="">
                        <spinner :loading="loading">
                            <b-table
                                :data="filteredGroups"
                                default-sort="name"
                                :striped="true"
                                :narrowed="true"
                                :loading="loading"
                                :hoverable="true"
                                class="site-sticky-header">
                                <b-table-column
                                    v-slot="props"
                                    field="name"
                                    label="Name"
                                    sortable>
                                    <router-link :to="getAccessGroupLink(props.row.id)">
                                        <b-icon
                                            icon="link-variant"
                                            size="is-small" />
                                        {{ props.row.name }}
                                    </router-link>
                                </b-table-column>
                                <b-table-column
                                    v-slot="props"
                                    field="policy"
                                    label="Policy"
                                    sortable>
                                    {{ props.row.policy }}
                                </b-table-column>
                                <template #empty>
                                    <section class="section">
                                        <div class="content has-text-grey has-text-centered title is-6">
                                            <p>No Access Groups Found</p>
                                        </div>
                                    </section>
                                </template>
                            </b-table>
                        </spinner>
                    </div>
                </div>
            </div>

            <b-modal v-model="isEditing">
                <div class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">
                            Access Group
                        </p>
                    </header>
                    <section class="modal-card-body">
                        <form>
                            <b-field label="Name">
                                <b-input
                                    v-model="currentAccessGroup.name"
                                    type="text"
                                    placeholder="enter group name"
                                    required />
                            </b-field>
                            <b-field label="Policy">
                                <b-input
                                    v-model="currentAccessGroup.policy"
                                    type="text"
                                    placeholder="enter policy name"
                                    required />
                            </b-field>

                            <div class="has-margin-top2">
                                <button
                                    class="button"
                                    @click="isEditing = false">
                                    <b-icon
                                        icon="close-circle"
                                        size="is-small" />
                                    <span>
                                        Cancel
                                    </span>
                                </button>
                                <button
                                    class="is-primary button"
                                    :disabled="!(currentAccessGroup.name !== '' && currentAccessGroup.policy !== '')"
                                    @click="onSave">
                                    <span class="icon is-small">
                                        <i
                                            class="fa fa-check-circle"
                                            aria-hidden="true" />
                                    </span>
                                    <span>
                                        Done
                                    </span>
                                </button>
                            </div>
                        </form>
                    </section>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
    import 'moment';
    import Spinner from '@/shared/components/Spinner.vue';
    import { requireIsAdministrator } from '@/shared/directives/requirePermission';
    import { getAccessGroups, postAccessGroups } from '@/shared/helpers/api';
    import { getAccessGroupLink } from '@/shared/helpers/routing';
    import messageDialog from '@/shared/mixins/messageDialogMixin';
    import { showMixin } from '@/shared/mixins/showMixin';

    export default {
        directives: {
            'require-is-administrator': requireIsAdministrator
        },
        components: {
            Spinner
        },
        mixins: [
            showMixin,
            messageDialog
        ],
        data: function() {
            return {
                loading: true,
                isEditing: false,
                currentAccessGroup: {},
                accessGroups: [],
                searchFilter: ''
            };
        },
        computed: {
            filteredGroups: function() {
                if (this.accessGroups.length)
                    return this.accessGroups.filter(g => g.name.toLowerCase().includes(this.searchFilter.toLowerCase()));
                return [];
            }
        },
        mounted: async function() {
            await this.fetchAccessGroups();
        },
        methods: {
            addAccessGroup: function() {
                this.editAccessGroup({
                    name: '',
                    policy: ''
                });
            },
            editAccessGroup: function(accessGroup) {
                this.currentAccessGroup = accessGroup;
                this.isEditing = true;
            },
            fetchAccessGroups: async function() {
                try {
                    this.accessGroups = await getAccessGroups(this);
                } catch {
                    this.showError('Unable to load access groups');
                }
                this.loading = false;
            },
            createAccessGroup: async function(accessGroup) {
                try {
                    accessGroup.id = (await postAccessGroups(this, accessGroup)).data.id;
                    this.accessGroups.push(accessGroup);
                } catch {
                    this.showError('Unable to create access group');
                }
            },
            onSave: function(e) {
                e.preventDefault();
                this.createAccessGroup(this.currentAccessGroup);
                this.isEditing = false;
            },
            getAccessGroupLink
        }
    };
</script>
