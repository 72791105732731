<template>
    <h2 class="title is-5">
        Referring items
        <span v-if="referringCodes">
            ({{ referringCodes.length }} of {{ totalCount }})
        </span>
    </h2>
    <spinner
        :loading="refCodesLoading"
        :fullscreen="false">
        <div class="table-container">
            <b-table
                :data="referringCodes"
                default-sort="codeName"
                :striped="true"
                :narrowed="true"
                :hoverable="true">
                <b-table-column
                    v-slot="props"
                    field="codeName"
                    label="Item"
                    sortable>
                    <a :href="getCodeLinkByB64Id(props.row.codeId)">
                        <b-icon
                            icon="link-variant"
                            size="is-small" />
                        {{ props.row.codeName }}
                    </a>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="codeDescription"
                    label="Description"
                    sortable>
                    {{ $filters.truncate(props.row.codeDescription, 50) }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="codeSetName"
                    label="Library set"
                    sortable>
                    <a :href="getCodeSetLink(props.row.codeSetName)">
                        <b-icon
                            icon="link-variant"
                            size="is-small" />
                        {{ props.row.codeSetDescription }}
                    </a>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="libraryName"
                    label="Library"
                    sortable>
                    <router-link :to="getLibraryLink(props.row.libraryName)">
                        <b-icon
                            icon="link-variant"
                            size="is-small" />
                        {{ props.row.libraryName }}
                    </router-link>
                </b-table-column>
                <template #empty>
                    <section class="section">
                        <div class="content has-text-grey has-text-centered title is-6">
                            <p>No Referring Codes Found</p>
                        </div>
                    </section>
                </template>
            </b-table>
        </div>
    </spinner>
</template>

<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import { showMixin } from '@/shared/mixins/showMixin';
    import { getCodeLinkByB64Id, getLibraryLink, getCodeSetLink } from '@/shared/helpers/routing';
    import { getReferingCodes } from '@/shared/helpers/api';
    import { encodeIdBase64 } from '@/shared/helpers/utils';

    export default {
        components: {
            Spinner
        },
        mixins: [
            showMixin
        ],
        props: {
            codeIdentity: {
                type: String,
                required: true
            },
            codeSetName: {
                type: String,
                required: true
            }
        },
        data: function() {
            return {
                refCodesLoading: true,
                referringCodes: null,
                totalCount: null
            };
        },
        mounted: async function() {
            this.loadReferringCodes();
        },
        methods: {
            loadReferringCodes: async function() {
                const result = await getReferingCodes(this, this.codeSetName, this.codeIdentity);
                this.totalCount = result.totalCount;
                this.referringCodes = result.codes;
                this.referringCodes.forEach(refCode => refCode.codeId = encodeIdBase64('Code', refCode.codeId));
                this.refCodesLoading = false;
            },
            getLibraryLink,
            getCodeSetLink,
            getCodeLinkByB64Id
        }
    };
</script>

<style scoped>
    .table-container {
        max-height: 500px;
        overflow-y: auto;
    }
</style>
