import queryString from 'query-string';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpWrapper } from '@/shared/httpWrapper';
import '@/global';
import { SchemaCompositionOptions, SchemaDTO, SimpleSchema } from '@/shared/helpers/api/types/schemaModel.ts';
import {
    Application,
    Attachment,
    AttributeDefinition,
    ChangedCodeWithID,
    Code,
    CodeSet,
    CodeWithId,
    CommonLibraryUser,
    Job,
    Library,
    PublishFilter,
    Release,
    ReleaseState,
    ScopeCodeSetRelease,
    Subscription,
    SubscriptionStatus,
    Tag,
    Webhook
} from '@/shared/helpers/api/types/entities.ts';
import { QuerySpecVersioned } from '@/shared/helpers/api/types/genericViews.ts';

// =============================================================================
// common

export type RequestPayload = unknown;

export type ResponsePromise<T> = Promise<AxiosResponse<T>>;

async function getResData(self: HttpWrapper, urlPath: string, options?: AxiosRequestConfig) {
    const url = constructInternalUrl(urlPath);
    return getCommand(self, url, options);
}

async function getCommandEnhanced(self: HttpWrapper, urlPath: string, command?: RequestPayload) {
    const url = constructInternalUrl(urlPath);
    if (!command) {
        return await self.$http
            .get(url)
            .then(response => responseHandling(response))
            .catch(() => []);
    } else {
        return await self.$http
            .get(url, command)
            .then(response => responseHandling(response))
            .catch(() => []);
    }
}

async function postCommandEnhanced(self: HttpWrapper, urlPath: string, command?: RequestPayload) {
    const url = constructInternalUrl(urlPath);
    const res = await self.$http.post(url, command);
    return responseHandling(res);
}

function constructInternalUrl(urlPath: string) {
    return `${window.config.commonLibraryApiUrl}internal/${urlPath}`;
}

function constructExternalUrl(urlPath: string) {
    return `${window.config.commonLibraryApiUrl}api/${urlPath}`;
}

function responseHandling<T>(response: AxiosResponse<T>): T | [] {
    return {
        200: response.data,
        201: response.data
    }[response.status] || [];
}

async function getCommand(self: HttpWrapper, url: string, command?: AxiosRequestConfig) {
    if (!command)
        return (await self.$http.get(url)).data;
    else
        return (await self.$http.get(url, command)).data;
}

async function getFileCommand(self: HttpWrapper, partialUrl: string) {
    const url = constructExternalUrl(partialUrl);
    const options: AxiosRequestConfig = { responseType: 'blob' };
    return await self.$http.get(url, options);
}

async function patchCommand(self: HttpWrapper, urlPath: string, command?: RequestPayload) {
    const url = constructInternalUrl(urlPath);
    return await self.$http.patch(url, command);
}

async function putCommand(self: HttpWrapper, urlPath: string, command?: RequestPayload) {
    const url = constructInternalUrl(urlPath);
    return await self.$http.put(url, command);
}

async function postCommand(self: HttpWrapper, urlPath: string, command?: RequestPayload, options?: AxiosRequestConfig) {
    const url = constructInternalUrl(urlPath);
    return await self.$http.post(url, command, options);
}

async function deleteCommand(self: HttpWrapper, urlPath: string, command?: RequestPayload) {
    const url = constructInternalUrl(urlPath);
    return await self.$http.delete(url, { data: command, headers: { 'Content-Type': 'Application/json' } });
}

async function getResDataPub(self: HttpWrapper, urlPath: string, options?: AxiosRequestConfig) {
    const url = constructExternalUrl(urlPath);
    return getCommand(self, url, options);
}

async function postCommandPub(self: HttpWrapper, urlPath: string, command?: RequestPayload, options?: AxiosRequestConfig) {
    const url = constructExternalUrl(urlPath);
    return await self.$http.post(url, command, options);
}

export type NameValuePair = {
    name: string;
    value: string | number | boolean;
};

function requestParameters(ps: NameValuePair[]) {
    if (ps && ps.length > 0) {
        return '?' + ps.map(x => `${x.name}=${encodeURIComponent(x.value)}`).join('&');
    }
    return '';
}

// =============================================================================
// AccessGroup

export type AccessGroup = {
    id: number;
    name: string;
    policy: string;
};

export type ScopeIdWithType = {
    id: number;
    type: string;
};

export type AccessGroupWithData = AccessGroup & {
    libraries: string[];
    scopes: ScopeIdWithType[];
};

export type AccessGroupCreateModel = {
    name: string;
    policy: string;
};

export function getAccessGroups(self: HttpWrapper): Promise<AccessGroup[]> {
    return getResData(self, 'AccessGroup');
}
export function getAccessGroup(self: HttpWrapper, accessGroupId: number): Promise<AccessGroupWithData> {
    return getResData(self, `AccessGroup/${accessGroupId}`);
}
export function getAccessGroupForLibrary(self: HttpWrapper, libraryName: string): Promise<AccessGroup[]> {
    return getResData(self, `AccessGroup/ByLibraryName/${libraryName}`);
}
export function postAccessGroups(self: HttpWrapper, accessGroup: AccessGroupCreateModel): ResponsePromise<{ id: number }> {
    return postCommand(self, 'AccessGroup', accessGroup);
}
export function putAccessGroups(self: HttpWrapper, accessGroup: AccessGroup): ResponsePromise<void> {
    return putCommand(self, 'AccessGroup', accessGroup);
}
export function deleteAccessGroups(self: HttpWrapper, accessGroup: AccessGroup): ResponsePromise<void> {
    return deleteCommand(self, 'AccessGroup', accessGroup);
}
export function postAccessGroupLibrary(self: HttpWrapper, accessGroupName: string, library: string): ResponsePromise<void> {
    return postCommand(self, 'AccessGroup/Library', { accessGroupName: accessGroupName, libraryName: library });
}
export function deleteAccessGroupLibrary(self: HttpWrapper, accessGroupName: string, library: string): ResponsePromise<void> {
    return deleteCommand(self, 'AccessGroup/Library', { accessGroupName: accessGroupName, libraryName: library });
}

// =============================================================================
// CodeSet

export type CodeSetCreateModel = {
    name?: string;
    description?: string;
    definition?: string;
    isValid?: boolean;
    isReleaseReady?: boolean;
};

export function updateCodeSet(self: HttpWrapper, libraryName: string, codeSetName: string, data: CodeSetCreateModel): ResponsePromise<void> {
    return patchCommand(self, `Libraries/${encodeURIComponent(libraryName)}/codesets/${codeSetName}`, { ...data, library: libraryName });
}
export function newCodeSet(self: HttpWrapper, libraryName: string, description: string): ResponsePromise<{ libraryName: string; codeSetName: string }> {
    return postCommand(self, `Libraries/${encodeURIComponent(libraryName)}/codesets`, { library: libraryName, description, isValid: true });
}
export function getCodeSets(self: HttpWrapper, library: string, scope = ''): Promise<CodeSet[]> {
    return getResData(self, `Libraries/${encodeURIComponent(library)}/codesets?scope=${scope}`);
}
export function getCodeSetsByLibraries(self: HttpWrapper, libraries: string[], scope = ''): ResponsePromise<CodeSet[]> {
    return postCommand(self, `Libraries/codesets?scope=${scope}`, libraries);
}
export function getCodeSet(self: HttpWrapper, library: string, codeSetName: string): Promise<CodeSet> {
    return getResData(self, `Libraries/${encodeURIComponent(library)}/codesets/${codeSetName}`);
}
export function getCodeSetNameAndCodeIdentity(self: HttpWrapper, libraryName: string, scope: string, codeName: string): Promise<{ codeSetName: string; codeIdentity?: string }> {
    return getResData(self, `Libraries/${libraryName}/${scope}/${codeName}`);
}
export function createScopeCodeSetRelease(self: HttpWrapper, command: ScopeCodeSetRelease): ResponsePromise<Release> {
    return postCommand(self, 'Release/CreateScopeCodeSetRelease', command);
}

// =============================================================================
// CodeSetView

export type CodeSetViewResultCodeItem = {
    value: string;
    name?: string;
    description?: string;
    codeId?: string;
};

export type CodeSetViewResultCodeRow = {
    id: string;
    codeItems: CodeSetViewResultCodeItem[];
};

export type CodeSetViewResultCodeSet = {
    codeRow: CodeSetViewResultCodeRow[];
};

export type CodeSetViewResultData = {
    node: CodeSetViewResultCodeSet;
};

export type CodeSetViewResult = {
    data: CodeSetViewResultData;
};

export function getCodeSetView(self: HttpWrapper, codeSet: string): Promise<CodeSetViewResult> {
    return getResData(self, `CodeSetView/codeset/${codeSet}`);
}
export function getCodeSetViewOrderBy(self: HttpWrapper, codeSet: string, orderBy: string): Promise<CodeSetViewResult> {
    return getResData(self, `CodeSetView/codeset/${codeSet}?orderBy=${orderBy}`);
}

// =============================================================================
// CodeTables

export async function getCodeTables(self: HttpWrapper, scope: string[], libraries: string[], getValidOnly: boolean): Promise<Blob> {
    const requestOptions: AxiosRequestConfig = {
        params: {
            scope: scope,
            libraries: libraries,
            getValidOnly: getValidOnly
        },
        paramsSerializer: function(params) {
            return queryString.stringify(params, { arrayFormat: 'none' });
        },
        responseType: 'blob'
    };
    const responseData = await getResData(self, 'Excel/CodeTables', requestOptions);
    return new Blob([responseData], {
        type: responseData.type
    });
}

// =============================================================================
// Libraries

/**
 * A request for CodeEditContext. Needs to have at least one of codeId, codeSetId, and codeSetName.
 * See also /docs/codeedit.md.
 */
export type CodeEditContextRequest = {
    codeId?: number;
    codeSetId?: number;
    codeSetName?: string;
    codeRefsNotToLoad?: string[];
    referenceLibraryQueryOverrides?: Record<string, string>;
};

export type CodeEditContextRefCode = {
    identity: string;
    id: number;
    name: string;
    description: string;
    isValid: boolean;
};

export type CodeEditContext = {
    library: Library;
    code: Code;
    codeSet: string;
    referableCodes: Record<string, CodeEditContextRefCode[]>;
    codeNameRegex: string;
};

export type AttributeDefinitionDeleteModel = {
    attributeDefinitions: string[];
};

export type LibraryUpdateModel = {
    description?: string;
    definition?: string;
    alias?: string;
    specializedUI?: string;
    isValid?: boolean;
    isCaseSensitive?: boolean;
    areNamesUpperCase?: boolean;
    nameInIdentity?: boolean;
    nameMayChange?: boolean;
    descriptionIsRequired?: boolean;
    isForeignObject?: boolean;
    foreignAppName?: string;
    isGlobal?: boolean;
    isScopeSpecific?: boolean;
    scopeType?: string;
    codeNameRegex?: string;
    descriptionRegex?: string;
};

export type RelatedLibrariesLibraryAttributes = {
    id: number;
    name: string;
    attributes: string[];
};

export type RelatedLibrariesRelation = {
    fromId: number;
    fromName: string;
    toId: number;
    toName: string;
    label: string;
};

export type RelatedLibrariesDTO = {
    relations: RelatedLibrariesRelation[];
    libraries: RelatedLibrariesLibraryAttributes[];
};

export type LibraryListItem = {
    id: number;
    name: string;
    description: string;
    alias: string;
    isGlobal: boolean;
    scopeType: string;
    isForeignObject: boolean;
    areNamesUpperCase: boolean;
    attachmentKey?: string;
    tags: string[];
    accessGroups: string[];
};

export type LibraryStatisticsReadModel = {
    libraryName: string;
    isGlobal: boolean;
    allIsReleaseReady: boolean;
    allLockedForDelete: boolean;
    minStatisticsUpdatedDate: string;
    maxStatisticsUpdatedDate: string;
    codeSetCount: number;
    codeCount: number;
    validCodeCount: number;
    stidCodeCount: number;
    stidValidCodeCount: number;
    stidMissingInStidCodeCount: number;
    stidMissingInCommonLibCodeCount: number;
    stidInBothButDifferentCodeCount: number;
    minStidSyncDate: string | null;
    maxStidSyncDate: string | null;
};

export type CodeSetStatisticsReadModel = {
    codeSetName: string;
    codeSetDescription: string;
    lockedForDelete: boolean;
    isReleaseReady: boolean;
    statisticsUpdatedDate: string;
    codeCount: number;
    validCodeCount: number;
    stidCodeCount: number;
    stidValidCodeCount: number;
    stidMissingInStidCodeCount: number;
    stidMissingInCommonLibCodeCount: number;
    stidInBothButDifferentCodeCount: number;
    stidSyncDate: string | null;
};

function encodeURIComponentAllowNull(value: string | number | boolean | null) {
    // From MDN: "Other values are converted to strings."
    return encodeURIComponent(value as string);
}

export function fetchCodeEditContext(self: HttpWrapper, request: CodeEditContextRequest): ResponsePromise<CodeEditContext> {
    return postCommand(self, 'Libraries/codeedit', request);
}

export function getLibraries(
    self: HttpWrapper,
    scope = '',
    tag = '',
    includeAttributeDefintions = false,
    includeCodeSets = false,
    includeAttachment = false,
    isGlobal: boolean | null = null
): Promise<Library[]> {
    return getResData(self, `Libraries?scope=${encodeURIComponent(scope)}&tag=${encodeURIComponent(tag)}&includeAttributeDefintions=${encodeURIComponent(includeAttributeDefintions)}&includeCodeSets=${encodeURIComponent(includeCodeSets)}&includeAttachment=${encodeURIComponent(includeAttachment)}&isGlobal=${encodeURIComponentAllowNull(isGlobal)}`);
}

export function getLibrary(
    self: HttpWrapper,
    library: string,
    { includeAccessGroups = true, includeAttributeDefintions = false, includeAttachment = true, includeTags = true, includeCodeSets = true } = {}
): Promise<Library | string> {
    return getResData(self, `Libraries/${encodeURIComponent(library)}?includeAccessGroups=${includeAccessGroups}&includeAttributeDefintions=${includeAttributeDefintions}&includeAttachment=${includeAttachment}&includeTags=${includeTags}&includeCodeSets=${includeCodeSets}`);
}

export function newLibrary(self: HttpWrapper, library: string): ResponsePromise<{ name: string }> {
    return postCommand(self, 'Libraries', library);
}
export function getLibraryFromCodeSet(self: HttpWrapper, codeSet: string): Promise<CodeSet> {
    return getResData(self, `CodeSet?name=${codeSet}`);
}
export function getAttributeDefinitions(self: HttpWrapper, library: string): Promise<AttributeDefinition[]> {
    return getResData(self, `Libraries/${encodeURIComponent(library)}/attributedefinitions`);
}
export function putAttributeDefinitions(self: HttpWrapper, library: string, definitions: AttributeDefinition[]): ResponsePromise<void> {
    return putCommand(self, `Libraries/${encodeURIComponent(library)}/attributedefinitions`, definitions);
}
export function deleteAttributeDefinitions(self: HttpWrapper, library: string, definitions: AttributeDefinitionDeleteModel[]): ResponsePromise<void> {
    return deleteCommand(self, `Libraries/${encodeURIComponent(library)}/attributedefinitions`, { attributedefinitions: definitions });
}
export async function getCode(self: HttpWrapper, codeset: string, code: string): Promise<CodeWithId> {
    return await getResData(self, `Code?codeSet=${codeset}&code=${code}`);// `Libraries/${encodeURIComponent(library)}/codesets/${codeset}/codes?$filter=identity%3D${encodeURI(code)}`))[0] TODO: bugged. cannot handle codes with / in id
}
export function patchLibrary(self: HttpWrapper, library: string, command: LibraryUpdateModel): ResponsePromise<void> {
    return patchCommand(self, `Libraries/${encodeURIComponent(library)}`, command);
}
export function getLibraryWithScopes(self: HttpWrapper, library: string): Promise<Library> {
    return getResData(self, `Library?name=${encodeURIComponent(library)}`);
}
export function getLibrarySubscriptionsAndWebhooks(self: HttpWrapper, library: string): Promise<Subscription> {
    return getResData(self, `Libraries/subscriptions?libraryName=${encodeURIComponent(library)}`);
}
export function getLibraryRelatedLibraries(self: HttpWrapper, library: string): Promise<RelatedLibrariesDTO> {
    return getResData(self, `Libraries/related-libraries/${encodeURIComponent(library)}`);
}
export function getSpecializedUIs(self: HttpWrapper): Promise<string[]> {
    return getResData(self, 'Libraries/SpecializedUI');
}
export function getLibraryList(self: HttpWrapper): Promise<LibraryListItem[]> {
    return getResData(self, 'LibraryList');
}
export function getLibraryTreeMap(self: HttpWrapper): Promise<string> {
    return getResData(self, 'Library/Treemap');
}
export function getScopesForLibraries(self: HttpWrapper, libraries: string[]): ResponsePromise<AxiosResponse<{ [libraryName: string]: string[] }>> {
    return postCommand(self, `Libraries/GetScopesForLibraries`, libraries);
}
export function getLibrariesStatistics(self: HttpWrapper): Promise<LibraryStatisticsReadModel[]> {
    return getResData(self, 'Libraries/Statistics');
}
export function getLibrariesStatisticsWithScopeFilter(self: HttpWrapper, scopeName: string): Promise<LibraryStatisticsReadModel[]> {
    return getResData(self, `Libraries/StatisticsWithScopeFilter/${encodeURIComponent(scopeName)}`);
}
export function getLibraryStatisticDetails(self: HttpWrapper, libraryName: string, includeStidDiffOnly: boolean): Promise<CodeSetStatisticsReadModel[]> {
    return getResData(self, `Libraries/Statistics/${encodeURIComponent(libraryName)}?includeStidDiffOnly=${includeStidDiffOnly}`);
}
export function recomputeLibraryStatistics(self: HttpWrapper, libraryName: string): ResponsePromise<void> {
    return putCommand(self, `Libraries/RecomputeStatistics/${encodeURIComponent(libraryName)}`);
}

// =============================================================================
// Code

export type ReferableCode = {
    codeId: number;
    codeName: string;
    codeDescription: string;
    codeSetName: string;
    codeSetDescription: string;
    libraryName: string;
};

export type FindReferringCodesResult = {
    totalCount: number;
    codes: ReferableCode[];
};

export type CodeAttributeUpdateInfo = {
    createdBy: CommonLibraryUser | null;
    updatedBy: CommonLibraryUser | null;
    dateCreated: string | null;
    dateUpdated: string | null;
    attributeName: string | null;
};

export type CodeUpdateInfo = {
    createdBy: CommonLibraryUser | null;
    updatedBy: CommonLibraryUser | null;
    dateCreated: string | null;
    dateUpdated: string | null;
    codeId: number;
    attributes: CodeAttributeUpdateInfo[];
};

export type CodeHistoryItem = {
    releaseId: number;
    dateUpdated: string | null;
    createdBy: CommonLibraryUser;
    releaseChanges: ChangedCodeWithID;
};

export type CodeHistory = {
    codeId: number;
    releasesWithChanges: CodeHistoryItem[];
};

export function getReferingCodes(self: HttpWrapper, codeSet: string, id: number, libName: string = ''): Promise< FindReferringCodesResult> {
    return getResData(self, `Code/ReferringCodes?codeSet=${codeSet}&code=${encodeURIComponent(id)}&withLibrary=${libName}`);
}
export function getReferringCodesForInvalidCodes(self: HttpWrapper, codeSet: string, libName: string = ''): Promise<ReferableCode[]> {
    return getResData(self, `Code/ReferringCodesForInvalidCodes?codeSet=${codeSet}&withLibrary=${libName}`);
}
export function getIdentity(self: HttpWrapper, codeSetName: string, codeAttributes: Record<string, string>): ResponsePromise<string> {
    return postCommand(self, 'Code/Identity', { codeSetName, codeAttributes });
}
export function getCodeById(self: HttpWrapper, codeId: number): Promise<Code> {
    return getResData(self, `Code/${encodeURIComponent(codeId)}`);
}
export function getCodeUpdateInfo(self: HttpWrapper, codeId: number): Promise<CodeUpdateInfo> {
    return getResData(self, `Code/UpdateInfo?code=${codeId}`);
}
export function getCodeHistory(self: HttpWrapper, codeId: number): Promise<CodeHistory> {
    return getResData(self, `Code/History?codeId=${codeId}`);
}

// =============================================================================
// Logs

export type ChangeReceiverResult = {
    success: boolean;
    description: string;
    message: string;
    scopeName: string;
    codeResults: {
        codeName: string;
        codeIdentity: string;
        success: boolean;
        message: string;
    }[];
};

export type ReleaseLogStringData = {
    data: string;
};

export type ReleaseLogData =
    | ReleaseLogStringData
    | ChangeReceiverResult;

export type LogLevel = 'Info' | 'Warning' | 'Error';

export type ReleaseLog = {
    id: number;
    timestamp: string;
    codeSetName: string;
    codeSetDescription: string;
    libraryName: string;
    releaseId: number | null;
    publishJobId: number | null;
    message: string;
    data: ReleaseLogData;
    logLevel: LogLevel;
};

export function getLogs(self: HttpWrapper, filter: AxiosRequestConfig): Promise<ReleaseLog> {
    return getResData(self, 'Log/Get', filter);
}

// =============================================================================
// Menu

export type MenuItem = {
    id: number;
    name: string;
    description: string;
    parentId: number;
    childItems: MenuItem[];
    path: string;
    isHighlighted: boolean;
};

export function getDynamicMenuItems(self: HttpWrapper): Promise<MenuItem[]> {
    return getResData(self, 'Menu');
}

// =============================================================================
// Scopes

export function getScopeTypes(self: HttpWrapper): Promise<string[]> {
    return getResData(self, 'Scopes');
}
export function getUsedScopeTypes(self: HttpWrapper): Promise<string[]> {
    return getResData(self, 'Scopes/used');
}
export function getScopes(self: HttpWrapper, scopeType: string): Promise<string[]> {
    return getResData(self, 'Scopes/' + scopeType);
}
export function getScopesWithDescription(self: HttpWrapper, scopeType: string): Promise<string[] | string> {
    return getResData(self, `Scopes/${scopeType}/Description`);
}

export type ScopeRegEx = {
    /** Name of the Scope */
    name: string;
    /** Regex for validating the scope */
    regEx: string;
};

export type LsdLibraryResult = {
    Id: number;
    Library: string;
    Alias: string;
    Groups: string[];
    ScopeCounts: Record<string, number>;
    Total: number;
    IsGrouped: boolean | null;
    CodeSetIsReleaseReady: Record<string, boolean>;
};

export type LsdResult = {
    Scopes: string[];
    Groups: Record<string, boolean>;
    Libraries: LsdLibraryResult[];
};

/**
 * Fetches Regex for scopeType, returns a ScopeRegEx object when found and an empty string if not found.
 */
export function getScopesTypeRegex(self: HttpWrapper, scopeType: string): Promise<ScopeRegEx | string> {
    return getResData(self, `Scopes/${scopeType}/RegEx`);
}
export function getScopesByScopeTypes(self: HttpWrapper, scopeTypes: string[]): ResponsePromise<string[]> {
    return postCommand(self, 'Scopes/getScopes', scopeTypes);
}
export function getScopeTypeLibraryReport(self: HttpWrapper, scopeType: string): Promise<LsdResult> {
    return getResData(self, `Scopes/${encodeURIComponent(scopeType)}/library-report`);
}

// =============================================================================
// Tag

export type LibraryTagMetadata = {
    tag: string;
    name: string;
    value: string;
};

export function getTagUniqueKeys(self: HttpWrapper): Promise<string[]> {
    return getResData(self, 'Tag/Keys/Unique');
}
export function getTagMetadata(self: HttpWrapper, tag: string): Promise<Record<string, string>> {
    return getResData(self, `Tag/${encodeURIComponent(tag)}`);
}
export function getAllTags(self: HttpWrapper): Promise<string[]> {
    return getResData(self, 'Tag');
}
export function getAllTagsAsEntities(self: HttpWrapper): Promise<Tag[]> {
    return getResData(self, 'Tag/Entities');
}
export function getAttachmentForTag(self: HttpWrapper, tagName: string): Promise<Attachment> {
    return getResData(self, `Tag/Attachment/${tagName}`);
}
export function getTagMetadataForLibrary(self: HttpWrapper, libraryName: string): Promise<LibraryTagMetadata[]> {
    return getResData(self, `Tag/Library/${encodeURIComponent(libraryName)}`);
}
export function deleteTagMetadataElement(self: HttpWrapper, tag: string, element: string): ResponsePromise<void> {
    return deleteCommand(self, `Tag/${encodeURIComponent(tag)}/${encodeURIComponent(element)}`);
}
export function saveTagMetadata(self: HttpWrapper, tag: string, elements: string): ResponsePromise<void> {
    return postCommand(self, `Tag/${encodeURIComponent(tag)}`, elements);
}
export function tagLibrary(self: HttpWrapper, tag: string, library: string): ResponsePromise<void> {
    return postCommand(self, 'LibraryUpdate/Tag', { libraryName: library, tagName: tag });
}
export function untagLibrary(self: HttpWrapper, tag: string, library: string): ResponsePromise<void> {
    return postCommand(self, 'LibraryUpdate/Untag', { libraryName: library, tagName: tag });
}
export function updateTag(self: HttpWrapper, tag: string): ResponsePromise<void> {
    return putCommand(self, 'Tag/Entity', tag);
}

// =============================================================================
// Me

export function getMyUserName(self: HttpWrapper): Promise<string> {
    return getResData(self, 'Me');
}
/**
 * Fetches users permissions, returns an empty [] if user don't have any permissions.
 */
export function getMyPermissions(self: HttpWrapper): Promise<string[]> {
    return getCommandEnhanced(self, 'Me/permissions');
}
/**
 * Clears the Cache for the users attributes on the API. Returns only a HTTP 200 response if successful.
 */
export function clearAttributesApiCache(self: HttpWrapper): Promise<void> {
    return getResData(self, 'Me/clearcache');
}

export function validateAccessCanEditLibrary(self: HttpWrapper, library: string): Promise<boolean> {
    return getResData(self, `Me/ValidateAccess/CanEditLibrary/${encodeURIComponent(library)}`);
}
export function validateAccessCanEditCode(self: HttpWrapper, library: string, scopes: string[]): Promise<boolean> {
    return getResData(self, `Me/ValidateAccess/CanEditCode/${encodeURIComponent(library)}?scopes=${scopes.join('&scopes=')}`);
}
export function validateAccessCanEditTagByName(self: HttpWrapper, tagName: string): Promise<boolean> {
    return getResData(self, `Me/ValidateAccess/CanEditTag/Name/${encodeURIComponent(tagName)}`);
}
export function validateAccessCanTransitionRelease(self: HttpWrapper, releaseId: number): Promise<boolean> {
    return getResData(self, `Me/ValidateAccess/CanTransitionRelease/${releaseId}`);
}

// =============================================================================
// Releases

enum OrderByDirection {
    Ascending = 'Ascending',
    Decending = 'Decending' // Known typo in the API
}

export type PagingParameters = {
    take?: number;
    skip?: number;
    direction?: OrderByDirection;
};

export type ReleaseQueryFilter = {
    pagingParameters?: PagingParameters;
    states?: ReleaseState[];
    userId?: string;
    libraries?: string[];
    codeSets?: string[];
    scopes?: string[];
};

type BatchDocumentValue = string | number | boolean;

export type BatchDocumenCode = Record<string, BatchDocumentValue>;

export type BatchDocument = {
    codeSetName: string;
    codes: BatchDocumenCode[];
};

export type PublishCountPreview = {
    libraries: string[];
    facilities: string[];
    isValid: boolean | null;
    updatedSince: string | null;
};

export type ReleaseAutoSubmitResult = {
    releaseId: number;
    message: string;
    wasAutoSubmit?: boolean;
};

export type PublishCountResult = {
    count: number;
    libraries: {
        name: string;
        count: number;
        facilities: {
            name: string;
            count: number;
        }[];
    };
};

export type CheckIdentityRequest = {
    codeSetName: string;
    attributeValues: Record<string, unknown>;
};

export type CheckIdentityResult =
    | { conflict: true; message: string; identity: string }
    | { conflict: false };

export function getReleases(self: HttpWrapper, states: ReleaseState[] = []): Promise<Release[]> {
    return getResData(self, `Release?states=${states.join('&states=')}`);
}
export function getFilteredReleases(self: HttpWrapper, filter: ReleaseQueryFilter): ResponsePromise<Release[]> {
    return postCommand(self, 'Release/GetFiltered', filter);
}
export function getMyReleases(self: HttpWrapper, states: string[] = []): Promise<Release[]> {
    return getResData(self, `Release/mine?states=${states.join('&states=')}`);
}
export function getRelease(self: HttpWrapper, releaseId: number): Promise<Release> {
    return getResData(self, `Release/${releaseId}`);
}
export function createChangeDoc(self: HttpWrapper, codeSetName: string, codes: BatchDocumenCode[]): ResponsePromise<ReleaseAutoSubmitResult> {
    return postCommand(self, 'Release/autosubmit', { codeSetName, codes });
}
export function createChangeDocAndCommit(self: HttpWrapper, codeSetName: string, codes: BatchDocumenCode[]): ResponsePromise<ReleaseAutoSubmitResult> {
    return postCommand(self, 'Release/CreateAndCommit', { codeSetName, codes });
}
export function createChangeDocAndCommitCodesets(self: HttpWrapper, collection: BatchDocument): ResponsePromise<ReleaseAutoSubmitResult> {
    return postCommand(self, 'Release/CreateAndCommitCodesets', collection);
}
export function createReleaseChain(self: HttpWrapper, collection: BatchDocument): ResponsePromise<ReleaseAutoSubmitResult> {
    return postCommand(self, 'Release/CreateReleaseChain', collection);
}
export function getReleaseChanges(self: HttpWrapper, releaseId: number): Promise<ChangedCodeWithID[]> {
    return getResData(self, `Release/${releaseId}/Changes`);
}
export function removeReleaseChanges(self: HttpWrapper, releaseId: number, changeIds: number[]): ResponsePromise<void> {
    return deleteCommand(self, `Release/${releaseId}/Changes`, changeIds);
}
export function transitionRelease(self: HttpWrapper, releaseId: number, state: string): ResponsePromise<void> {
    return postCommand(self, `Release/Transition/${state}/${releaseId}`);
}
export function createPublishingRelease(self: HttpWrapper, specification: PublishFilter): ResponsePromise<Release> {
    return postCommand(self, 'Release/CreatePublishingRelease', specification);
}
export function deleteCode(self: HttpWrapper, codeSetName: string, codeIdentity: string): ResponsePromise<void> {
    return deleteCommand(self, 'Release/code', { codeSetName: codeSetName, codeIdentity: codeIdentity });
}
export function getPublishCount(self: HttpWrapper, query: PublishCountPreview): ResponsePromise<PublishCountResult> {
    return postCommand(self, 'Release/GetPublishCount', query);
}
export function checkIdentityConflict(self: HttpWrapper, codeSetName: string, code: CheckIdentityRequest): ResponsePromise<CheckIdentityResult> {
    return postCommand(self, 'Release/checkIdentityConflict', { codeSetName: codeSetName, attributeValues: code });
}
export function abortPublishingRelease(self: HttpWrapper, releaseId: number): ResponsePromise<void> {
    return putCommand(self, `Release/${releaseId}/AbortPublishing`);
}

// =============================================================================
// KPI

export type KpiDataCounts = {
    libraryCount: number;
    codeSetCount: number;
    codeCount: number;
    codeAttributeCount: number;
    tagCount: number;
    accessGroupCount: number;
    releaseCount: number;
    aliasCount: number;
    subscriptionCount: number;
    webhookCount: number;
    scheduleCount: number;
    userCount: number;
    usersLastWeek: number;
    usersLastMonth: number;
    usersLastYear: number;
    attachmentCount: number;
};

export type KpiResult = {
    labels: string[];
    series: Record<string, number[]>;
    sums: Record<string, number>;
};

export function getKpiDataCounts(self: HttpWrapper): Promise<KpiDataCounts> {
    return getResData(self, 'KPI/DataCounts');
}
export function getKpiReleaseStats(self: HttpWrapper, days: number): Promise<KpiResult> {
    return getResData(self, 'KPI/ReleaseStats?days=' + days);
}

// =============================================================================
// Favourites

export type Favourite = {
    id: number;
    userId: string;
    title: string;
    url: string;
    dateCreated: string;
};

export type FavouriteArguments = {
    title: string;
    url: string;
};

export function getFavourites(self: HttpWrapper): Promise<Favourite> {
    return getResData(self, 'favourite');
}
export function addFavourite(self: HttpWrapper, fav: FavouriteArguments): ResponsePromise<void> {
    return postCommand(self, 'favourite', fav);
}
export function updateFavourite(self: HttpWrapper, id: number, fav: FavouriteArguments): ResponsePromise<void> {
    return putCommand(self, `favourite/${id}`, fav);
}
export function deleteFavourite(self: HttpWrapper, id: number): ResponsePromise<void> {
    return deleteCommand(self, `favourite/${id}`);
}

// =============================================================================
// Reports

export function getReport(self: HttpWrapper, reportName: string, filter: string): Promise<unknown[]> {
    return getResDataPub(self, `Report/${encodeURIComponent(reportName)}?$filter=${filter}`);
}

// =============================================================================
// WebHooks

export type WebhookSubscriptionModel = {
    callbackUrl: string;
    ownerId?: string;
    library: string;
    secret: string;
    enabled: boolean;
};

export function getWebHooks(self: HttpWrapper): Promise<Webhook[]> {
    return getResData(self, 'Webhooks');
}
export function addWebhook(self: HttpWrapper, hook: WebhookSubscriptionModel): ResponsePromise<{ id: number }> {
    return postCommand(self, 'Webhooks', hook);
}
export function deleteWebhook(self: HttpWrapper, id: number): ResponsePromise<void> {
    return deleteCommand(self, `Webhooks/${id}`);
}
export function enableWebHook(self: HttpWrapper, id: number): ResponsePromise<void> {
    return patchCommand(self, `Webhooks/${id}/enable`);
}
export function disableWebHook(self: HttpWrapper, id: number): ResponsePromise<void> {
    return patchCommand(self, `Webhooks/${id}/disable`);
}

// =============================================================================
// Monitoring

export type JobIssueDTO = {
    jobId: number;
    jobState: string;
    jobType: string;
    jobDateUpdated: string;
    retryCount: number;
    webhookId: number | null;
    codeSet: string;
    releaseId: number;
    releaseState: string;
    releaseUserName: string;
    releaseUserPrincipal: string;
    subscriptionId: number | null;
    application: string;
};

export type ScheduleDTO = {
    scheduleExecutionId: number;
    scheduleId: number;
    startDate: string;
    endDate: string | null;
    running: boolean;
    failed: boolean;
    action: string;
    description: string;
};

export type ScheduleDeltaTimeDTO = {
    scheduleId: number;
    action: string;
    description: string;
    elapsedSecondsAverage: number;
    elapsedSecondsLatest: number;
    delta: number;
};

export type ScheduleExecutionOutput = {
    output: string;
};

export type ReleaseDTO = {
    id: number;
    state: string;
    userName: string;
    userPrincipal: string;
    dateCreated: string;
    errorCount: number;
    specificationType: string;
    library: string;
    codeSetName: string;
    codeSetDescription: string;
};

export type LateSchedulesDTO = {
    id: number;
    action: string;
    description: string;
    scheduleSpec: string;
    latestDate: string;
    expectedDate: string;
};

export function adminPageJobs(self: HttpWrapper): Promise<JobIssueDTO[]> {
    return getResData(self, 'AdminPage/Jobs');
}
export function adminPageSchedules(self: HttpWrapper): Promise<ScheduleDTO[]> {
    return getResData(self, 'AdminPage/Schedules');
}
export function adminPageSchedulesSignificantDeltaTimeChange(self: HttpWrapper): Promise<ScheduleDeltaTimeDTO[]> {
    return getResData(self, 'AdminPage/ScedulesWithSignificantDeltaTimeChange');
}
export function adminPageScheduleOutput(self: HttpWrapper, id: number): Promise< ScheduleExecutionOutput> {
    return getResData(self, `AdminPage/Schedules/${id}`);
}
export function adminPageReleases(self: HttpWrapper): Promise<ReleaseDTO[]> {
    return getResData(self, 'AdminPage/Releases');
}
export function adminPageLateSchedules(self: HttpWrapper): Promise<LateSchedulesDTO> {
    return getResData(self, 'AdminPage/LateSchedules');
}
export function adminPageRestartJob(self: HttpWrapper, id: number): ResponsePromise<Job> {
    return postCommand(self, `AdminPage/RestartJob/${id}`);
}

// =============================================================================
// Subscription

export type DifferenceCode = Record<string, unknown>;

export type DifferenceResult = {
    hasDifference: boolean;
    releaseNeeded: boolean;
    hasError: boolean;
    expectedCodeCount: number;
    externalCodeCount: number;
    externalValidCodeCount: number;
    error: string;
    notInExternal: DifferenceCode[];
    notInInternal: DifferenceCode[];
    inBothButDifferent: { item1: DifferenceCode; item2: DifferenceCode }[];
    summary: string;
};

export function getSubscription(self: HttpWrapper, id: number): Promise<Subscription> {
    return getResData(self, `Application/Subscription/${id}`);
}
export function getSubscriptionStatus(self: HttpWrapper, id: number): Promise<SubscriptionStatus[]> {
    return getResData(self, `Application/Subscription/${id}/Status`);
}
export function getSubscriptionStatusById(self: HttpWrapper, id: number): Promise<{ status: SubscriptionStatus; data: DifferenceResult }> {
    return getResData(self, `Application/Subscription/Status/${id}`);
}
export function downloadSubscriptionReport(self: HttpWrapper, reportName: string, id: number): ResponsePromise<Blob> {
    return getFileCommand(self, `Excel/${reportName}/${id}`);
}

// =============================================================================
// Alias

export type AliasDirection = 'Both' | 'Out' | 'In';

export type Alias = {
    id: number;
    key: string;
    value: string;
    scope: string;
    direction: AliasDirection;
    mapFunction: string;
};

export type AliasScope = {
    name: string;
};

export function getAliases(self: HttpWrapper): Promise<Alias[]> {
    return getResData(self, 'Alias');
}
export function getAliasScopes(self: HttpWrapper): Promise<AliasScope[]> {
    return getResData(self, 'Alias/Scope');
}
export function saveNewAlias(self: HttpWrapper, command: Alias): ResponsePromise<void> {
    return postCommand(self, 'Alias', command);
}
export function saveNewAliasScope(self: HttpWrapper, command: AliasScope): ResponsePromise<void> {
    return postCommand(self, 'Alias/Scope', command);
}
export function updateAlias(self: HttpWrapper, command: Alias): ResponsePromise<void> {
    return postCommand(self, 'Alias/Update', command);
}
export function deleteAlias(self: HttpWrapper, alias: Alias): ResponsePromise<void> {
    return deleteCommand(self, 'Alias', alias);
}

// =============================================================================
// Schedules

export type ScheduleState =
    | 'Ok'
    | 'Failed'
    | 'Running';

export type Schedule = {
    id: number;
    scheduleSpec: string;
    action: string;
    icons: string;
    category: string;
    description: string;
    configuration: string;
    enabled: boolean;
    lastKnownState: ScheduleState;
    lastExecutionTime: string;
};

export type ScheduleExecution = {
    id: number;
    scheduleId: number;
    startDate: string;
    endDate: string;
    running: boolean;
    failed: boolean;
    output: string;
};

export type ScheduleUpdateModel = {
    action: string;
    icons: string;
    category: string;
    description: string;
    configuration: string;
    scheduleSpec: string;
    enabled: boolean;
};

export function getSchedules(self: HttpWrapper): Promise<Schedule[]> {
    return getResData(self, 'Schedule');
}
export function getScheduleExecutions(self: HttpWrapper, id: number): Promise<ScheduleExecution[]> {
    return getResData(self, `Schedule/${id}/executions`);
}
export function updateSchedule(self: HttpWrapper, schedule: ScheduleUpdateModel): ResponsePromise<Schedule> {
    return putCommand(self, 'Schedule', schedule);
}
export function addSchedule(self: HttpWrapper, schedule: ScheduleUpdateModel): ResponsePromise<Schedule> {
    return postCommand(self, 'Schedule', schedule);
}
export function deleteSchedule(self: HttpWrapper, id: number): ResponsePromise<void> {
    return deleteCommand(self, `Schedule/${id}`);
}
export function deleteScheduleExecution(self: HttpWrapper, id: number): ResponsePromise<void> {
    return deleteCommand(self, `Schedule/executions/${id}`);
}
export function runSchedule(self: HttpWrapper, schedule: ScheduleUpdateModel): ResponsePromise<void> {
    return postCommand(self, 'Schedule/run', schedule);
}
export function runScheduleById(self: HttpWrapper, id: number): ResponsePromise<void> {
    return postCommand(self, `Schedule/run/${id}`);
}

// =============================================================================
// Saved Queries

export type SaveRequest = {
    name: string;
    spec: string;
    isPublic: boolean;
};

export type SavedQuery = {
    id: number;
    name: string;
    library: string;
    parameters: string;
    spec: string;
    userId: string;
    isValid: boolean;
    isPublic: boolean;
    isReport: boolean;
    dateCreated: string | null;
    dateUpdated: string | null;
};

export function createSavedQuery(self: HttpWrapper, sq: SaveRequest): ResponsePromise<{ id: number }> {
    return postCommand(self, 'SavedQueries/Create', sq);
}
export function updateSavedQuery(self: HttpWrapper, id: number, sq: SaveRequest): ResponsePromise<void> {
    return putCommand(self, 'SavedQueries/' + id, sq);
}
export function deleteSavedQuery(self: HttpWrapper, id: number): ResponsePromise<void> {
    return deleteCommand(self, 'SavedQueries/' + id);
}
export function getSavedQueries(self: HttpWrapper): Promise<SavedQuery[]> {
    return getResData(self, 'SavedQueries');
}
export function getQueryReports(self: HttpWrapper): Promise<SavedQuery[]> {
    return getResData(self, 'SavedQueries/reports');
}
export function savedQuerySetReport(self: HttpWrapper, id: number, isReport: boolean): ResponsePromise<void> {
    return putCommand(self, `SavedQueries/${id}/set-report/${isReport}`);
}

// =============================================================================
// Search

enum SearchResultType {
    Unknown = 0,
    Url = 10,
    LibraryTag = 15,
    Library = 20,
    CodeSet = 25
}

export type SearchResultItem = {
    type: SearchResultType;
    key: string;
    summary: string;
    hits: number;
};

export function globalSearch(self: HttpWrapper, q: string): Promise<SearchResultItem[]> {
    return getResData(self, `Search?input=${encodeURIComponent(q)}`);
}

// =============================================================================
// Schema

/**
 * @deprecated Use getSchemaDTO to get schema with options
 */
export function getSchema(self: HttpWrapper, name: string): Promise<SchemaDTO> {
    return getResDataPub(self, `Schema/${name}`);
}
export function compileDoc(self: HttpWrapper, options: SchemaCompositionOptions): ResponsePromise<Blob> {
    return postCommandPub(self, 'Schema/compile/doc', options, { responseType: 'blob' });
}
export function getCompileCSharp(self: HttpWrapper, identity: string): ResponsePromise<Blob> {
    return getFileCommand(self, `Schema/compile/csharp/${identity}`);
}
export function getAllSchemas(self: HttpWrapper): Promise<SimpleSchema[]> {
    return getResDataPub(self, 'Schema');
}
export function getSchemaDTO(self: HttpWrapper, options: SchemaCompositionOptions): ResponsePromise<SchemaDTO> {
    return postCommandPub(self, 'Schema', options);
}
export function getSchemaMapSchema(self: HttpWrapper): Promise<Code[]> {
    return getResDataPub(self, 'Code/SchemaMapSchema');
}
export function mapMessage(self: HttpWrapper, form: FormData): ResponsePromise<Blob> {
    return postCommandPub(self, 'Schema/MapMessage', form);
}
export function mapMessageWithMultipleSchemas(self: HttpWrapper, form: FormData): ResponsePromise<Blob> {
    return postCommandPub(self, 'Schema/MapMessageWithMultipleSchemas', form);
}
export function getZippedSchemas(self: HttpWrapper, form: FormData): ResponsePromise<Blob> {
    return postCommandPub(self, 'Schema/GetZippedSchemas', form, { responseType: 'blob' });
}

// =============================================================================
// Admin commands

export type CommandResult = {
    success: boolean;
    output: string;
};

export function runAdminCommand(self: HttpWrapper, args: string[]): ResponsePromise<CommandResult> {
    return postCommand(self, 'AdminCommands/', args);
}

// =============================================================================
// RDF

export function rdfCodeByIRI(self: HttpWrapper, encodedPart: string): Promise<unknown> {
    return getResData(self, `Rdf/iri/code/${encodedPart}`);
}

// =============================================================================
// ENS

export type RuleViolationType = 'ERROR' | 'WARNING';

export type EnsFormatRuleViolation = {
    name: string;
    message: string;
    description: string;
    type: RuleViolationType;
};

export type EnsFormatValidationResult = {
    isValid: boolean;
    ruleViolations: EnsFormatRuleViolation[];
    formatName: string;
    syntax: string;
    regex: string;
    regexGrouped: string;
};

export type EnsFormatType = 'TagFormat' | 'DocumentNumberingFormat';

export type ParseMatchElement = {
    description: string;
    referenceType: string;
    value: string;
    valueDescription: string;
};

export type ParseMatch = {
    formatName: string;
    formatDescription: string;
    syntax: string;
    elements: ParseMatchElement[];
};

export type EnsParseResult = {
    formatType: EnsFormatType;
    facility: string;
    category: string;
    number: string;
    matches: ParseMatch[];
    matchFound: boolean;
};

export function ensValidateFormat(self: HttpWrapper, formatType: EnsFormatType, format: string, axiosOptions?: AxiosRequestConfig): ResponsePromise<EnsFormatValidationResult> {
    return postCommand(self, `Ens/Validate/${formatType}`, format, axiosOptions);
}
export function ensParse(self: HttpWrapper, formatType: EnsFormatType, category: string, facility: string, number: string): Promise<EnsParseResult> {
    return getResDataPub(self, `Ens/Parse/${formatType}` + requestParameters([
        { name: 'category', value: category },
        { name: 'facility', value: facility },
        { name: 'number', value: number }
    ]));
}

// =============================================================================
// Generic views

export type GenericViewQueryParam = NameValuePair;

export type GenericViewQueryParams = GenericViewQueryParam[];

export type GenericViewCompactResult = {
    columns: string[];
    rows: unknown[][];
};

export type InspectSqlResult = {
    sql: string;
};

export type DatabaseInformationSchemaColumn = Record<string, unknown>;

export type ViewDefinitionDTO = {
    library: string;
    columns: DatabaseInformationSchemaColumn[];
};

export type QueryParseInfo = {
    query: QuerySpecVersioned;
    text: string;
    sql: string;
};

function queryParameters(ps: GenericViewQueryParams | undefined) {
    if (ps && ps.length > 0) {
        return '?' + ps.map(x => `${x.name.substring(1)}=${encodeURIComponent(x.value)}`).join('&');
    }
    return '';
}

export function genericViewQuery<R>(self: HttpWrapper, q: QuerySpecVersioned): ResponsePromise<R[]> {
    return postCommandPub(self, 'GenericViews/query', q);
}
export function genericViewQueryCompact(self: HttpWrapper, q: QuerySpecVersioned): ResponsePromise<GenericViewCompactResult> {
    return postCommandPub(self, 'GenericViews/query/compact', q);
}
export function genericViewQueryInspectSql(self: HttpWrapper, q: QuerySpecVersioned): ResponsePromise<InspectSqlResult> {
    return postCommandPub(self, 'GenericViews/query/sql', q);
}
export function genericViewQueryToExcel(self: HttpWrapper, q: QuerySpecVersioned): ResponsePromise<Blob> {
    return postCommandPub(self, 'GenericViews/query/excel', q, { responseType: 'blob' });
}
export function genericViewQueryAsTextToExcel(self: HttpWrapper, q: string, p?: GenericViewQueryParams): ResponsePromise<Blob> {
    return postCommandPub(self, 'GenericViews/query/text/excel' + queryParameters(p), q, { responseType: 'blob' });
}
export function genericViewQueryAsText<R>(self: HttpWrapper, q: string, p?: GenericViewQueryParams): ResponsePromise<R[]> {
    return postCommandPub(self, 'GenericViews/query/text' + queryParameters(p), q);
}
export function genericViewParseText(self: HttpWrapper, q: string): ResponsePromise<QueryParseInfo> {
    return postCommandPub(self, 'GenericViews/query/text/parse', q);
}
export function genericViewSavedQuery<R>(self: HttpWrapper, id: number, p?: GenericViewQueryParams): ResponsePromise<R[]> {
    return postCommandPub(self, `GenericViews/query/saved/${id}` + queryParameters(p));
}
export function genericViewSavedQueryToExcel(self: HttpWrapper, id: number, p?: GenericViewQueryParams): ResponsePromise<Blob> {
    return postCommandPub(self, `GenericViews/query/saved/${id}/excel` + queryParameters(p), null, { responseType: 'blob' });
}
export function genericViewDefinition(self: HttpWrapper, libraryName: string): Promise<ViewDefinitionDTO> {
    return getResDataPub(self, `GenericViews/library/${encodeURIComponent(libraryName)}/definition`);
}

// =============================================================================
// SQL query

export function sqlQuery(self: HttpWrapper, q: string): ResponsePromise<Record<string, unknown>[]> {
    return postCommandPub(self, 'sql', q);
}
export function sqlQueryToExcel(self: HttpWrapper, q: string): ResponsePromise<Blob> {
    return postCommandPub(self, 'sql/excel', q, { responseType: 'blob' });
}

// =============================================================================
// Metadata

export type MetadataRequest = {
    request: string;
    values: string[];
};

export type MetadataResponse = {
    'access-groups-for-library': Record<string, AccessGroup[]>;
    'code-sets': Record<string, CodeSet[]>;
    'attribute-definitions': Record<string, Library>;
};

export function getMetadata(self: HttpWrapper, array: MetadataRequest[]): ResponsePromise<MetadataResponse> {
    return postCommand(self, 'Metadata', array);
}

// =============================================================================
// Image

export function uploadImage(self: HttpWrapper, form: FormData): ResponsePromise<{ url: string }> {
    return postCommand(self, 'Image', form);
}

// =============================================================================
// Attachment

export type SearchAttachmentsParameters = {
    libraryName: string;
    mimeType: string;
    fileNameLike: string;
};

export type SearchAttachmentResult = {
    libraryName: string;
    codeSetDescription: string;
    codeSetName: string;
    codeId?: number;
    codeName: string;
    attachmentKey: string;
    mimeType: string;
    fileName: string;
    sizeInBytes: number;
    attachmentUpdatedDate: string;
    storageLocation: string;
    libraryGroup: string;
};

export function getAttachment(self: HttpWrapper, key: string): Promise<Blob> {
    return getResDataPub(self, `Attachment/File/${key}`, { responseType: 'blob' });
}
export function getAttachmentName(self: HttpWrapper, key: string): Promise<string> {
    return getResDataPub(self, `Attachment/FileName/${key}`);
}
export function getMimeTypes(self: HttpWrapper): Promise<string[]> {
    return getResData(self, 'Attachment/MimeTypes');
}
export function uploadAttachmentToCode(self: HttpWrapper, codeId: number, form: FormData): ResponsePromise<{ attachmentKey: string }> {
    return postCommand(self, `Attachment/SaveToCode/${codeId}`, form);
}
export function uploadAttachmentToLibrary(self: HttpWrapper, libraryName: string, form: FormData): ResponsePromise<{ attachmentKey: string }> {
    return postCommand(self, `Attachment/SaveToLibrary/${libraryName}`, form);
}
export function uploadAttachmentToTag(self: HttpWrapper, tagName: string, form: FormData): ResponsePromise<{ attachmentKey: string }> {
    return postCommand(self, `Attachment/SaveToTag/${tagName}`, form);
}
export function searchAttachments(self: HttpWrapper, params: SearchAttachmentsParameters): ResponsePromise<SearchAttachmentResult> {
    return postCommand(self, 'Attachment/Search', params);
}

export function getLibraryNames(self: HttpWrapper): Promise<string[]> {
    return getResDataPub(self, 'Library/NameList');
}
export function getCodesPublicApi(self: HttpWrapper, name: string, p: NameValuePair[]): Promise<Code[]> {
    return getResDataPub(self, `Code/${name + requestParameters(p)}`);
}

// =============================================================================
// Excel

export type CodeFilter = {
    regEx: string[];
    queries: string[][];
    columns: string[];
};

export function excelImportCodeSet(self: HttpWrapper, form: FormData): ResponsePromise<{ releaseId: number }> {
    return postCommand(self, 'Excel/ImportCodeSet', form);
}
export function excelExportCodeSet(self: HttpWrapper, codeSetName: string, codeFilter: CodeFilter): ResponsePromise<Blob> {
    return postCommand(self, `Excel/ExportCodeSet?name=${codeSetName}`, codeFilter, { responseType: 'blob' });
}

// =============================================================================
// Access Policy

export type LibraryPolicy = {
    id: number;
    libraryName: string;
    facilityRole: string;
    sourceSystem: string;
};

export type LibraryPolicyDto = {
    id: number | null;
    libraryName: string;
    facilityRole: string;
    sourceSystem: string;
};

export type Policy = {
    id: number;
    sourceSystem: string;
    facilityRole: string;
    scope?: string;
    commonLibrary?: boolean;
    dateTimeExpire: string;
};

export type PolicyDto = {
    id: number;
    libraryName: string;
    policies: Policy[];
};

export type UserAttributesDto = {
    id: number;
    email: string;
    fullName?: string;
    policies: Policy[];
};

export type AttributeDto = {
    id: number | null;
    email: string | null;
    facilityRole: string;
    sourceSystem: string;
    scope: string;
    commonLibrary: boolean;
};

export type SourceSystemsAndRolesDto = {
    sourceSystems: string[];
    facilityRoles: string[];
};

export type UserAccessAttribute = {
    id: number;
    oid: string;
    email: string;
    facility: string;
    sourceSystem: string;
    facilityRole: string;
    dateTimeChecked: string;
    dateTimeExpire: string;
    commonLibrary: boolean;
};

/**
 * Fetches all the users attributes
 */
export function getMyUserAccessAttributes(self: HttpWrapper): Promise<string[]> {
    return getCommandEnhanced(self, 'Me/attributes');
}
export function getAllAccessPolicies(self: HttpWrapper): Promise<PolicyDto[]> {
    return getCommandEnhanced(self, 'AccessPolicy');
}
export function getAllSystemsAndRoles(self: HttpWrapper): Promise<SourceSystemsAndRolesDto | []> {
    return getCommandEnhanced(self, 'AccessPolicy/SourceSystemsAndRoles');
}
export function getAllUserAttributes(self: HttpWrapper): Promise<UserAttributesDto[]> {
    return getCommandEnhanced(self, 'AccessPolicy/UserAttributes');
}
export function getAccessPolicesForLib(self: HttpWrapper, libraryName: string): Promise<PolicyDto | []> {
    return getCommandEnhanced(self, `AccessPolicy/${libraryName}`);
}
export function saveLibraryPolicy(self: HttpWrapper, libraryName: string, policy: LibraryPolicyDto): ResponsePromise<LibraryPolicy> {
    return postCommandEnhanced(self, `AccessPolicy/${libraryName}`, policy);
}
export function saveUserAttribute(self: HttpWrapper, email: string, attribute: AttributeDto): ResponsePromise<UserAccessAttribute> {
    return postCommandEnhanced(self, `AccessPolicy/UserAttributes/${email}`, attribute);
}
export function deleteLibraryPolicy(self: HttpWrapper, libraryName: string, policy: LibraryPolicyDto): ResponsePromise<void> {
    return deleteCommand(self, `AccessPolicy/${libraryName}`, policy);
}
export function expireUserAttribute(self: HttpWrapper, email: string, attribute: AttributeDto): ResponsePromise<void> {
    return putCommand(self, `AccessPolicy/UserAttributes/${email}`, attribute);
}

// =============================================================================
// Internal Application

export type CreateApplicationModel = {
    name: string;
};

export type CreateSubscriptionModel = {
    libraryName: string;
    applicationName: string;
};

export function getInternalApplication(self: HttpWrapper, appName: string): Promise<Application> {
    return getResData(self, 'Application/' + encodeURIComponent(appName));
}
export function getInternalApplicationSubscriptions(self: HttpWrapper, appName: string): Promise<Subscription[]> {
    return getResData(self, 'Application/Subscription?appName=' + encodeURIComponent(appName));
}
export function getAllInternalApplication(self: HttpWrapper): Promise<Application[]> {
    return getResData(self, 'Application/All');
}
export function saveNewInternalApplication(self: HttpWrapper, command: CreateApplicationModel): ResponsePromise<void> {
    return postCommand(self, 'Application/Add', command);
}

export function saveNewInternalApplicationSubscription(self: HttpWrapper, command: CreateSubscriptionModel): ResponsePromise<void> {
    return postCommand(self, 'Application/Subscription', command);
}
export function disableSubscriptionForApplication(self: HttpWrapper, subscriptionId: number): ResponsePromise<void> {
    return postCommand(self, `Application/Subscription/Disable/${subscriptionId}`);
}
export function enableSubscriptionForApplication(self: HttpWrapper, subscriptionId: number): ResponsePromise<void> {
    return postCommand(self, `Application/Subscription/Enable/${subscriptionId}`);
}

// =============================================================================
// System Information

export type ServiceInfo = {
    environment: string;
    machineName: string;
    os: string;
    settings: { path: string; key: string; value: string }[];
    resourceGroup: string;
    apiVersion: string;
};

export function getSystemInfo(self: HttpWrapper): Promise<ServiceInfo> {
    return getResData(self, 'ServiceInfo');
}
