<template>
    <div>
        <b-modal
            :model-value="runsModalActive"
            has-modal-card
            @close="closeRuns">
            <div
                class="modal-card"
                style="height: auto; width: auto;">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        Select run..
                    </p>
                </header>
                <section class="modal-card-body">
                    <div
                        style="height: auto; width: auto;">
                        <b-table
                            :data="scheduleRuns"
                            default-sort="startDate"
                            default-sort-direction="desc"
                            class="cursorPointer"
                            :striped="true"
                            :narrowed="true"
                            :hoverable="true"
                            @click="selectRun">
                            <b-table-column
                                v-slot="props"
                                sortable
                                field="id"
                                label="ID">
                                {{ props.row.id }}
                            </b-table-column>
                            <b-table-column
                                v-slot="props"
                                field="action"
                                label="State">
                                <i
                                    v-if="props.row.failed"
                                    class="fa fa-times-circle"
                                    style="color:red;" />
                                <i
                                    v-else-if="props.row.running"
                                    class="fa fa-spinner"
                                    style="color:blue;" />
                            </b-table-column>
                            <b-table-column
                                v-slot="props"
                                sortable
                                field="startDate"
                                label="Start">
                                {{ $filters.dateFormatLong(props.row.startDate) }}
                            </b-table-column>
                            <b-table-column
                                v-slot="props"
                                sortable
                                field="endDate"
                                label="End">
                                {{ $filters.dateFormatLong(props.row.endDate) }}
                            </b-table-column>
                        </b-table>
                    </div>
                </section>
            </div>
        </b-modal>

        <div class="columns">
            <div class="column is-2">
                <h1 class="title">
                    <b-icon
                        icon="cog"
                        size="" />
                    Monitoring
                </h1>
            </div>
            <div class="column">
                <div
                    class="field is-grouped is-pulled-right"
                    style="margin-top:10px;">
                    <div class="control buttons has-addons">
                        <button
                            class="button"
                            @click="refreshAll">
                            <b-icon
                                icon="refresh"
                                size="is-small" />
                            <span>Refresh</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="block">
            <h4 class="title is-4">
                Schedules (running/failed)
            </h4>

            <div v-if="showOutput">
                <pre style="padding: 6px;background-color: black;color: lightgreen"><button
                    class="delete is-pulled-right"
                @click="showOutput = false" />{{ outputData.output }}</pre>
            </div>

            <b-table
                :data="schedules"
                :striped="true"
                :narrowed="true"
                :loading="schedulesLoading"
                :hoverable="true">
                <b-table-column
                    v-slot="props"
                    field="description"
                    label="Schedule">
                    {{ props.row.description }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="action"
                    label="Action">
                    {{ props.row.action }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="action"
                    label="State">
                    <i
                        v-if="props.row.failed"
                        class="fa fa-times-circle"
                        style="color:red;" />
                    <i
                        v-else-if="props.row.running"
                        class="fa fa-spinner"
                        style="color:blue;" />
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="startDate"
                    label="Start">
                    {{ $filters.dateFormatLong(props.row.startDate) }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="endDate"
                    label="End">
                    {{ $filters.dateFormatLong(props.row.endDate) }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    numeric
                    field="id"
                    label="Inspect">
                    <button
                        class="button is-primary is-small"
                        @click="runSchedule(props.row.scheduleId)">
                        <span class="icon is-small">
                            <i
                                class="fa fa-rocket"
                                aria-hidden="true" />
                        </span>
                        <span>Run NOW!</span>
                    </button>
                    <button
                        class="button is-info is-small"
                        @click="openRuns(props.row.scheduleId)">
                        <b-icon
                            icon="format-list-numbered"
                            size="is-small" />
                        <span>Runs</span>
                    </button>
                </b-table-column>
                <template #empty>
                    <section class="section">
                        <div class="content has-text-grey has-text-centered">
                            <p>No schedules of interest!</p>
                        </div>
                    </section>
                </template>
            </b-table>
        </div>
        <div class="block">
            <h4 class="title is-4">
                Schedules with significant change in running duration
            </h4>

            <b-table
                :data="schedulesDelta"
                :striped="true"
                :narrowed="true"
                :loading="schedulesDeltaLoading"
                :hoverable="true">
                <b-table-column
                    v-slot="props"
                    field="description"
                    label="Schedule">
                    {{ props.row.description }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="action"
                    label="Action">
                    {{ props.row.action }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="elapsedSecondsAverage"
                    label="Average duration">
                    {{ humanizeSeconds(props.row.elapsedSecondsAverage) }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="elapsedSecondsLatest"
                    label="Latest duration">
                    {{ humanizeSeconds(props.row.elapsedSecondsLatest) }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="delta"
                    label="Delta">
                    {{ props.row.delta }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    numeric
                    field="id"
                    label="Inspect">
                    <button
                        class="button is-primary is-small"
                        @click="runSchedule(props.row.scheduleId)">
                        <span class="icon is-small">
                            <i
                                class="fa fa-rocket"
                                aria-hidden="true" />
                        </span>
                        <span>Run NOW!</span>
                    </button>
                    <button
                        class="button is-info is-small"
                        @click="openRuns(props.row.scheduleId)">
                        <b-icon
                            icon="format-list-numbered"
                            size="is-small" />
                        <span>Runs</span>
                    </button>
                </b-table-column>
                <template #empty>
                    <section class="section">
                        <div class="content has-text-grey has-text-centered">
                            <p>No schedules with significant delta change :)</p>
                        </div>
                    </section>
                </template>
            </b-table>
        </div>
        <div class="block">
            <h4 class="title is-4">
                Schedules possibly running late
            </h4>

            <b-table
                :data="lateSchedules"
                :striped="true"
                :narrowed="true"
                :loading="lateSchedulesLoading"
                :hoverable="true">
                <b-table-column
                    v-slot="props"
                    field="description"
                    label="Schedule">
                    {{ props.row.description }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="action"
                    label="Action">
                    {{ props.row.action }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="scheduleSpec"
                    label="Cron">
                    {{ props.row.scheduleSpec }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="latestDate"
                    label="Last run">
                    {{ $filters.dateFormatLong(props.row.latestDate) }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="expectedDate"
                    label="Expected date">
                    {{ $filters.dateFormatLong(props.row.expectedDate) }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    numeric
                    field="id"
                    label="Inspect">
                    <button
                        class="button is-primary is-small"
                        @click="runSchedule(props.row.id)">
                        <span class="icon is-small">
                            <i
                                class="fa fa-rocket"
                                aria-hidden="true" />
                        </span>
                        <span>Run NOW!</span>
                    </button>
                    <button
                        class="button is-info is-small"
                        @click="openRuns(props.row.id)">
                        <b-icon
                            icon="format-list-numbered"
                            size="is-small" />
                        <span>Runs</span>
                    </button>
                </b-table-column>
                <template #empty>
                    <section class="section">
                        <div class="content has-text-grey has-text-centered">
                            <p>No schedules running late :)</p>
                        </div>
                    </section>
                </template>
            </b-table>
        </div>
        <div class="block">
            <h4 class="title is-4">
                Jobs (running/failed)
            </h4>
            <b-table
                :data="jobs"
                :striped="true"
                :narrowed="true"
                :loading="jobsLoading"
                :hoverable="true">
                <b-table-column
                    v-slot="props"
                    field="jobDateUpdated"
                    label="Timestamp">
                    {{ $filters.dateFormatLong(props.row.jobDateUpdated) }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="jobType"
                    label="Type">
                    {{ props.row.jobType }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="jobState"
                    label="State">
                    {{ props.row.jobState }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="retryCount"
                    label="Retries">
                    {{ props.row.retryCount }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="releaseState"
                    label="Release state">
                    <router-link
                        :to="getReleaseLink(props.row.releaseId)">
                        <b-icon
                            icon="link-variant"
                            size="is-small" />
                        {{ props.row.releaseState }}
                    </router-link>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="releaseUserName"
                    label="Released by">
                    {{ props.row.releaseUserName }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="releaseUserPrincipal"
                    label="User Principal">
                    {{ props.row.releaseUserPrincipal }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="codeSet"
                    label="CodeSet">
                    <a
                        v-if="props.row.codeSet"
                        :href="getCodeSetLink(props.row.codeSet)">
                        <b-icon
                            icon="link-variant"
                            size="is-small" />
                        {{ props.row.codeSet }}
                    </a>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="application"
                    label="Subscription">
                    <router-link
                        v-if="props.row.application"
                        :to="getSubscriptionLink(props.row.application, props.row.subscriptionId)">
                        <b-icon
                            icon="link-variant"
                            size="is-small" />
                        {{ props.row.application }}, {{ props.row.subscriptionId }}
                    </router-link>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    numeric
                    field="id"
                    label="Rerun">
                    <button
                        class="button is-primary is-small"
                        @click="rerunJob(props.row.jobId)">
                        <span class="icon is-small">
                            <i
                                class="fa fa-rocket"
                                aria-hidden="true" />
                        </span>
                    </button>
                </b-table-column>
                <template #empty>
                    <section class="section">
                        <div class="content has-text-grey has-text-centered">
                            <p>No jobs are having issues...</p>
                        </div>
                    </section>
                </template>
            </b-table>
        </div>
        <div class="block">
            <h4 class="title is-4">
                Releases (not yet completed)
            </h4>
            <b-table
                :data="releases"
                :striped="true"
                :narrowed="true"
                :loading="jobsLoading"
                :hoverable="true">
                <b-table-column
                    v-slot="props"
                    field="dateCreated"
                    label="Created">
                    {{ $filters.dateFormatLong(props.row.dateCreated) }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="state"
                    label="State">
                    <router-link
                        :to="getReleaseLink(props.row.id)">
                        <b-icon
                            icon="link-variant"
                            size="is-small" />
                        {{ props.row.state }}
                    </router-link>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="specificationType"
                    label="Type">
                    {{ props.row.specificationType }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="errorCount"
                    label="Errors">
                    {{ props.row.errorCount }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="library"
                    label="Library">
                    <router-link
                        v-if="props.row.library"
                        :to="getLibraryLink(props.row.library)">
                        <b-icon
                            icon="link-variant"
                            size="is-small" />
                        {{ props.row.library }}
                    </router-link>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="codeSetName"
                    label="CodeSet">
                    <a
                        v-if="props.row.codeSetName"
                        :href="getCodeSetLink(props.row.codeSetName)">
                        <b-icon
                            icon="link-variant"
                            size="is-small" />
                        {{ props.row.codeSetDescription }}
                    </a>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="userName"
                    label="Released by">
                    {{ props.row.userName }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="userPrincipal"
                    label="User Principal">
                    {{ props.row.userPrincipal }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    label="Action"
                    :visible="hasReleaseWithPublishingState">
                    <b-button
                        v-if="props.row.state === 'Publishing'"
                        :loading="props.row.isAborting"
                        @click="abortPublishing(props.row)">
                        Abort publishing
                    </b-button>
                </b-table-column>
                <b-table-column
                    v-slot="props">
                    {{ props.row.abortPublishingError }}
                </b-table-column>
                <template #empty>
                    <section class="section">
                        <div class="content has-text-grey has-text-centered">
                            <p>All releases have been completed :D</p>
                        </div>
                    </section>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
    import {
        abortPublishingRelease,
        adminPageJobs,
        adminPageLateSchedules,
        adminPageReleases,
        adminPageRestartJob,
        adminPageScheduleOutput,
        adminPageSchedules,
        adminPageSchedulesSignificantDeltaTimeChange,
        getScheduleExecutions,
        runScheduleById
    } from '@/shared/helpers/api';
    import { getCodeSetLink, getLibraryLink, getReleaseLink, getSubscriptionLink } from '@/shared/helpers/routing';
    import { showMixin } from '@/shared/mixins/showMixin';
    import moment from 'moment';

    export default {
        mixins: [
            showMixin
        ],
        data: function() {
            return {
                jobs: [],
                schedules: [],
                schedulesDelta: [],
                releases: [],
                lateSchedules: [],
                scheduleRuns: [],
                jobsLoading: false,
                schedulesLoading: false,
                schedulesDeltaLoading: false,
                releasesLoading: false,
                lateSchedulesLoading: false,
                outputData: { output: '' },
                showOutput: false
            };
        },
        computed: {
            runsModalActive() {
                return this.scheduleRuns.length > 0;
            },
            hasReleaseWithPublishingState() {
                return this.releases.some(release => release.state === 'Publishing');
            }
        },
        mounted: function() {
            this.refreshAll();
        },
        methods: {
            abortPublishing: async function(release) {
                release.isAborting = true;
                release.abortPublishingError = '';

                try {
                    await abortPublishingRelease(this, release.id);
                    this.showInfo('Publishing aborted.');
                    await this.loadJobs();
                } catch (ex) {
                    this.showError(ex, errorMessage => {
                        release.abortPublishingError = errorMessage;
                    });
                }

                release.isAborting = false;
            },
            refreshAll: function() {
                this.loadJobs();
                this.loadSchedules();
                this.loadSchedulesSignificantDeltaTimeChange();
                this.loadReleases();
                this.loadLateSchedules();
            },
            loadJobs: async function() {
                this.jobsLoading = true;
                this.jobs = await adminPageJobs(this);
                this.jobsLoading = false;
            },
            loadSchedules: async function() {
                this.schedulesLoading = true;
                this.schedules = await adminPageSchedules(this);
                this.schedulesLoading = false;
            },
            loadSchedulesSignificantDeltaTimeChange: async function() {
                this.schedulesDeltaLoading = true;
                this.schedulesDelta = await adminPageSchedulesSignificantDeltaTimeChange(this);
                this.schedulesDeltaLoading = false;
            },
            loadReleases: async function() {
                this.releasesLoading = true;
                this.releases = await adminPageReleases(this);
                this.releasesLoading = false;
            },
            loadLateSchedules: async function() {
                this.lateSchedulesLoading = true;
                this.lateSchedules = await adminPageLateSchedules(this);
                this.lateSchedulesLoading = false;
            },
            openOutput: async function(id) {
                this.outputData.output = '';
                this.outputData = await adminPageScheduleOutput(this, id);
                this.showOutput = true;
            },
            openRuns: async function(scheduleId) {
                const tmp = await getScheduleExecutions(this, scheduleId);
                this.scheduleRuns = tmp;
            },
            closeRuns: function() {
                this.scheduleRuns = [];
            },
            selectRun: async function(run) {
                await this.openOutput(run.id);
                this.closeRuns();
            },
            runSchedule: async function(id) {
                await runScheduleById(this, id);
                this.showInfo('Schedule started');
            },
            rerunJob: async function(id) {
                await adminPageRestartJob(this, id);
                this.showInfo('Job re-inserted');
            },
            humanizeSeconds: function(s) {
                return `${moment.duration(s, 'seconds').humanize()} (${s} seconds)`;
            },
            getCodeSetLink,
            getReleaseLink,
            getLibraryLink,
            getSubscriptionLink
        }
    };
</script>

<style scoped>
.cursorPointer {
    cursor: pointer;
}
</style>
