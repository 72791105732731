<template>
    <div>
        <b-loading
            v-if="refCodesLoading"
            v-model="refCodesLoading" />
        <div
            v-else
            class="table-container">
            <b-table
                :data="referringCodes"
                default-sort="codeName"
                :striped="true"
                :narrowed="true"
                :hoverable="true">
                <b-table-column
                    v-slot="props"
                    field="codeName"
                    label="Item"
                    sortable>
                    <a :href="getCodeLinkByB64Id(props.row.codeId)">
                        <b-icon
                            icon="link-variant"
                            size="is-small" />
                        {{ props.row.codeName }}
                    </a>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="codeDescription"
                    label="Description"
                    sortable>
                    {{ $filters.truncate(props.row.codeDescription, 50) }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="codeSetName"
                    label="Library set"
                    sortable>
                    <a :href="getCodeSetLink(props.row.codeSetName)">
                        <b-icon
                            icon="link-variant"
                            size="is-small" />
                        {{ props.row.codeSetDescription }}
                    </a>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="libraryName"
                    label="Library"
                    sortable>
                    <router-link :to="getLibraryLink(props.row.libraryName)">
                        <b-icon
                            icon="link-variant"
                            size="is-small" />
                        {{ props.row.libraryName }}
                    </router-link>
                </b-table-column>
                <template #empty>
                    <section class="section">
                        <div class="content has-text-grey has-text-centered title is-6">
                            <p>No Referring Codes Found</p>
                        </div>
                    </section>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
    import { getReferringCodesForInvalidCodes } from '@/shared/helpers/api.ts';
    import { getCodeLinkByB64Id, getCodeSetLink, getLibraryLink } from '@/shared/helpers/routing.js';
    import { encodeIdBase64 } from '@/shared/helpers/utils.js';
    import { showMixin } from '@/shared/mixins/showMixin.js';

    export default {
        mixins: [
            showMixin
        ],
        props: {
            codeSetName: {
                type: String,
                required: true
            }
        },
        data: function() {
            return {
                refCodesLoading: true,
                referringCodes: null
            };
        },
        mounted: async function() {
            this.loadReferringCodes();
        },
        methods: {
            loadReferringCodes: async function() {
                this.referringCodes = await getReferringCodesForInvalidCodes(this, this.codeSetName);
                this.referringCodes.forEach(refCode => refCode.codeId = encodeIdBase64('Code', refCode.codeId));
                this.refCodesLoading = false;
            },
            getLibraryLink,
            getCodeSetLink,
            getCodeLinkByB64Id
        }
    };
</script>

<style scoped>
.table-container {
    max-height: 500px;
    overflow-y: auto;
}
</style>
