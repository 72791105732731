<template>
    <div
        class="field is-grouped mt-3">
        <div class="control buttons has-addons">
            <button
                :class="runButtonClass"
                title="Run query"
                @click="emitClick('click-run')">
                <b-icon
                    icon="play"
                    size="is-small" />
                <span>Run</span>
            </button>
            <button
                class="button is-info"
                @click="emitClick('click-excel-export')">
                <b-icon
                    icon="download"
                    size="is-small" />
                <span>Excel export</span>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SqlButtons',
        props: {
            executing: {
                type: Boolean,
                default: false
            }
        },
        emits: [
            'click-run',
            'click-excel-export'
        ],
        computed: {
            runButtonClass() {
                return {
                    button: true,
                    'is-primary': true,
                    'is-loading': this.executing
                };
            }
        },
        methods: {
            emitClick(name) {
                this.$emit(name);
            }
        }
    };
</script>

<style scoped>

</style>
