<template>
    <div>
        <div class="columns">
            <div class="column">
                <h2 class="title is-3">
                    Release log
                </h2>
                <div class="field is-grouped is-grouped-multiline">
                    <div
                        v-for="item in filterList"
                        class="control">
                        <div class="tags has-addons">
                            <span class="tag is-dark">{{ $filters.filterToName(item[0]) }}</span>
                            <span class="tag is-info">{{ item[1] }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <spinner
            v-if="loading"
            :loading="loading" />
        <b-table
            :data="log"
            :striped="true"
            :narrowed="true"
            :hoverable="true"
            detailed
            :has-detailed-visible="hasDetails">
            <b-table-column
                v-slot="props"
                field="logLevel"
                label="Level"
                sortable>
                <span :class="['tag', logLevelClasses[props.row.logLevel]]">
                    {{ props.row.logLevel }}
                </span>
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="timestamp"
                label="Timestamp"
                sortable>
                {{ $filters.dateFormatLong(props.row.timestamp) }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="libraryName"
                label="Library"
                sortable>
                <router-link
                    v-if="props.row.libraryName"
                    :to="getLibraryLink(props.row.libraryName)">
                    <b-icon
                        icon="link-variant"
                        size="is-small" />
                    {{ props.row.libraryName }}
                </router-link>
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="codeSetDescription"
                label="Set"
                sortable>
                <a
                    v-if="props.row.codeSetName"
                    :href="getCodeSetLink(props.row.codeSetName)">
                    <b-icon
                        icon="link-variant"
                        size="is-small" />
                    {{ props.row.codeSetDescription }}
                </a>
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="releaseId"
                label="Release Id"
                sortable>
                <router-link :to="getReleaseLink(props.row.releaseId)">
                    <b-icon
                        icon="link-variant"
                        size="is-small" />
                    {{ props.row.releaseId }}
                </router-link>
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="publishJobId"
                label="Job Id"
                sortable>
                {{ props.row.publishJobId }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="message"
                label="Message"
                sortable>
                {{ props.row.message }}
            </b-table-column>
            <template #detail="props">
                <pre>{{ props.row.data }}</pre>
            </template>
        </b-table>
    </div>
</template>
<script>
    import Spinner from '@/shared/components/Spinner.vue';
    import { getLogs } from '@/shared/helpers/api';
    import { getCodeSetLink, getLibraryLink, getReleaseLink } from '@/shared/helpers/routing';
    import loadash from 'lodash';

    export default {
        components: {
            Spinner
        },
        filters: {
            filterToName: function(inValue) {
                switch (inValue) {
                    case 'libraryName': return 'Library';
                    case 'codeSetName': return 'Library set';
                    case 'releaseId': return 'Release';
                    case 'publishJobId': return 'Publish job';
                    case 'applicationName': return 'Application';
                    default: return inValue;
                }
            }
        },
        data: () => {
            return {
                log: [],
                loading: true,
                filterList: []
            };
        },
        computed: {
            logLevelClasses() {
                return { 'Error': 'is-danger', 'Info': 'is-info', 'Warning': 'is-warning' };
            }
        },
        mounted: function() {
            this.load();
        },
        methods: {
            load: async function() {
                const f = this.filter();

                this.filterList = loadash.toPairs(f.params);
                this.log = await getLogs(this, f);
                this.loading = false;
            },
            filter: function() {
                // TODO: window.location.search is empty
                const queryParams = new URLSearchParams(window.location.search);
                const filter = { params: {} };

                loadash.each(['libraryName', 'codeSetName', 'releaseId', 'publishJobId', 'applicationName'], function(elm) {
                    const tmp = queryParams.get(elm);
                    if (tmp)
                        filter.params[elm] = tmp;
                });
                return filter;
            },
            hasDetails: function(row) {
                return row.data;
            },
            getCodeSetLink,
            getReleaseLink,
            getLibraryLink
        }
    };
</script>
