<template>
    <footer class="footer">
        <div class="content has-text-centered">
            <p>
                <router-link :to="{ name: 'ChangeLog' }">
                    Changelog
                </router-link>
                | <a
                    :href="technicalDocUrl"
                    target="_blank">Technical documentation</a>
                | <a
                    :href="reportErrorUrl"
                    target="_blank">Report an error</a>
            </p>
            <p>Common Library &copy; Equinor ASA 2019 - {{ year }}</p>
        </div>
    </footer>
</template>
<script>
    export default {
        data() {
            return {
                technicalDocUrl: window.config.technicalDocUrl,
                reportErrorUrl: window.config.reportErrorUrl
            };
        },
        computed: {
            year() {
                return new Date().getUTCFullYear();
            }
        }
    };
</script>
<style scoped>
.footer {
    margin-top: auto;
}
</style>
