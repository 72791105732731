<template>
    <b-field>
        <p class="control">
            <scope-filter-dropdown
                v-if="!scopeStore.scopesLoading"
                :button-style="['is-text']"
                :button-text="`Manage ${actualScopeType} filter`"
                :library="{ scopeType: scopeType }" />
        </p>
        <p class="control">
            <custom-select
                v-model="scope"
                :options="selectOptions"
                :loading="scopeStore.scopesLoading"
                :disbled="scopeStore.scopesLoading"
                :placeholder="dropdownLabel"
                :label="scope"
                width="400px" />
        </p>
    </b-field>
</template>

<script>
    import { useScopeStore } from '@/stores/scopeStore.js';
    import { mapStores } from 'pinia';
    import CustomSelect from './CustomSelect.vue';
    import ScopeFilterDropdown from './ScopeFilterDropdown.vue';

    export default {
        components: {
            CustomSelect,
            ScopeFilterDropdown
        },
        props: {
            scopeType: {
                type: String,
                default: null
            }
        },
        emits: [
            'update:selectedScope'
        ],
        computed: {
            ...mapStores(useScopeStore),
            dropdownLabel() {
                return this.scope !== null ? this.scope : this.scopeStore.selectedScopeLabel;
            },
            actualScopeType() {
                return this.scopeType || this.scopeStore.scopeType;
            },
            scope: {
                get() {
                    return this.scopeStore.selectedScope;
                },
                set(newScope) {
                    this.scopeStore.updateSelectedScope(newScope);
                }
            },
            selectOptions() {
                return this.scopeStore.getScopes.map(scp => ({
                    key: scp.identity,
                    value: scp.identity,
                    string: `${scp.identity} - ${scp.description}`
                }));
            }
        },
        watch: {
            scope(newScope) {
                this.$emit('update:selectedScope', newScope);
            }
        },
        async mounted() {
            if (this.scopeType !== null) {
                this.scopeStore.setScopeType(this.scopeType);
            }
            await this.scopeStore.init();
        }
    };
</script>
