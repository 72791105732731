<template>
    <spinner
        :loading="loading"
        size="small"
        :fullscreen="false">
        <b-table
            :striped="false"
            :hoverable="false"
            :data="attributeDefinitions"
            class="site-sticky-header table-height">
            <b-table-column
                v-slot="props"
                field="sequenceNumber"
                label="Seq#">
                {{ props.row.sequenceNumber }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="name"
                label="Name">
                {{ props.row.name }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="displayAs"
                label="Display Name">
                {{ props.row.displayAs }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="description"
                label="Description">
                <span :title="props.row.description">{{ props.row.description }}</span>
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="attributeType"
                label="Type">
                <router-link
                    v-if="isReferenceType(props.row.attributeType)"
                    :to="getLibraryLink(props.row.referenceLibraryName)">
                    <b-icon
                        icon="link-variant"
                        size="is-small" />
                    {{ props.row.referenceLibraryName }}
                </router-link>
                <span v-else>{{ props.row.attributeType }}</span>
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="referenceDisplayMode"
                label="Item display">
                <span
                    v-if="isReferenceType(props.row.attributeType)">
                    {{ referenceDisplayMode(props.row.referenceDisplayMode) }}
                </span>
                <span v-else>&nbsp;</span>
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="inludeInidentity"
                label="In Identity">
                <bool-element :value="props.row.includeInIdentity" />
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="required"
                label="Required">
                <bool-element :value="props.row.required" />
            </b-table-column>
            <template #empty>
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>No attributes defined for this library</p>
                    </div>
                </section>
            </template>
        </b-table>
    </spinner>
</template>

<script>
    import BoolElement from '@/shared/components/BoolElement.vue';
    import { getLibraryLink } from '@/shared/helpers/routing';
    import Spinner from '@/shared/components/Spinner.vue';
    import { getAttributeDefinitions } from '@/shared/helpers/api';
    import { referenceDisplayMode } from '@/shared/helpers/utils';

    export default {
        components: {
            BoolElement,
            Spinner
        },
        props: {
            libraryName: {
                type: String,
                required: true
            },
            nameInIdentity: {
                type: Boolean,
                default: true
            },
            descriptionIsRequired: {
                type: Boolean,
                default: false
            }
        },
        data: function() {
            return {
                loading: true,
                attributeDefinitions: null
            };
        },
        computed: {
            hasDataStyle() {
                return { disabled: !this.hasData };
            },
            hasData() {
                return this.attributeDefinitions !== null && this.attributeDefinitions.length > 0;
            }
        },
        mounted: async function() {
            const tempDefinitions = (await getAttributeDefinitions(this, this.libraryName)).sort((a, b) => a.sequenceNumber - b.sequenceNumber);
            tempDefinitions.unshift({
                name: 'IsValid',
                displayAs: '',
                description: 'Standard attribute: Is the item valid or not',
                attributeType: 'Bool',
                includeInIdentity: false,
                required: true,
                sequenceNumber: '-'
            });
            tempDefinitions.unshift({
                name: 'Description',
                displayAs: '',
                description: 'Standard attribute: The description of an item',
                attributeType: 'String',
                includeInIdentity: false,
                required: this.descriptionIsRequired,
                sequenceNumber: '-'
            });
            tempDefinitions.unshift({
                name: 'Name',
                displayAs: '',
                description: 'Standard attribute: The name of an item',
                attributeType: 'String',
                includeInIdentity: this.nameInIdentity,
                required: true,
                sequenceNumber: '-'
            });
            this.attributeDefinitions = tempDefinitions;
            this.loading = false;
        },
        methods: {
            isReferenceType: function(attributeType) {
                return attributeType.toUpperCase() === 'CODEREF';
            },
            getLibraryLink,
            referenceDisplayMode
        }
    };
</script>
