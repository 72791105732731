<template>
    <div v-if="current">
        <chain-release
            v-if="current.specification.specificationType === 'ReleaseChain'"
            :release="current" />
        <template v-else>
            <div class="columns">
                <div class="column">
                    <h2 class="title is-3">
                        Release {{ current.id }}
                    </h2>
                </div>
            </div>

            <b-message
                v-if="connectionLost"
                title="Problem With Live Updates"
                type="is-warning"
                :closable="false">
                <p>Try to refresh the page </p>
            </b-message>
            <b-message
                v-if="showAccessDenied"
                title="The requested action is forbidden for the user"
                type="is-warning"
                :closable="false">
                <p>The performed action on release {{ releaseId }} resulted in forbidden. Try to refresh the page if this was an error</p>
            </b-message>

            <release-state-changer
                v-if="current"
                :release="current"
                :log-button="true"
                :log-button-class="'is-primary'"
                @transition="load($event)"
                @changeState="current.state = $event"
                @showAccessDeniedMessage="showAccessDeniedMessage" />

            <div class="columns">
                <div class="column is-one-third-desktop is-full-tablet is-full-mobile">
                    <div class="box">
                        <table class="table is-striped is-narrow is-fullwidth">
                            <tbody>
                                <tr>
                                    <th>State</th>
                                    <td>{{ current.state }}</td>
                                </tr>
                                <tr>
                                    <th>Type</th>
                                    <td>{{ current.specification.specificationType }}</td>
                                </tr>
                                <tr>
                                    <th>Library</th>
                                    <td>
                                        <p v-if="current.specification.isComposite">
                                            {{ current.specification.libraries.join(', ') }}
                                        </p>
                                        <p v-else-if="specIsPublisherRelease">
                                            {{ current.specification.libraryNames.join(', ') }}
                                        </p>
                                        <router-link
                                            v-if="current.specification.libraryName"
                                            :to="getLibraryLink(current.specification.libraryName)">
                                            <b-icon
                                                icon="link-variant"
                                                size="is-small" />
                                            {{ current.specification.libraryName }}
                                        </router-link>
                                    </td>
                                </tr>
                                <tr v-if="specIsPublisherRelease">
                                    <th>Codes to publish</th>
                                    <td>
                                        <p>{{ current.specification.codesCount }}</p>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <th>Library set</th>
                                    <td>
                                        <p v-if="current.specification.isComposite">
                                            {{ current.specification.changeDocuments.map(doc => doc.codeSetDescription).join(', ') }}
                                        </p>
                                        <a
                                            v-if="current.specification.codeSetName"
                                            :href="getCodeSetLink(current.specification.codeSetName)">
                                            <b-icon
                                                icon="link-variant"
                                                size="is-small" />
                                            {{ current.specification.codeSetDescription }}
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Application</th>
                                    <td>
                                        <router-link
                                            v-if="current.specification.applicationName"
                                            :to="getApplicationLink(current.specification.applicationName)">
                                            <b-icon
                                                icon="link-variant"
                                                size="is-small" />
                                            {{ current.specification.applicationName }}
                                        </router-link>
                                        <router-link
                                            v-for="app in current.specification.applicationNames"
                                            v-else-if="current.specification.applicationNames"
                                            :key="app"
                                            :to="getApplicationLink(app)">
                                            <b-icon
                                                icon="link-variant"
                                                size="is-small" />
                                            {{ app }}
                                        </router-link>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Created</th>
                                    <td>{{ $filters.dateFormatLong(current.dateCreated) }}</td>
                                </tr>
                                <tr>
                                    <th>Updated</th>
                                    <td>{{ $filters.dateFormatLong(current.dateUpdated) }}</td>
                                </tr>
                                <tr>
                                    <th>Created by</th>
                                    <td>
                                        <b-tooltip
                                            v-if="current.createdBy.name && current.createdBy.email"
                                            position="is-bottom"
                                            :label="current.createdBy.email">
                                            {{ current.createdBy.name }}
                                        </b-tooltip>
                                        <div v-else>
                                            {{ current.createdBy.name ?? 'Unknown user' }}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="column">
                    <div
                        v-if="current.jobs.length"
                        class="box">
                        <h2 class="title is-5">
                            Jobs
                        </h2>
                        <table class="table is-narrow is-hoverable is-fullwidth">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Type</th>
                                    <th>Application / Webhook</th>
                                    <th>&nbsp;</th>
                                    <th>State</th>
                                    <th>Retry count</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="job in current.jobs"
                                    :key="job.id">
                                    <td>{{ job.id }}</td>
                                    <td>{{ job.type }}</td>
                                    <td>
                                        <router-link
                                            v-if="job.subscription"
                                            :to="getApplicationLink(job.subscription.application.name)">
                                            <b-icon
                                                icon="link-variant"
                                                size="is-small" />
                                            {{ job.subscription.application.name }}
                                        </router-link>
                                        <div
                                            v-else-if="job.webhook">
                                            <p
                                                class="webhook-url-style">
                                                {{ job.webhook.callbackUrl }}
                                            </p>
                                            <router-link
                                                style="white-space: nowrap;"
                                                :to="getWebhookLink(job.webhook.id)">
                                                <b-icon
                                                    icon="link-variant"
                                                    size="is-small" />
                                                Configuration
                                            </router-link>
                                        </div>
                                    </td>
                                    <td style="vertical-align: middle">
                                        <progress
                                            v-show="job.state === 'InProgress'"
                                            class="progress is-info"
                                            max="100" />
                                    </td>
                                    <td>
                                        {{ job.state }}
                                        <b-tooltip
                                            v-if="job.state === 'Fail'"
                                            label="The job failed. Please see the release log for details."
                                            position="is-bottom"
                                            type="is-primary">
                                            <b-icon
                                                icon="information-outline"
                                                size="is-small" />
                                        </b-tooltip>
                                    </td>
                                    <td>{{ job.retryCount }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <release-errors-and-warnings
                :release="current"
                :log-errors="logErrors" />
            <release-publish-box
                v-if="specIsPublisherRelease"
                :spec="current ? current.specification : {}" />
            <release-change-box
                v-else
                :changes-ready="changesReady"
                :current="current" />
        </template>
    </div>
</template>

<script>

    import Spinner from '@/shared/components/Spinner.vue';
    import { getLogs, getRelease } from '@/shared/helpers/api';
    import { getApplicationLink, getCodeSetLink, getLibraryLink, getWebhookLink } from '@/shared/helpers/routing';
    import { showMixin } from '@/shared/mixins/showMixin';
    import * as signalR from '@microsoft/signalr';
    import ChainRelease from './ChainRelease.vue';
    import ReleaseChangeBox from './ReleaseChangeBox.vue';
    import ReleaseErrorsAndWarnings from './ReleaseErrorsAndWarnings.vue';
    import ReleasePublishBox from './ReleasePublishBox.vue';
    import ReleaseStateChanger from './ReleaseStateChanger.vue';

    export default {
        components: {
            Spinner,
            ReleaseStateChanger,
            ReleaseChangeBox,
            ReleasePublishBox,
            ChainRelease,
            ReleaseErrorsAndWarnings
        },
        mixins: [
            showMixin
        ],
        data: function() {
            return {
                releaseId: null,
                showAccessDenied: false,
                connectionLost: false,
                paginate: {
                    maxErrors: 10,
                    maxWarnings: 10
                },
                current: null,
                changesReady: false,
                connection: null,
                logErrors: []
            };
        },
        computed: {
            isCodeChangeDocument() {
                return (
                    this.current.specification.specificationType === 'CompositeChangeDocument'
                    || this.current.specification.specificationType === 'ChangeDocument');
            },
            specIsScopeCodeSetRelease: function() {
                return this.current.specification.specificationType === 'ScopeCodeSetRelease';
            },
            specIsPublisherRelease: function() {
                return this.current.specification.specificationType === 'PublishFilter';
            },
            hasFailedJobs: function() {
                return this.current.jobs.filter(j => j.state === 'Fail').length > 0;
            }
        },
        created: function() {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(window.config.signalRUrlRelease, {
                    accessTokenFactory: async () => await window.authService.acquireToken()
                })
                .configureLogging(signalR.LogLevel.None)
                .build();
        },
        mounted: async function() {
            const releaseId = this.$route.params.release;
            await this.load(releaseId);
            this.connection.on('Change', () => this.reLoad(this.current.id));
            try {
                await this.connection.start();
                this.connection.invoke('ReleaseId', `${this.current.id}`);
                this.connection.onclose(() => this.connectionLost = true);
            } catch {
                this.connectionLost = true;
            }
        },
        beforeUnmount: function() {
            this.connection.stop();
        },
        methods: {
            showAccessDeniedMessage(releaseId) {
                this.releaseId = releaseId;
                this.showAccessDenied = true;
            },
            load: async function(releaseId) {
                this.current = await getRelease(this, releaseId);
                if (this.current.state !== 'Planned' && this.current.state !== 'Creating')
                    this.changesReady = true;
                await this.loadJobErrors();
            },
            reLoad: async function(releaseId) {
                const update = await getRelease(this, releaseId);
                if (update.state === 'Open')
                    this.changesReady = true;
                this.current.state = update.state;
                this.current.jobs = update.jobs;
                this.current.specification = update.specification;
                await this.loadJobErrors();
            },
            loadJobErrors: async function() {
                if (this.hasFailedJobs) {
                    const log = await getLogs(this, {
                        params: {
                            releaseId: this.current.id
                        }
                    });
                    this.logErrors = log.filter(x => x.logLevel === 'Error');
                } else {
                    this.logErrors = [];
                }
            },
            getCodeSetLink,
            getLibraryLink,
            getApplicationLink,
            getWebhookLink
        }
    };
</script>

<style>
    .webhook-url-style {
      display: inline;
      margin-right: 10px;
      word-break: break-word;
    }
</style>
