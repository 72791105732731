<template>
    <spinner :loading="loading">
        <h2 class="title is-3">
            Webhooks
        </h2>
        <nav class="level has-margin-top2 is-flex-wrap-wrap">
            <div class="level-left">
                <div class="level-item">
                    <h2 class="title is-6">
                        Filters:
                    </h2>
                </div>
                <div class="level-item has-margin-left">
                    <b-field label="by Library">
                        <b-select v-model="filters.library">
                            <option :value="''">
                                All Libraries
                            </option>
                            <option
                                v-for="lib in libraries"
                                :key="lib.name">
                                {{ lib.name }}
                            </option>
                        </b-select>
                    </b-field>
                </div>
                <div class="level-item has-margin-left">
                    <b-field label="by Identity">
                        <b-select v-model="filters.identity">
                            <option :value="''">
                                All Identities
                            </option>
                            <option
                                v-for="id in identities"
                                :key="id">
                                {{ id }}
                            </option>
                        </b-select>
                    </b-field>
                </div>
                <div class="level-item has-margin-left">
                    <b-field label="search for url">
                        <b-input
                            v-model="filters.search"
                            placeholder="api.example.com" />
                    </b-field>
                </div>
            </div>

            <div class="level-right has-margin-top2">
                <p class="level-item">
                    <button
                        class="button is-info">
                        <span class="icon is-small">
                            <i
                                class="fa fa-info"
                                aria-hidden="true" />
                        </span>
                        <span><a
                            style="color: white"
                            href="/Help#/webhooks">Documentation</a></span>
                    </button>
                </p>
                <p class="level-item">
                    <button
                        class="button is-info"
                        @click="showModal(true)">
                        <span class="icon is-small">
                            <i
                                class="fa fa-plus-circle"
                                aria-hidden="true" />
                        </span>
                        <span>Add Webhook</span>
                    </button>
                </p>
                <p class="level-item">
                    <button
                        class="button is-info"
                        :disabled="!selectedHookVisible"
                        @click="showModal(false)">
                        <span class="icon is-small">
                            <i
                                class="fa fa-fw fa-pencil-alt"
                                aria-hidden="true" />
                        </span>
                        <span>Edit Webhook</span>
                    </button>
                </p>
                <p class="level-item">
                    <button
                        class="button is-primary"
                        :disabled="!selectedHookVisible"
                        @click="deleteHook">
                        <span class="icon is-small">
                            <i
                                class="fa fa-trash"
                                aria-hidden="true" />
                        </span>
                        <span>Delete webhook</span>
                    </button>
                </p>
            </div>
        </nav>

        <b-table
            ref="webhooksTable"
            v-model:selected="selectedHook"
            class="has-margin-top2 site-sticky-header"
            :striped="true"
            :hoverable="true"
            :data="filteredHooks">
            <b-table-column
                v-slot="props"
                field="ownerId"
                label="Registration Identity"
                sortable>
                {{ props.row.ownerId }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="callbackUrl"
                label="Url"
                sortable>
                {{ props.row.callbackUrl }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="date"
                label="Date"
                sortable>
                {{ $filters.dateFormatShort(props.row.dateSubscribed) }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="library"
                label="Library"
                sortable>
                <router-link :to="getLibraryLink(props.row.library)">
                    <b-icon
                        icon="link-variant"
                        size="is-small" />
                    {{ props.row.library }}
                </router-link>
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="enabled"
                label="Enabled"
                sortable>
                <bool-element :value="props.row.enabled" />
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="connectionStatus"
                label="Status">
                {{ props.row.connectionStatus }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Logs">
                <button
                    class="button is-info"
                    @click="showLog(props.row.id)">
                    <b-icon
                        icon="format-list-numbered"
                        size="is-small" />
                </button>
            </b-table-column>
            <template #empty>
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>No webhooks found</p>
                    </div>
                </section>
            </template>
        </b-table>

        <b-modal
            v-cloak
            v-if="tempHook"
            :cancel-callback="cancelEdit"
            :model-value="editModalActive"
            has-modal-card>
            <div
                class="modal-card"
                style="height: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">
                        {{ isCreate? 'Create' : 'Edit' }} Webhook
                    </p>
                </header>
                <section class="modal-card-body">
                    <b-field label="Identity">
                        <b-input
                            v-model="tempHook.ownerId"
                            :disabled="!isCreate" />
                    </b-field>
                    <b-field
                        v-if="isCreate"
                        label="Secret">
                        <b-input v-model="tempHook.secret" />
                    </b-field>
                    <b-field label="Callback-url or email">
                        <b-input
                            v-model="tempHook.callbackUrl"
                            placeholder="api.example.com or user.name@example.com"
                            :disabled="!isCreate" />
                    </b-field>
                    <b-field label="Library">
                        <b-select
                            v-model="tempHook.library"
                            placeholder="select library"
                            :disabled="!isCreate">
                            <option
                                v-for="lib in libraries"
                                :key="lib.name">
                                {{ lib.name }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field label="Enabled">
                        <bool-selector
                            :value="tempHook.enabled? 'True' : 'False'"
                            @bool-select="tempHook.enabled = boolParser($event)" />
                    </b-field>
                </section>
                <footer class="modal-card-foot">
                    <button
                        class="button"
                        @click="cancelEdit">
                        <span class="icon is-small">
                            <i class="fa fa-times-circle" />
                        </span>
                        <span>Cancel</span>
                    </button>
                    <button
                        class="is-primary button"
                        :disabled="!tempHookValid"
                        @click="modalSave">
                        <span class="icon is-small">
                            <i class="fa fa-fw fa-check-circle" />
                        </span>
                        <span>Save</span>
                    </button>
                </footer>
            </div>
        </b-modal>
    </spinner>
</template>

<script>

    import BoolElement from '@/shared/components/BoolElement.vue';
    import BoolSelector from '@/shared/components/BoolSelector.vue';
    import Spinner from '@/shared/components/Spinner.vue';
    import {
        addWebhook,
        deleteWebhook,
        disableWebHook,
        enableWebHook,
        getLibraries,
        getWebHooks
    } from '@/shared/helpers/api.ts';
    import { getLibraryLink } from '@/shared/helpers/routing.js';
    import { filterUnique } from '@/shared/helpers/utils.js';
    import { showMixin } from '@/shared/mixins/showMixin.js';

    export default {
        components: {
            BoolElement,
            BoolSelector,
            Spinner
        },
        mixins: [
            showMixin
        ],
        data: function() {
            return {
                loading: true,
                webhooks: null,
                identities: [],
                libraries: [],
                editModalActive: false,
                isCreate: false,
                selectedHook: null,
                selectedHookId: null,
                tempHook: null,
                filters: {
                    identity: '',
                    library: '',
                    search: ''
                }
            };
        },
        url: {
            selectedHookId: {
                param: 'hookId',
                noHistory: true
            }
        },
        computed: {
            filteredHooks: function() {
                if (this.webhooks) {
                    return this.webhooks
                        .filter(wh => this.filters.identity !== '' ? wh.ownerId === this.filters.identity : true)
                        .filter(wh => this.filters.library !== '' ? wh.library === this.filters.library : true)
                        .filter(wh => this.filters.search !== '' ? wh.callbackUrl.includes(this.filters.search) : true);
                } else {
                    return [];
                }
            },
            selectedHookVisible: function() {
                if (!this.selectedHook || this.selectedHook && !this.filteredHooks.includes(this.selectedHook))
                    return false;
                else
                    return true;
            },
            tempHookValid: function() {
                if (this.tempHook?.library && this.tempHook?.callbackUrl) {
                    const mailExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                    return mailExp.test(this.tempHook.callbackUrl) || this.tempHook.secret?.length > 0;
                }
                return false;
            }
        },
        watch: {
            selectedHook() {
                this.selectedHookId = this.selectedHook ? this.selectedHook.id : null;
            }
        },
        mounted: async function() {
            this.libraries = (await getLibraries(this)).sort((a, b) => a.name > b.name ? 1 : -1);
            await this.getHooks();
            if (Number.isInteger(this.selectedHookId)) {
                this.selectedHook = this.webhooks.find(hook => hook.id === this.selectedHookId);
            } else {
                this.selectedHookId = null;
            }
            this.loading = false;
            this.$nextTick(() => {
                this.scrollToSelected(this.selectedHookId);
            });
        },
        methods: {
            getHooks: async function() {
                this.webhooks = await getWebHooks(this);
                this.webhooks.forEach(h => h.library = h.library.name);
                this.identities = this.webhooks
                    .map(wh => wh.ownerId)
                    .filter(filterUnique);
            },
            deleteHook: async function() {
                await deleteWebhook(this, this.selectedHook.id);
                this.getHooks();
            },
            showModal: function(create) {
                if (create) {
                    this.tempHook = this.createEmptyHook();
                    this.isCreate = true;
                } else {
                    this.tempHook = { ...this.selectedHook };
                    this.isCreate = false;
                }
                this.editModalActive = true;
            },
            cancelEdit: function() {
                this.tempHook = null;
                this.editModalActive = false;
            },
            modalSave: async function() {
                if (this.isCreate) {
                    if (!this.tempHook.secret) {
                        this.tempHook.secret = '.'; // note: This is a hacky solution that could be improved.
                    }
                    await addWebhook(this, this.tempHook);
                } else {
                    await this.toggleWebhook(this, this.tempHook.id, this.tempHook.enabled);
                }
                this.editModalActive = false;
                this.tempHook = null;
                this.getHooks();
            },
            createEmptyHook: function() {
                return {
                    enabled: false
                };
            },
            toggleWebhook: async function(self, id, enabled) {
                if (enabled)
                    await enableWebHook(self, id);
                else
                    await disableWebHook(self, id);
            },
            showLog: function(hookId) {
                this.$router.push({ name: 'WebhookLog', query: { webhookId: hookId } });
            },
            boolParser: function(string) {
                if (string.toLowerCase() === 'true')
                    return true;
                else if (string.toLowerCase() === 'false')
                    return false;
                else
                    return undefined;
            },
            scrollToSelected: function(selectedId) {
                if (!selectedId) { return; }

                const index = this.filteredHooks.findIndex(otherRow => otherRow.id === selectedId);
                if (index === -1) { return; }

                const buefyTable = this.$refs.webhooksTable.$el,
                      tableRow = buefyTable.querySelectorAll('tbody tr')[index];

                tableRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
            },
            getLibraryLink
        }
    };
</script>
